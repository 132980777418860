import { React, useContext, useEffect, useState } from 'react';
import {
  chakra,
  Button,
  Heading,
  VStack,
  Text,
  Center,
  Stack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Image,
  useMediaQuery,
  useToast,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import B2bImage from '../../../../images/CancelamentoB2B.svg';

function CancelamentoB2BAviso(props) {
  const disclosure = props.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const toast = useToast();
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <Drawer
        isOpen={disclosure.isOpen}
        placement="bottom"
        onClose={disclosure.onClose}
        size="full" 
      >
        <DrawerOverlay />
        <DrawerContent borderRadius="0.8em" bg="white">
          <DrawerCloseButton _focus={'off'}/>
          <DrawerHeader>
            <Center align="center" mt={'1em'}>
              <chakra.span fontSize="20px" color={'#585858'} fontWeight={'bold'}>
                Cancelar Contrato
              </chakra.span>
            </Center>
          </DrawerHeader>
          <DrawerBody>
            <VStack mt={'1em'} align="left">
              <Image src={B2bImage} />
              <Center align="center" mt={'1em'}>
                <chakra.span fontSize="20px" color={'#EB8B2A'} fontWeight={'bold'}>
                  Fale com o setor de RH da sua empresa
                </chakra.span>
              </Center>

              <Center align="center" mt={'1em'}>
                <Text>Para cancelar seu contrato, é necessário solicitar essa ação com o setor de RH da sua empresa.</Text>
              </Center>
            </VStack>
            <Center align="center" mt={'1em'}>
              <Button w={'100%'} color={'white'} bg={'#EB8B2A'} fontWeight={'bold'} onClick={() => disclosure.onClose()}>
                Entendi
              </Button>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CancelamentoB2BAviso;
