import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useDisclosure,
  useMediaQuery,
  useTab,
  useToast,
} from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useEffect, useState } from 'react';
import SucessoLaranja from '../../../images/SucessoLaranja.svg';
import Excluir from '../../../images/ExcluirGenerico.svg';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import MainChildHeader from 'components/Dashboard/Main/MainChildHeader';
import ExcluirContaDrawer from './Modal/ExcluirContaDrawer';
import { ReactComponent as Heart } from '../../../images/heart.svg';
import { ReactComponent as FamiliaPlus } from '../../../images/familia-plus.svg';
import { ReactComponent as Familia } from '../../../images/plano-familia.svg';
import { ReactComponent as Individual } from '../../../images/individual.svg';
import { ReactComponent as PlanoRecomendado } from '../../../images/plano-recomendado.svg';
import { loadPlans } from 'api/plans';

function ExcluiContaPagina() {
  const toast = useToast();
  const navigate = useNavigate();
  const { setPage, setUser, user } = useContext(AccountContext);
  const [tipo, setTipo] = useState('');
  const [medico, setMedico] = useState(false);
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [iphoneXr] = useMediaQuery('(max-height: 896px)');
  const [isInRange] = useMediaQuery('(max-height: 840px) and (max-height: 1000px)');
  const disclosure = useDisclosure();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await loadPlans();
        setPlans(data);
      } catch (error) {
        //setError(true);
      } finally {
        //setLoading(false);
      }
    })();
  }, []);

  const [frequenciaPagamento, setFrequenciaPagamento] = useState(0);

  function getPlanIcon(planID) {
    switch (planID) {
      case 29: // FAMILIA
        return <Familia height={20}></Familia>;
      case 55: //INDIVIDUAL
        return <Individual height={20}></Individual>;
      case 137:
        return <FamiliaPlus height={20}></FamiliaPlus>;

      default:
        return <Individual height={20}></Individual>;
    }
  }

  function getPlanColor(planID) {
    switch (planID) {
      case 29: // FAMILIA
        return '#EB8B2A';
      case 55: //INDIVIDUAL
        return '#529C94';

      default:
        return '#FF8000';
    }
  }

  function CardDadosPlano() {
    const [lg] = useMediaQuery('(min-width: 425px)');

    const TabFrequenciaPlano = React.forwardRef((props, ref) => {
      const tabProps = useTab({ ...props, ref });
      const isSelected = !!tabProps['aria-selected'];
      return (
        <Text
          {...tabProps}
          py={1}
          px={2}
          textAlign={'center'}
          bg={isSelected ? 'white' : 'transparent'}
          color={isSelected ? props.color : 'white'}
          borderRadius={'full'}
          border={`2px solid white`}
        >
          {tabProps.children}
        </Text>
      );
    });

    return plans?.map((item, index) => {
      const idPlano = item.id;
      const menorValor = item?.parcelas && item.parcelas.length > 0 ? Math.min(...item.parcelas.map((parcela) => parcela.min_valor)) : null;
      return (
        <Box
          key={item.id}
          plan={item}
          p={8}
          borderRadius={20}
          width={'100%'}
          flexShrink={0}
          scrollSnapAlign={'center'}
          overflow={'visible'}
          position={'relative'}
          fontSize={lg ? 'md' : 'xs'}
          border={`1px solid ${getPlanColor(item.id)}`}
          bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
        >
          <Center bg={getPlanColor(item.id)} color={'white'} gap={2} p={2} borderRadius={10} textAlign={'center'}>
            {getPlanIcon(item.id)} Plano {item.nome}
          </Center>

          <Box mb={2}>
            <Tabs
              variant="solid-rounded"
              colorScheme={getPlanColor(item.id)}
              index={frequenciaPagamento}
              bg={getPlanColor(item.id)}
              onChange={(index) => {
                setFrequenciaPagamento(index);
              }}
              mt={4}
              p={0.5}
              borderRadius={20}
            >
              <TabPanels color={'white'}>
                {item?.parcelas?.map(({ min_valor, quantidade }) => (
                  <TabPanel px={4}>
                    <Center flexDirection={'column'}>
                      <Text fontSize={lg ? 'xl' : 'md'} alignSelf={'flex-start'}>
                        A partir de
                      </Text>
                      <Text fontSize={lg ? '5xl' : '3xl'} fontWeight={'bold'} lineHeight={1} mb={4} alignSelf={'flex-start'}>
                        {Number(menorValor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Text>
                      <Text fontSize={'xs'}>* O valor do plano será ajustado de acordo com a forma de pagamento escolhida.</Text>
                    </Center>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>

          <Center>
            <Button
              bottom={0}
              color="#fff"
              backgroundColor={`${getPlanColor(item.id)}`}
              borderRadius={20}
              mt={'1em'}
              onClick={() => {
                if (user.id_contrato > 0) {
                  if (user.contrato_pendente_pagamento === true) {
                    toast({
                      title: 'Assinatura pendente',
                      description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  } else {
                    if (user.loggedIn === true && user.id_contrato > 0) {
                      if (medico === true) {
                        navigate('/telepet/termos');
                      } else {
                        toast({
                          title: 'Indisponível',
                          description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'green',
                          containerStyle: {
                            paddingBottom: '5em',
                          },
                        });
                      }
                    } else {
                      setTipo('Veterinário');
                      disclosure.onOpen();
                    }
                  }
                } else if (user.loggedIn === false) {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro');
                } else {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service: 'telemedicine',
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro/plano', {
                    state: {
                      client_id: user.id_cliente_deovita,
                      name: user.name,
                      cpf: user.cpf,
                    },
                  });
                }
              }}
            >
              {user.loggedIn === false ? 'Quero me cadastrar agora!' : 'Quero assinar agora!'}
            </Button>
          </Center>
          <Box pt={4} borderTop={'2px solid #D0D0D0'} mt={6} color={'#585858'}>
            <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'} mb={4}>
              Com o <span style={{ color: getPlanColor(item.id) }}>Plano {item.nome}</span>, você pode:
            </Text>
            {item?.beneficios &&
              item?.beneficios?.map((beneficio) => (
                <HStack mt={1}>
                  <chakra.span fontSize="16px">
                    <Heart style={{ flexShrink: 0, marginTop: 4 }} color={getPlanColor(idPlano)}></Heart>
                  </chakra.span>
                  {
                    <chakra.span color={'#585858'} fontSize="16px">
                      {beneficio.descricao}
                    </chakra.span>
                  }
                </HStack>
              ))}
          </Box>
        </Box>
      );
    });
  }

  return (
    <>
      <ChildHeader />
      <VStack mt={'1em'}>
        <Center>
          <Heading fontSize={'16px'} color={'#585858'}>
            Excluir Conta
          </Heading>
        </Center>
        <Center>
          <Image boxSize="66" objectFit="cover" src={Excluir} alt="" mt={'2em'} />
        </Center>
        <Center>
          <Text fontSize={'16px'} color={'#585858'} justify={'center'} fontWeight={'bold'} mt={'2em'}>
            Tem certeza que quer <br /> excluir sua conta?
          </Text>
        </Center>
        <Box
          display={'flex'}
          gap={4}
          px={10}
          py={8}
          overflow={'visible'}
          overflowY={'auto'}
          overscrollBehaviorX={'contain'}
          scrollSnapType={'x mandatory'}
          maxWidth="400px"
          minWidth="300px"
          margin="0 auto"
        >
          <CardDadosPlano />
        </Box>
        <VStack w={'100%'}>
          <Button
            onClick={() => navigate('/perfil/assinaturas/confirma-conta-excluida')}
            color="#585858"
            mt={4}
            alignSelf={'center'}
            bg={'#F3F3F3'}
            w={'80%'}
            mb={'2em'}
          >
            Excluir minha conta
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default ExcluiContaPagina;
