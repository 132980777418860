import { React, useContext, useEffect, useRef, useState } from 'react';
import { Button, Text, HStack, Box, Grid, GridItem, Image, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import socketIOClient from 'socket.io-client';
import { AccountContext } from 'components/AccountContext';
import sendMessageSolicitante from './sendMessageSolicitante';
import WarningIcon from '../../images/WarningBranco.svg';
import { ReactComponent as CheckCircle } from '../../images/check-circle.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
const REACT_APP_DEOVITA_RECIPIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_DEOVITA_RECIPIENT_ID_PROD
    : process.env.REACT_APP_DEOVITA_RECIPIENT_ID_TEST;

const PixGenerico = (selecionado) => {
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent ?? 0;
  const mdvGerente = selecionado.mdvGerente ?? 0;
  const mdvDiretor = selecionado.mdvDiretor ?? 0;
  const { state } = useLocation();

  const { user } = useContext(AccountContext);

  const { expiration_pix_in_min, navig_to, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;

  const valor = fromCadastro ? valor_selecionado : state.valor_plano;
  const navigate = useNavigate();
  const [pix, setPix] = useState({ sucess: '', pix_code: '', id: 0, paid: false });
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(null);
  const [socket] = useState(socketIOClient(ENDPOINT));
  const [timer, setTimer] = useState(60 * expiration_pix_in_min);
  const intervalRef = useRef();

  const decreaseTimer = () =>
    setTimer((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });

  const fromCadastroData = {
    client_id: state.client_id,
    nome: state.name,
    fone: user.phone1 ? user.phone1 : state.phone,
    adicionais: state.adicionais,
    plano: state.id_plano,
  };
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  useEffect(() => {
    socket.on('retorno_pix', (response) => {
      if (response === true) {
        console.log('pix pago');
      }
    });
    let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
    if (user.titular === false) usuarioAssinante = true;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        particular: !usuarioAssinante ? true : false,
        metadataCoparticipacao: state?.metadataCoparticipacao ,
        coparticipacao: user?.allowsTelemedicina?.paga_telemedicina ? user?.allowsTelemedicina?.paga_telemedicina : false,
        id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
        nome_cliente: user.name ? user.name : state.name,
        document: user.username ? user.username : state.cpf,
        email: user.email ? user.email : 'novoassinante@deovita.com.br',
        phone: user && user.loggedIn === true ? (user.phone1 ? onlyNumbers(user.phone1) : onlyNumbers(user.phone2)) : state.phone,
        amount: valor_selecionado * 100,
        expiration_in_min: expiration_pix_in_min,
        paid: false,
        frequencia: state.frequencia,
        split_rules: voucher
          ? voucher.recipient_id_gerente
            ? [
                {
                  amount: mdvGerente,
                  recipient_id: voucher.recipient_id_gerente,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvDiretor,
                  recipient_id: voucher.recipient_id_diretor,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent - mdvDiretor - mdvGerente,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: true,
                    charge_remainder_fee: true,
                    liable: true,
                  },
                },
              ]
            : voucher.recipient_id_diretor
            ? [
                {
                  amount: mdvDiretor,
                  recipient_id: voucher.recipient_id_diretor,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent - mdvDiretor,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                },
              ]
            : [
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: true,
                    charge_remainder_fee: true,
                    liable: true,
                  },
                },
              ]
          : split,

        item: fromCadastro === true ? fromCadastroData : state.item,
        voucher: voucher,
      }),
    };

    const erroMsg = 'Não foi possível gerar seu código pix no momento';
    fetch(`${ENDPOINT}/payment/${state.service}/pix`, requestOptions)
      .catch((err) => {
        console.log('err', err);
        setError(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          console.log('res', res.body);
          setError(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setError(null);
          socket.emit('pix', { duration: 5 * 60, id: data.id });
          setPix({ ...data, paid: false });
          if (state.solicitacao === true) {
            console.log('sending message');
            sendMessageSolicitante(ENDPOINT, state.orcamento_id);
          }
        } else {
          setError(erroMsg);
        }
      });
  }, []);

  useEffect(() => {
    if (pix.pix_code) {
      intervalRef.current = setInterval(decreaseTimer, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [pix]);

  function renderPixPaid() {
    return (
      <Modal isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent p={8} bgColor={'#F3F3F3'} borderRadius={10} color={'#585858'} m={4}>
          <CheckCircle width={25} height={25}></CheckCircle>
          <Text mt={4} fontWeight={'bold'} fontSize={20}>
            Seu <span style={{ color: '#529C94' }}>pagamento</span> foi realizado <span style={{ color: '#529C94' }}>com sucesso!</span>
          </Text>
          <Text mt={4}>Ficamos felizes por escolher o DeoVita! Você será redirecionado para a Home do app.</Text>

          <Button isFullWidth colorScheme="teal" onClick={() => navigate('/')} mt={6}>
            Continuar
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  function renderPix() {
    return (
      <>
        <Box bgColor={'#E4E4E4'} borderRadius={10} p={4} color={'#585858'}>
          <Text fontSize={'xl'} textAlign={'center'} fontWeight={'bold'} mb={4}>
            Chave Pix
          </Text>
          <Text>Chave aleatória:</Text>
          <Box bgColor={' #EDEDED'} textAlign={'center'} py={4} mt={2} borderRadius={10}>
            <Text as="p">{pix.pix_code ? pix.pix_code : 'carregando...'} </Text>
          </Box>
          {(state.service === 'telemedicine_deovita' || state.service === 'telemedicine_deovita_pet') && (
              <Box w={'100%'} border={`2px solid ${usuarioAssinante ? '#EB8B2A' : '#529C94'}`} borderRadius={20} p={4} color={'#585858'} mt={8}>
                <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
                  Resumo da compra:
                </Text>
                <HStack justifyContent={'space-between'}>
                  <Box>
                    <Text color={usuarioAssinante ? '#EB8B2A' : '#529C94'} fontWeight={'bold'}>
                      {state.service === 'telemedicine_deovita'
                        ? 'Consulta Telemedicina'
                        : state.service === 'telemedicine_deovita_pet'
                        ? 'Consulta Telepet'
                        : ''}
                    </Text>
                  </Box>
                  <Text fontWeight={'bold'}>{Number(valor_selecionado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                </HStack>
              </Box>
            )}
          <Grid templateColumns="2fr 5fr" alignItems={'center'} gap={4} my={4}>
            <GridItem>
              <Text align="right" fontSize={18} fontWeight={'bold'}>
                {`${Math.floor(timer / 60)
                  .toString()
                  .padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
              </Text>
            </GridItem>
            <GridItem>
              <Text fontSize={14}>Utilize a chave antes do fim deste tempo</Text>
            </GridItem>
          </Grid>

          <Text color={'red.700'} textAlign={'center'} mb={4}>
            {error}
          </Text>

          <CopyToClipboard text={pix.pix_code} onCopy={() => setCopy(true)}>
            <Button name="copy" colorScheme="teal" w="100%" disabled={pix.pix_code ? false : true}>
              {copy ? 'Copiado!' : 'Copiar código'}
            </Button>
          </CopyToClipboard>

          <Box display={'flex'} mt={4} p={4} bgColor={'#CB0000'} borderRadius={10} color={'white'} gap={4}>
            <Image src={WarningIcon}></Image>
            <Text fontWeight={'bold'} fontSize={12} lineHeight={1}>
              O débito será automaticamente processado ao concluir a transação
            </Text>
          </Box>
        </Box>
        {fromCadastro && (
          <Box w={'100%'} border={'2px solid #E4E4E4'} borderRadius={20} p={4} color={'#585858'} my={4}>
            <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
              Resumo da compra:
            </Text>
            <HStack justifyContent={'space-between'}>
              <Box>
                <Text color={'#FF8000'} fontWeight={'bold'}>
                  Plano {state.nome_plano}
                </Text>
                <Text>Período: {state.frequenciaText} </Text>
              </Box>
              <Text fontWeight={'bold'}>{Number(valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
            </HStack>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <Formik validateOnChange={false} validateOnBlur={false}>
        <Form>{pix.paid ? renderPixPaid() : renderPix()}</Form>
      </Formik>
      <Button
        colorScheme="teal"
        w="100%"
        mt={4}
        mb={10}
        isDisabled={pix.pix_code !== '' ? false : true}
        onClick={() => {
          fetch(`${ENDPOINT}/whatsapp/processa-pagamento`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nome: state.name ? state.name : state.item.nome,
              numero: user.phone1 ? user.phone1 : state.phone ? state.phone : state.item.fone,
            }),
          });
          navigate(navig_to, {
            state: {
              ...state,
              valor: valor,
              transaction_id: pix.id,
              forma_pgto: 'pix',
              pix_code: pix.pix_code,
              credito_id: pix.credito_id,
              metadataCoparticipacao: state?.metadataCoparticipacao ,
              coparticipacao: user?.allowsTelemedicina?.paga_telemedicina,
            },
          });
        }}
      >
        Continuar
      </Button>
    </>
  );
};

export default PixGenerico;
