import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import { useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

function DesistenciaModal({ state, agenda, setPausedFetchRdy }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenPosDesistencia, onOpen: openPosDesistencia, onClose: closePosDesistencia } = useDisclosure();
  const [checkingFaturado, setCheckingFaturado] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [tipoDesistencia, setTipoDesistencia] = useState('credito');
  const { user, setPage } = useContext(AccountContext);
  const [isFaturado, setIsFaturado] = useState(!user?.allowsTelemedicina?.paga_telemedicina);

  const desistirAtendimento = useCallback(async () => {
    setPausedFetchRdy(true);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);

      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setPausedFetchRdy(false);
      setIsLoading(false);
    }
  }, [agenda, navigate, setPausedFetchRdy, state.patient]);

  const desistirAtendimentoCredito = useCallback(async () => {
    setPausedFetchRdy(true);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();

      setTipoDesistencia('credito');
      onClose();
      openPosDesistencia();
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setIsLoading(false);
    }
  }, [agenda, onClose, openPosDesistencia, setPausedFetchRdy, state.patient]);

  const desistirAtendimentoReembolso = useCallback(async () => {
    setPausedFetchRdy(true);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}?reembolso=true`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();

      setTipoDesistencia('reembolso');
      onClose();
      openPosDesistencia();
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setIsLoading(false);
    }
  }, [agenda, onClose, openPosDesistencia, setPausedFetchRdy, state.patient]);

  const verificarFaturado = useCallback(async () => {
    setPausedFetchRdy(true);
    setCheckingFaturado(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/verifica-faturado/${agenda}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const data = await response.json();
      setIsFaturado(data.faturado);
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a verificação de pagamento');
    } finally {
      setPausedFetchRdy(false);
      setCheckingFaturado(false);
    }
  }, [agenda, setPausedFetchRdy]);

  return (
    <>
      <Button
        isFullWidth
        size="sm"
        colorScheme="gray"
        variant="outline"
        color="#585858"
        mt={4}
        isLoading={checkingFaturado}
        onClick={async () => {
          await verificarFaturado();
          onOpen();
        }}
      >
        Desistir do atendimento
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent textAlign={'center'} borderRadius={20} m={8}>
          <ModalHeader fontSize={'md'}>Você tem certeza disso?</ModalHeader>
          <ModalBody fontSize={'xs'}>
            <Text mb={4}>Se desistir do atendimento, você perderá sua posição na fila.</Text>
            <Text>Caso já tenha realizado o pagamento, acesse nossa central de atendimento para solicitar o reembolso.</Text>
          </ModalBody>

          <ModalFooter>
            <VStack w={'100%'}>
              {error && (
                <Text fontSize={'sm'} fontWeight={'bold'} bgColor={'#CB0000'} color={'white'} p={2} rounded={10}>
                  {error}
                </Text>
              )}

              {isFaturado && user?.allowsTelemedicina?.paga_telemedicina ? (
                <>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      desistirAtendimentoCredito();
                    }}
                    isFullWidth
                    isLoading={isLoading}
                  >
                    Desistir e manter crédito
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="gray"
                    color={'#585858'}
                    onClick={() => {
                      desistirAtendimentoReembolso();
                    }}
                    fontWeight={'normal'}
                    isFullWidth
                    isLoading={isLoading}
                  >
                    Desistir e solicitar reembolso
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  colorScheme="teal"
                  onClick={() => {
                    desistirAtendimento();
                  }}
                  isFullWidth
                  isLoading={isLoading}
                >
                  Confirmar desistência
                </Button>
              )}

              <Button size="sm" disabled={isLoading} colorScheme="gray" onClick={onClose} variant="ghost" isFullWidth>
                Cancelar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenPosDesistencia} onClose={closePosDesistencia} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent textAlign={'center'} borderRadius={20} m={8}>
          <ModalHeader fontSize={'md'}>Recebemos sua solicitação</ModalHeader>
          <ModalBody fontSize={'sm'}>
            {tipoDesistencia === 'credito' && 'O crédito ficará disponível para a sua próxima consulta'}
            {tipoDesistencia === 'reembolso' &&
              'Para solicitar o reembolso, por favor, entre em contato com nossa central de atendimento pelo número 0800 280-2133 (Whatsapp)'}
          </ModalBody>

          <ModalFooter>
            <VStack w={'100%'}>
              {error && (
                <Text fontSize={'sm'} fontWeight={'bold'} bgColor={'#CB0000'} color={'white'} p={2} rounded={10}>
                  {error}
                </Text>
              )}

              <Button
                size="sm"
                colorScheme="teal"
                onClick={() => {
                  navigate('/dashboard', { replace: true });
                }}
                isFullWidth
                isLoading={isLoading}
              >
                Entendi
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DesistenciaModal;
