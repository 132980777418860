import React, { useState, useContext, useEffect } from 'react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import {
  useToast,
  HStack,
  chakra,
  VStack,
  Avatar,
  Flex,
  Heading,
  Box,
  Button,
  List,
  ListItem,
  Divider,
  Text,
  WrapItem,
  Image,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  LinkBox,
  TableContainer,
  Table,
  Badge,
  Center,
  Tr,
  Td,
  Stack,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon, CloseIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { MdOutlineWifi, MdOutlineWifiOff } from 'react-icons/md';
import { AccountContext } from '../../AccountContext';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavBar from '../../BottomNavBar';
import Loading from 'components/Loading';
import PopupAvaliacao from './PopupAvalicao';
import verifyPeriodOfTheDay from '../../Utils/Functions/verifyPeriodOfTheDay';
import DrawerDocumentos from './DrawerDocumentos';
import _ from 'lodash';
import { SiGooglemaps } from 'react-icons/si';
import haversine from 'haversine-distance';
import LoadingGenerico from 'components/AreaLivre/DashboardLivre/LoadingGenerico';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
const ENDPOINT_DEOVITA =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_URL_DEOVITA_PROD : process.env.REACT_APP_URL_DEOVITA_TEST;

function Detalhes() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const documentosDisclosure = useDisclosure();
  const { atendimento, statusAtual } = state;
  const timeString = atendimento.START_TIME;

  const dateObject = new Date(`2000-01-01T${timeString}`);
  dateObject.setMinutes(dateObject.getMinutes() - 30);
  const data_agora = new Date();
  const horario_agora = data_agora.getHours() + ':' + data_agora.getMinutes() + ':00';
  const hora_atendimento = atendimento.START_TIME;
  const year = data_agora.getFullYear();
  const month = String(data_agora.getMonth() + 1).padStart(2, '0');
  const day = String(data_agora.getDate()).padStart(2, '0');
  const data_atual = `${year}-${month}-${day}`;
  const data_agendamento = atendimento.APP_DATE.split('T')[0];

  const [cancelamento, setCancelamento] = useState([]);
  const [documentos, setDocumentos] = useState(false);

  const [agendas, setAgendas] = useState([]);
  const [preparos, setPreparos] = useState([]);
  const [creditoPago, setValorCreditoPago] = useState(0);
  const data = new Date(atendimento.APP_DATE.replace(' ', 'T'));
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + atendimento.RESOURCE_LID + '/foto_profissional.png';
  const dataextenso = data.toLocaleDateString('pt-br', { weekday: 'long' });
  const monthNameLong = data.toLocaleString('pt-br', { month: 'long' });
  const options = { month: 'short', day: 'numeric' };
  const subTitle = { casing: 'capitalize', color: 'gray' };
  const body = { casing: 'capitalize', fontWeight: 'bold' };
  const logo = `${process.env.REACT_APP_URL_LOGOMARCA}/` + atendimento.LOCATION_LID + '/logomarca.png';
  const logoDeovita = `${process.env.REACT_APP_URL_LOGOMARCA}/` + '286/logomarca.png';
  const isAndroid = user.device === 'android' ? true : false;
  var mapUriPrefix = 'https://maps.apple.com/?q=';
  if (isAndroid) mapUriPrefix = 'https://maps.google.com/?q=';
  const urlMapa = `${mapUriPrefix}${atendimento.ADRESS}, ${atendimento.NUMBER}, ${atendimento.bairro}, ${atendimento.CITY}, Ceará`;
  let unidadePhone;
  // Assuming 'atendimento.PHONE' holds the phone number

  // Remove special characters and spaces

  if (atendimento?.PHONE) {
    unidadePhone = atendimento?.PHONE.replace(/[^\d]/g, '');
    // Check if it starts with '0800'
    if (unidadePhone?.startsWith('0800')) {
      // Format 0800 phone numbers (0800 xxx xxx)
      unidadePhone = unidadePhone?.replace(/(\d{4})(\d{3})(\d{3})/, '0800 $2 $3');
    } else {
      // Format normal phone numbers ((xx) x xxxx xxxx)
      unidadePhone = unidadePhone?.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3 $4');
    }
  }

  const procedimentosUnicos = Array.from(new Set(preparos.map((procedure) => procedure.descricao)));

  const descriccoes = new Map();

  procedimentosUnicos.forEach((descricao, index) => {
    const preparosParaDescricao = preparos.filter((proc) => proc.descricao === descricao);
    const firstProcedure = preparosParaDescricao[0];
    descriccoes.set(descricao, firstProcedure);
  });

  const useListProcedures = atendimento.PROCEDURE_NAMES?.map((procedure, key) => {
    return (
      <Text mt="1%" mb="1%" fontWeight="bold">
        {procedure}
      </Text>
    );
  });

  const useListProceduresHierar = preparos.map((procedure, index) => {
    const descricao_maiscula = procedure.descricao_pai.charAt(0).toUpperCase() + procedure.descricao_pai.slice(1);
    return (
      <div key={index}>
        <chakra.li>
          <Text mt={'0.5em'}>{descricao_maiscula + ' ' + procedure.descricao}</Text>
        </chakra.li>
      </div>
    );
  });

  const useOrientacoes = preparos?.map((procedure) => {
    return <chakra.p>{procedure.descricao_preparo}</chakra.p>;
  });

  let titulo = '';
  if (atendimento.GENDER === 'M') {
    titulo = 'Dr. ';
  } else if (atendimento.GENDER === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Minhas Consultas');

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }
  async function fetchOrientacoes() {
    let url_pesquisa = `${ENDPOINT}/schedule/getHierarquiaProcs/${
      atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null
        ? atendimento.procedimentos_multiplos
        : atendimento.ACTIVITY_LID
    }/${atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? false : true}`;
    const response = await fetch(url_pesquisa, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setPreparos(postsData?.data?.data);
  }

  async function fetchValorCredito() {
    const postsData = await fetch(`${ENDPOINT}/clients/searchValorCredito`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agenda_exames_id: atendimento.APP_LID }),
    });
    const response = await postsData.json();
    setValorCreditoPago(response?.valor);
  }

  const LoadingIcon = () => {
    return (
      <>
        <ChildHeader />
        <Box w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
          <Loading />
        </Box>
      </>
    );
  };
  useEffect(() => {
    fetchOrientacoes();
    fetchValorCredito();
  }, []);

  useEffect(() => {
    setValorCreditoPago(creditoPago);
  }, [creditoPago]);

  function cancelarConsulta(motivoCancelamento) {
    const url = ENDPOINT + '/appointments/cancel/';
    const payload = {
      agenda_exames_id: atendimento.APP_LID,
      motivo: motivoCancelamento,
      paciente_id: atendimento.USER_LID,
      medico: atendimento.RESOURCE_LID,
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        navigate('/alerts/sucesso/excluir-agendamento');
      } catch (error) {
        console.log(error);
        navigate('/alerts/erro/excluir-agendamento');
      }
    };

    fetchPosts();
  }

  function ShowMessageByPaymentType() {
    if ([1007, 1026]?.includes(atendimento.forma_pagamento)) {
      return <Text>Pagamentos realizados usando PIX serão estornados em até 24 horas.</Text>;
    } else if ([1027, 1023, 1018, 1024, 1017]?.includes(atendimento.forma_pagamento)) {
      return <Text>Pagamentos realizados usando cartão de crédito serão estornados em até 30 dias.</Text>;
    } else {
      return <></>;
    }
  }

  function AlertDialogExample(props) {
    const cancelRef = React.useRef();

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          size="xs"
          isCentered
          isLazy={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius={15}>
              <AlertDialogFooter>
                <VStack w="100%" spacing={3} margin={2}>
                  <Center></Center>
                  <Text fontWeight="bold" fontSize="x-large">
                    Deseja cancelar{' '}
                    {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? 'este exame' : 'esta consulta'}?
                  </Text>
                  <Text>{ShowMessageByPaymentType()}</Text>
                  <Button
                    ref={cancelRef}
                    isFullWidth
                    onClick={() => {
                      // cancelarConsulta();
                      onClose();
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    colorScheme="teal"
                    onClick={() => {
                      //cancelarConsulta();
                      navigate('/consultas/cancelamento', { state: { atendimento: atendimento, statusAtual } });
                      onClose();
                    }}
                    isFullWidth
                  >
                    Continuar
                  </Button>
                </VStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  let assinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) assinante = true;
 
  async function fetchAgendas() {
    setLoading(true);
    let url = '';
    if (atendimento.especial) {
      url = `${ENDPOINT}/schedule/getAvailabilitySpecialist2/${atendimento.RESOURCE_LID}/${atendimento.ACTIVITY_LID}/${
        atendimento.USER_LID
      }/${assinante}/${user?.allowsTelemedicina?.paga_telemedicina ? user.allowsTelemedicina.paga_telemedicina : false}`;
    } else {
      url = `${ENDPOINT}/schedule/getAvailability/${atendimento.RESOURCE_LID}/${atendimento.ACTIVITY_LID}/${atendimento.USER_LID}`;
    }
    console.log(url)
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setAgendas(postsData);
    setLoading(false);
  }

  async function ObterDados() {
    let url = ENDPOINT + '/schedule/get_motivos_cancelamento';
    const resposta = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsDados = await resposta.json();
    setCancelamento(postsDados);
  }

  // Função para verificar se existem
  async function fetchDocumentos() {
    // 1 -  obter numero do laudo id
    let localStateAtendimento = state.atendimento;
    // localStateAtendimento.ambulatorio_laudo_id = 246812;

    // 2 - fetch Data
    try {
      if (localStateAtendimento.ambulatorio_laudo_id) {
        const response = await fetch(
          `${ENDPOINT_DEOVITA}/deovita/autocomplete/retrievePrescriptionAssignatureUrlsForLaudoId/${localStateAtendimento.ambulatorio_laudo_id}`
        );
        const data = await response.json();
        setDocumentos(data);
      }
    } catch (err) {
      console.log('documentos error: ', err);
    }
  }

  useEffect(() => {
    ObterDados();
    fetchAgendas();
    fetchDocumentos();
  }, []);

  function isNotLonger24Hours() {
    const dataAtual = new Date();
    const horario = atendimento?.START_TIME;
    const [hora, minuto] = horario.split(':').map(Number);

    const dataAgendamento = new Date(atendimento.APP_DATE);
    dataAgendamento.setHours(hora);
    dataAgendamento.setMinutes(minuto);

    if (dataAgendamento < dataAtual) {
      return false;
    }

    const diferencaEmMilissegundos = dataAgendamento.getTime() - dataAtual.getTime();
    const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);

    if (diferencaEmHoras <= 24) {
      return false;
    } else {
      return true;
    }
  }

  function Telemedicina() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [disableButton, setDisableButton] = useState(false);
    const [camera, setCamera] = useState('');
    const [mic, setMic] = useState('');

    // data atual
    let dataAtual = new Date();
    let diaAtual = dataAtual.getDate();
    let mesAtual = dataAtual.getMonth() + 1;
    let anoAtual = dataAtual.getFullYear();
    let horasAtual = dataAtual.getHours();
    let horasAtualFormat = horasAtual.toString;
    let horarioAtendimentoTeste = '16:35';

    let minutosAtual = dataAtual.getMinutes();
    let horarioAtual = horasAtual + ':' + minutosAtual;

    let confereDataAtual = diaAtual + '/' + mesAtual;

    // data do atendimento
    let dataAtendimento = new Date(atendimento.APP_DATE);
    let horarioAtendimento = atendimento.START_TIME.substring(0, 5);
    let diaAtendimento = dataAtendimento.getDate();
    let mesAtendimento = dataAtendimento.getMonth() + 1;
    let anoAtendimento = dataAtendimento.getFullYear();
    let horasAtendimento = horarioAtendimento;
    let minutosAtendimento = dataAtendimento.getMinutes();

    let confereDataAtendimento = diaAtendimento + '/' + mesAtendimento;

    let tipoConexao = '';
    let statusVelocidade = '';

    let larguraBanda = '';

    let statusConexao = '';

    let aviso = '';

    if (navigator.connection.effectiveType === '4g') {
      tipoConexao = 'Wi-Fi / 4G';
    }

    if (navigator.connection.downlink >= 1.25) {
      statusVelocidade = 'Conexão Estável';
    } else {
      statusVelocidade = 'Conexão Instável';
      aviso = (
        <Center>
          <Text color="red">É recomendável uma conexão estável para prosseguir com o atendimento</Text>
        </Center>
      );
    }
    if (navigator.connection.downlink === 0) {
      statusVelocidade = 'Sem Conexão';
    }

    let statusIcon = '';
    let statusIconConexao = '';

    if (statusConexao === 'Sem Conexão') {
      statusIcon = ' ' + <WarningTwoIcon />;
    } else {
      statusIcon = <CheckIcon color="green.500" />;
    }

    if (tipoConexao === 'Sem Conexão') {
      statusIcon = <WarningTwoIcon color="yellow.500" />;
      tipoConexao = 'Desconectado';
    } else {
    }

    if (navigator.connection.downlink >= 1.25) {
      statusVelocidade = 'Conexão Estável';
      statusIconConexao = <CheckIcon color="green.500" />;
      aviso = (
        <Text color="green.500" fontWeight="bold">
          Tudo certo para prosseguir!{' '}
        </Text>
      );
    } else {
      statusIconConexao = <WarningTwoIcon color="yellow.500" />;
    }

    if (navigator.onLine === true) {
      statusConexao = 'Usuário Conectado';
      tipoConexao = navigator.connection.effectiveType;
      larguraBanda = navigator.connection.downlink;
    } else {
      statusConexao = 'Usuário Desconectado';
      statusIcon = <WarningTwoIcon color="yellow.500" />;
      tipoConexao = 'Sem Conexão';
      larguraBanda = 'Sem Conexão';
      tipoConexao = 'Sem Conexão';
    }

    let plataforma = navigator.platform;

    useEffect(() => {
      if (navigator.onLine === true) {
        statusConexao = 'Usuário Conectado';
        tipoConexao = navigator.connection.effectiveType;
        larguraBanda = navigator.connection.downlink;
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }, [navigator]);

    function detectDevice(callback) {
      let device = navigator.mediaDevices;

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'videoinput' === device.kind));
      });

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'audioinput' === device.kind));
      });
    }

    detectDevice(function (temCamera) {
      let possuiCamera = '';

      possuiCamera = temCamera ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setCamera(possuiCamera);

      if (temCamera === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }

      if (navigator.onLine === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }

      // checando se as datas e horarios batem

      if (confereDataAtual === confereDataAtendimento) {
        avisoHorario = 'Tudo certo! Prossiga com o Atendimento';
        setDisableButton(false);
      } else {
        avisoHorario = (
          <Center>
            <Text color="red">Horário atual não é igual ao horário do atendimento</Text>
          </Center>
        );
        setDisableButton(true);
      }

      if (horarioAtual === horarioAtendimento) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    });

    detectDevice(function (temMic) {
      let possuiMic = '';

      possuiMic = temMic ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setMic(possuiMic);
    });
    let avisoHorario = '';

    if (confereDataAtual === confereDataAtendimento) {
    } else {
      avisoHorario = (
        <Center>
          <Text color="red">O atendimento será habilitado na data e horário marcados!</Text>
        </Center>
      );
    }

    if (horarioAtual === horarioAtendimento) {
      avisoHorario = (
        <Center>
          <Text color="green.500">Atendimento Liberado!</Text>
        </Center>
      );
    } else {
      avisoHorario = (
        <Center>
          <Text color="red">O atendimento será habilitado na data e horário marcados!</Text>
        </Center>
      );
    }

    return (
      <>
        <Button colorScheme="blue" disabled={disableButton} isFullWidth height="12" size="xs" borderRadius={'10'} variant="outline" onClick={onOpen}>
          ir para o atendimento
        </Button>
        {avisoHorario}

        <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
          <ModalOverlay />
          <ModalContent borderRadius={25}>
            <ModalCloseButton />
            <ModalBody>
              <VStack
                spacing={8}
                w={{
                  base: '90%',
                  md: '500px',
                }}
                m="20% auto"
                justify="center"
              >
                <Heading size="md">Vamos Checar Sua Conexão </Heading>
                {navigator.onLine === true ? (
                  <MdOutlineWifi size="90" color="rgba(90, 178, 173, 0.7)" />
                ) : (
                  <MdOutlineWifiOff size="90" color="rgba(174, 174, 178, 1)" />
                )}

                <Text fontWeight="bold">
                  <span>{statusConexao}</span> {statusIcon}
                </Text>

                <List spacing={2} size="md">
                  <ListItem>
                    <Text fontWeight="bold">
                      {' '}
                      Tipo de Conexão:<span> {tipoConexao}</span> {statusIcon}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <ListItem>
                    <Text fontWeight="bold">
                      <span>{statusVelocidade}</span> {statusIconConexao}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">
                    {' '}
                    Mb/s : {larguraBanda} {statusIconConexao}
                  </Text>

                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Câmera: {camera}</Text>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Microfone: {mic}</Text>
                </List>
                <Text fontWeight="bold">
                  Plataforma: <span>{plataforma}</span>
                </Text>
                <Text color="red.500">{aviso}</Text>
                <Center>
                  <Button
                    colorScheme="blue"
                    isFullWidth
                    height="12"
                    disabled={disableButton}
                    size="sm"
                    borderRadius={'10'}
                    variant="outline"
                    onClick={() => navigate('/termostelemed', { state: { atendimento: atendimento, statusAtual } })}
                  >
                    ir para o atendimento
                  </Button>
                </Center>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  function RemarcarConsulta() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pag, setPag] = useState(0);
    const [escolha, setEscolha] = useState(null);
    const [reagendamento, setReagendamento] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const [pickWarning, setPickWarning] = useState(false);
    const [pagExame, setPagExame] = useState(0);
    const [escolhaExame, setEscolhaExame] = useState(false);
    const [horarioExame, setHorarioExame] = useState(false);

    const toast = useToast();

    let uniqueDates = [];
    agendas.map((agenda) => (!uniqueDates?.includes(agenda.AVA_DATE) ? uniqueDates?.push(agenda.AVA_DATE) : ''));

    let dataAtendimento = new Date(atendimento.APP_DATE.replace(' ', 'T'));
    let dataextenso = dataAtendimento.toLocaleDateString('pt-br', {
      weekday: 'long',
    });
    let monthNameLong = dataAtendimento.toLocaleString('pt-br', {
      month: 'long',
    });

    let data = '';
    let dataIcon = ['', ''];
    if (typeof uniqueDates[pag] === 'string') {
      data = new Date(uniqueDates[pag]?.split('/').reverse().join('-') + 'T08:00:00');
      dataIcon = data.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    }

    const handlePick = async (agendaId, filteredAgenda, index) => {
      try {
        const url = ENDPOINT + '/schedule/checkAvailability/' + agendaId + '/' + atendimento.USER_LID;
        const response = await fetch(url, {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'GET',
        });
        const postsData = await response.json().then(({ available }) => {
          if (available === true) {
            setEscolha(agendaId);
            setReagendamento(filteredAgenda);
            document.querySelectorAll(`[class="chakra-badge css-wm29s0"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById(filteredAgenda.AVA_START_TIME + index).style.border = 'solid 2px #5ab2ad';
            setDisabled(true);
          } else {
            setPickWarning(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    function remarcarExame() {
      // setLoading(true);
      const url = ENDPOINT + '/schedule/rescheduleExame';
      const payload = {
        agenda_exames_id_atual: parseInt(atendimento.APP_LID),
        novaData: generatedDatas[pagExame].date,
        novoHorario: horarioExame,
      };

      const fetchPosts = async () => {
        try {
          const postsData = await fetch(url, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(payload),
          });

          const response = await postsData.json();

          if (response.success) {
            sendWhatsappReagendamentoExame();
            navigate('/alerts/sucesso/default');
          } else {
            navigate('/alerts/erro/default');
          }
        } catch (error) {
          console.log(error);
          navigate('/alerts/erro/default');
        }
      };
      fetchPosts();
      return;
    }

    function remarcarConsulta() {
      setLoading(true);
      const url = ENDPOINT + '/schedule/reschedule';
      const payload = {
        agenda_exames_id_atual: parseInt(atendimento.APP_LID),
        agenda_exames_id_novo: escolha,
      };
      const remarcarConsultaNoCalendario = () => {
        const payload = {
          agenda_exames_id_atual: parseInt(atendimento.APP_LID),
          agenda_exames_id_novo: escolha,
          reScheduleEvent: true,
          startDate_novo: reagendamento.data_inicio,
          endDate_novo: reagendamento.data_inicio,
        };
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ calendarEvent: payload }));
      };
      const fetchPosts = async () => {
        try {
          const postsData = await fetch(url, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(payload),
          });

          const response = await postsData.json();
          console.log(response)
          if (response.success) {
            sendWhatsappReagendamento();
            remarcarConsultaNoCalendario();
            //setCancelamento(response.data);
            navigate('/alerts/sucesso/default');
          } else {
            navigate('/alerts/erro/default');
          }
        } catch (error) {
          console.log(error);
          navigate('/alerts/erro/default');
        }
      };
      fetchPosts();
    }
    const filteredAgendas = agendas.filter((i) => uniqueDates[pag]?.includes(i.AVA_DATE));
    const useFilteredAgendas = filteredAgendas.map((agendas, index) => {
      return (
        <>
          <Td>
            <Badge
              id={agendas.AVA_START_TIME + index}
              borderRadius="1em"
              margin="0 0.5em"
              padding="1em 1.4em"
              size="md"
              borderEndRadius="15px"
              borderLeftRadius="15px"
              fontSize="sm"
              backgroundColor="rgba(90, 178, 173, 0.3)"
              color="black"
              onClick={() => {
                handlePick(agendas.AVAILABILITY_LID, agendas, index);
              }}
            >
              {agendas.AVA_START_TIME}
            </Badge>
          </Td>
        </>
      );
    });

    const sendWhatsappReagendamento = async () => {
      const url = ENDPOINT + '/whatsapp/reagendamento';
      const payload = {
        numero: user.phone1,
        nome: atendimento.USER_FIRST_NAME,
        procedimento: atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE,
        medico: atendimento.RESOURCE_NAME,
        data: reagendamento.AVA_DATE,
        hora: reagendamento.AVA_START_TIME,
        clinica: atendimento.NAME,
      };

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
    };

    const sendWhatsappReagendamentoExame = async () => {
      const url = ENDPOINT + '/whatsapp/reagendamentoExame';
      const payload = {
        numero: user.phone1,
        nome: atendimento.USER_FIRST_NAME,
        procedimentos: atendimento.PROCEDURE_NAMES,
        novoHorario: horarioExame,
        novaData: generatedDatas[pagExame].date,
        empresa: atendimento.NAME,
      };

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
    };

    function isSameDate(date1, date2) {
      return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
    }
    function generateTimestampsForNext30Days(
      horario_seg_sex_inicio = '07:30',
      horario_seg_sex_fim = '18:00',
      horario_sab_inicio = '08:00',
      horario_sab_fim = '18:00'
    ) {
      // const startDate = new Date(atendimento.APP_DATE);
      const startDate = new Date(atendimento.APP_DATE);
      const endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);

      // Feriados mockados temporariamente. Devido ao UTC, tive que por 1 dia na frente.
      const currentYear = new Date().getFullYear();
      const feriadosMock = [
        new Date(`${currentYear}-01-02`), // Ano Novo (domingo) – Feriado nacional
        new Date(`${currentYear}-02-21`), // Carnaval (segunda-feira) – Ponto facultativo
        new Date(`${currentYear}-02-22`), // Carnaval (terça-feira) – Ponto facultativo
        new Date(`${currentYear}-02-23`), // Quarta-feira de Cinzas (quarta-feira) – Ponto facultativo até as 14h
        new Date(`${currentYear}-03-09`), // Dia da Mulher (quarta) – Data comemorativa
        new Date(`${currentYear}-04-08`), // Sexta-feira Santa (sexta-feira) – Feriado nacional
        new Date(`${currentYear}-04-10`), // Páscoa (domingo) – Data comemorativa
        new Date(`${currentYear}-04-22`), // Tiradentes (sexta-feira) – Feriado nacional
        new Date(`${currentYear}-05-02`), // Dia do Trabalho (segunda-feira) – Feriado nacional
        new Date(`${currentYear}-05-15`), // Dia das mães (domingo) – Data comemorativa
        new Date(`${currentYear}-06-09`), // Corpus Christi (quinta-feira) – Ponto facultativo
        new Date(`${currentYear}-06-13`), // Dia dos Namorados (segunda-feira) – Data comemorativa
        new Date(`${currentYear}-06-25`), // Dia de São João (sábado) – Data comemorativa
        new Date(`${currentYear}-07-27`), // Dia dos Avós (quarta-feira) – Data comemorativa
        new Date(`${currentYear}-08-14`), // Dia dos Pais (domingo) – Data comemorativa
        new Date(`${currentYear}-09-08`), // Independência do Brasil (quinta-feira)
        new Date(`${currentYear}-10-13`), // Nossa Sra. Aparecida (quinta-feira) – Feriado nacional
        new Date(`${currentYear}-10-29`), // Dia do Servidor Público (sábado) – Ponto facultativo
        new Date(`${currentYear}-11-03`), // Finados (quinta-feira) – Feriado nacional
        new Date(`${currentYear}-11-16`), // Proclamação da República (quarta-feira) – Feriado nacional
        new Date(`${currentYear}-11-21`), // Consciência Negra (segunda-feira) – Data comemorativa
        new Date(`${currentYear}-12-25`), // Véspera de Natal (domingo) – Ponto facultativo após 14h
        new Date(`${currentYear}-12-26`), // Natal (segunda-feira) – Feriado nacional
        new Date(`${currentYear + 1}-01-01`), // Véspera do Ano Novo 2023 (domingo) – Ponto facultativo após 14h
      ];

      const days = _.range(0, 31)
        .map((index) => {
          const day = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
          if (day < startDate || day > endDate || day.getDay() === 0 || feriadosMock.some((feriado) => isSameDate(feriado, day))) {
            return null;
          }
          return day;
        })
        .filter((day) => day !== null);

      const timestamps = _.map(days, (day) => {
        const isCurrentDay = _.isEqual(day.toDateString(), new Date().toDateString());
        const dayTimestamps = _.range(0, 24 * 2)
          .map((index) => {
            const hour = _.floor(index / 2);
            const minute = (index % 2) * 30;
            const timestamp = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

            if (isCurrentDay) {
              const now = new Date();
              const currentHour = now.getHours();
              const currentMinute = now.getMinutes();
              if (hour < currentHour || (hour === currentHour && minute < currentMinute)) {
                return null;
              }
              if (hour === currentHour && minute < currentMinute) {
                return null;
              }
              if (hour === currentHour && minute === currentMinute && timestamp < horario_seg_sex_inicio) {
                return null;
              }
            } else if (day.getDay() === 6) {
              if (timestamp < horario_sab_inicio || timestamp >= horario_sab_fim) {
                return null;
              }
            } else {
              if (timestamp < horario_seg_sex_inicio || timestamp >= horario_seg_sex_fim) {
                return null;
              }
            }
            return timestamp;
          })
          .filter((timestamp) => timestamp !== null);
        if (_.isEmpty(dayTimestamps)) {
          return null;
        }
        return { date: day, timestamps: dayTimestamps };
      }).filter((timestamp) => timestamp !== null);
      return timestamps;
    }

    const generatedDatas = generateTimestampsForNext30Days(
      atendimento.HORARIO_SEG_SEX_ABERTURA,
      atendimento.HORARIO_SEG_SEX_ENCERRAMENTO,
      atendimento.HORARIO_SAB_ABERTURA,
      atendimento.HORARIO_SAB_ENCERRAMENTO
    );

    function verificarhorariolivre(event) {
      const data = new Date(event);
      // Verifique se o dia da semana é sábado (6)
      if (data.getDay() === 6) {
        // Converte os horários em strings para objetos Date
        const horarioInicio = new Date(`1970-01-01T${atendimento.HORARIO_SAB_ABERTURA}:00`);
        const horarioFim = new Date(`1970-01-01T${atendimento.HORARIO_SAB_ENCERRAMENTO}:00`);

        // Cria objetos Date para representar o horário de início e fim da manhã e tarde
        const horarioInicioManha = new Date(`1970-01-01T05:00:00`);
        const horarioFimManha = new Date(`1970-01-01T13:00:00`);

        const horarioInicioTarde = new Date(`1970-01-01T13:00:00`);
        const horarioFimTarde = new Date(`1970-01-01T23:00:00`);

        // Verifica se há horário de manhã
        if (
          (horarioInicio < horarioFimManha && horarioFim >= horarioInicioManha) ||
          (horarioInicio >= horarioInicioManha && horarioInicio < horarioFimManha)
        ) {
          document.getElementById('t1').style.display = 'block';
        } else {
          document.getElementById('t1').style.display = 'none';
        }

        // Verifica se há horário de tarde
        if (
          (horarioInicio <= horarioFimTarde && horarioFim > horarioInicioTarde) ||
          (horarioInicio >= horarioInicioTarde && horarioInicio <= horarioFimTarde)
        ) {
          document.getElementById('t2').style.display = 'block';
        } else {
          document.getElementById('t2').style.display = 'none';
        }
      } else {
        document.getElementById('t1').style.display = 'block';
        document.getElementById('t2').style.display = 'block';
      }
    }

    const useDatas = generatedDatas.map((generated, index) => {
      const uniqueDate = generated.date;
      const options = { month: 'short', day: 'numeric' };
      const event = new Date(uniqueDate);
      const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
      return (
        <>
          <Td
            id={`td${index}`}
            borderRadius="15px"
            p="0.5em"
            fontSize="xl"
            textAlign="center"
            class="margin-td"
            onClick={() => {
              document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
              document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('date' + index).style.border = '1px solid orange';
              setPagExame(index);
              setEscolhaExame(false);
              verificarhorariolivre(event);
            }}
          >
            <HStack
              id={`date${index}`}
              backgroundColor="rgba(90, 178, 173, 0.25)"
              borderRadius="15px"
              style={pag === index ? { border: '1px solid orange' } : {}}
            >
              <Text width="60%">
                <Flex ml="1em">
                  <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                    <Text fontWeight="bold" fontSize="xl">
                      {dataIcon[0]}
                    </Text>
                    <Text fontWeight="bold" fontSize="sm" mt="-1">
                      {dataIcon[1].toUpperCase()}
                    </Text>
                  </Box>
                  <Text margin="11% 0" fontSize="xs" align="left">
                    {event.toLocaleString('pt-br', { weekday: 'long' })}
                  </Text>
                </Flex>
              </Text>
            </HStack>
          </Td>
        </>
      );
    });

    return agendas.length > 0 ? (
      <>
        <Button
          colorScheme="teal"
          isFullWidth
          height="12"
          size="xs"
          borderRadius={'10'}
          variant="outline"
          onClick={onOpen}
          isDisabled={!isNotLonger24Hours()}
          // disabled={atendimento.procedimentos_multiplos !== null ? true : false}
        >
          {loading ? 'Carregando...' : 'Remarcar'}
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          //width='150px'
          //height='471px'
          top="-176px"
          left="1616px"
          size="sm"
        >
          <ModalOverlay />
          <ModalContent borderRadius="23px">
            <ModalHeader textAlign="justify" mt="5">
              Deseja remarcar {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? 'esse exame' : 'essa consulta'}?
            </ModalHeader>
            <ModalCloseButton size="sm" />
            <ModalBody textAlign="left" fontSize="sm">
              {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null
                ? 'Exame em '
                : 'CONSULTA COM ' + atendimento.ESPECIALIDADE + ' - '}
              {atendimento.NAME} na {dataextenso}, {dataAtendimento.getDate()} de {monthNameLong} de {dataAtendimento.getFullYear()} as{' '}
              {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}
              {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? '.' : ' ' + 'com ' + titulo + ' '}
              {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? '' : atendimento.RESOURCE_NAME}
            </ModalBody>
            <chakra.h1 marginTop="4%" textAlign="left" ml="10" color="rgba(0, 0, 0, 0.5)" fontSize="sm">
              Paciente
            </chakra.h1>
            <chakra.p textAlign="left" ml="10">
              {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
            </chakra.p>
            <ModalHeader fontWeight="bold" fontSize="21" mt="2">
              Selecione dia e horário
            </ModalHeader>
            {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? (
              <>
                <TableContainer minWidth="100%">
                  <Table variant="simple" size="lg" class="table-agendamento">
                    <Tr>{useDatas}</Tr>
                  </Table>
                </TableContainer>
                <HStack justifyContent="center">
                  <Badge
                    id="t1"
                    borderRadius="0.5em"
                    margin="0 0.5em"
                    padding="0.5em 1.4em"
                    size="lg"
                    fontSize="md"
                    backgroundColor="rgba(90, 178, 173, 0.3)"
                    color="rgba(62, 100, 255, 1)"
                    onClick={() => {
                      document.getElementById('t1').style.border = '2px solid orange';
                      document.getElementById('t2').style.border = 'none';
                      setHorarioExame('07:30');
                      setEscolhaExame(true);
                      verificarhorariolivre(generatedDatas[pagExame].date);
                    }}
                  >
                    Manhã
                  </Badge>
                  <Badge
                    id="t2"
                    borderRadius="0.5em"
                    margin="0 0.5em"
                    padding="0.5em 1.4em"
                    size="lg"
                    fontSize="md"
                    backgroundColor="rgba(90, 178, 173, 0.3)"
                    color="rgba(62, 100, 255, 1)"
                    onClick={() => {
                      document.getElementById('t2').style.border = '2px solid orange';
                      document.getElementById('t1').style.border = 'none';
                      setHorarioExame('12:30');
                      setEscolhaExame(true);
                      verificarhorariolivre(generatedDatas[pagExame].date);
                    }}
                  >
                    Tarde
                  </Badge>
                </HStack>
              </>
            ) : (
              <LinkBox
                mt="1"
                id=""
                as="card"
                width="328px"
                height="85px"
                ml="7"
                borderRadius="18px"
                fontSize="xs"
                size="10"
                backgroundColor="rgba(90, 178, 173, 0.25)"
                maxInlineSize="80"
                textAlign="center"
              >
                <HStack>
                  <ChevronLeftIcon
                    fontSize="5em"
                    w={12}
                    h={8}
                    mt="-3"
                    onClick={() => {
                      if (pag > 0) {
                        setPag(pag - 1);
                        document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                          item.style.border = 'none';
                        });
                        setEscolha(null);
                        setDisabled(false);
                      }
                    }}
                  />
                  <Flex>
                    <Box
                      backgroundColor="rgba(90, 178, 173, 0.3)"
                      borderRadius="5px"
                      margin="20px"
                      color="rgba(62, 100, 255, 1)"
                      padding="0 10px"
                      ml="-3"
                      mt="2"
                    >
                      <Text fontWeight="bold" fontSize="xl" mt="1">
                        {dataIcon[0]}
                      </Text>
                      <Text fontWeight="bold" fontSize="sm">
                        {dataIcon[1]?.toUpperCase()}
                      </Text>
                    </Box>
                    <Text margin="15% 0" ml="2" mt="1" fontSize="lg" align="left">
                      <chakra.h1 textAlign="center" color="black" fontSize="md" mt="2" fontWeight="bold">
                        {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? 'Exame' : 'Consulta Médica'}
                      </chakra.h1>
                      {data.toLocaleString('pt-br', { weekday: 'long' })}
                    </Text>
                  </Flex>
                  <ChevronRightIcon
                    fontSize="5em"
                    w={12}
                    h={8}
                    mt="-3"
                    onClick={() => {
                      if (pag < uniqueDates?.length - 1) {
                        setPag(pag + 1);
                        document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                          item.style.border = 'none';
                        });
                        setEscolha(null);
                        setDisabled(false);
                      }
                    }}
                  />
                </HStack>
              </LinkBox>
            )}

            <TableContainer minWidth="100%" size="sm" mt="15">
              <Table variant="simple" borderRadius="15px" width="99px" height="44px">
                <Tr>{useFilteredAgendas}</Tr>
              </Table>
            </TableContainer>

            <Button
              colorScheme="teal"
              isFullWidth
              height="12"
              size="xs"
              borderRadius={'10'}
              ml="38"
              mt="5"
              width="300px"
              isLoading={loading}
              onClick={() => {
                if (escolha === null && escolhaExame === false) {
                  toast({
                    title: 'Aviso',
                    description: 'Selecione um horário para continuar',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                    position: 'bottom',
                    colorScheme: 'green',
                    containerStyle: {
                      paddingTop: '50px',
                    },
                  });
                } else {
                  if (atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null) {
                    remarcarExame();
                  } else {
                    remarcarConsulta();
                  }
                }
              }}
            >
              Remarcar {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null ? 'Exame' : 'Consulta'}
            </Button>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ) : (
      !loading && (
        <>
          <Button
            colorScheme="teal"
            isFullWidth
            height="12"
            size="xs"
            borderRadius={'10'}
            variant="outline"
            isDisabled={!isNotLonger24Hours() || loading}
            onClick={() => {
              if (!loading && agendas.length <= 0) {
                toast({
                  title: 'Indisponível',
                  description: 'Profissional não possui horários disponíveis para reagendamento ',
                  status: 'warning',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom',
                  colorScheme: 'green',
                  containerStyle: {
                    paddingTop: '50px',
                  },
                });
                return;
              }
            }}
          >
            {loading ? 'Carregando...' : 'Remarcar'}
          </Button>
        </>
      )
    );
  }

  const mostrarValor =
    (atendimento.VALOR_TOTAL || atendimento.PRICE) &&
    (atendimento.procedimentos_multiplos !== null ? parseFloat(atendimento.VALOR_TOTAL) >= 0.01 : parseFloat(atendimento.PRICE) >= 0.01);

  const situacaoBotao =
    atendimento?.realizada === false
      ? data_atual > data_agendamento
        ? 'O dia do agendamento já passou'
        : data_atual < data_agendamento
        ? 'O atendimento ainda não está disponível'
        : 'Atendimento indisponível'
      : 'Ir para o atendimento';

  let local = '';

  if (atendimento.TELEMEDICINA === true || atendimento.agenda_especialidade === true) {
    local = 'Atendimento Online';
  } else {
    local = `${atendimento?.NAME?.toUpperCase()} - ${myToLowerCase(atendimento.ADRESS)}, ${myToLowerCase(atendimento.NUMBER)} - ${myToLowerCase(
      atendimento.CITY
    )}`;
  }

  return loading ? (
    <>
      <ChildHeader />
      <Loading />
    </>
  ) : (
    <>
      <AlertDialogExample />
      <ChildHeader />

      <DrawerDocumentos disclosure={documentosDisclosure} documentos={documentos} />

      <VStack
        w={{ base: '90%' }}
        m="auto"
        marginTop="5"
        py="5"
        px="5"
        borderWidth={3}
        borderColor="#00000"
        borderRadius="10px"
        justify="center"
        align="start"
        spacing={5}
      >
        <Text fontWeight="bold">
          {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null
            ? 'AGENDAMENTO DE EXAMES'
            : atendimento.ACTIVITY_NAME
            ? atendimento.ACTIVITY_NAME
            : atendimento?.ESPECIALIDADE
            ? 'CONSULTA COM ' + atendimento?.ESPECIALIDADE?.toUpperCase()
            : 'AGENDAMENTO DE CONSULTA'}
        </Text>
        <HStack w="100%" justify="center">
          {atendimento.ACTIVITY_LID == 80168 || atendimento.ACTIVITY_LID == '80168' ? (
            <Image
              minwidth="150px"
              minHeight="50px"
              maxWidth="150px"
              padding="2"
              mt="5"
              name={atendimento.NAME}
              src={logoDeovita}
              fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            />
          ) : (
            <Image
              minwidth="150px"
              minHeight="50px"
              maxWidth="150px"
              padding="2"
              mt="5"
              name={atendimento.NAME}
              src={logo}
              fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            />
          )}
        </HStack>
        <HStack paddingTop="5%" justify="center">
          <div></div>
          <VStack align="start">
            {atendimento.ACTIVITY_LID == 80168 || atendimento.ACTIVITY_LID == '80168' ? (
              <></>
            ) : (
              <Text fontSize="lg" textAlign="center" maxW="80">
                {' '}
                {atendimento.NAME}
              </Text>
            )}
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}></HStack>
            <HStack spacing={1} fontSize="LG"></HStack>
          </VStack>
        </HStack>
        <chakra.p>
          <Text {...subTitle}>Data:</Text>
          <chakra.p>
            {(atendimento.RESOURCE_NAME === 'Sem Solicitante' ||
              atendimento.RESOURCE_NAME === null ||
              atendimento.RESOURCE_NAME === '' ||
              atendimento.RESOURCE_NAME === 'SEM SOLICITANTE' ||
              atendimento.RESOURCE_NAME === undefined ||
              atendimento.RESOURCE_LID === 51298 ||
              atendimento.ordem_chegada === true) &&
            atendimento.TELEMEDICINA !== true &&
            atendimento.agenda_especialidade !== true ? (
              <Text {...body} casing="lowercase">
                {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} no turno da{' '}
                {verifyPeriodOfTheDay(atendimento.START_TIME) + ' - atendimento por ordem de chegada'}.
              </Text>
            ) : (
              <>
                <Text {...body} casing="lowercase">
                  {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} às{' '}
                  {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}.
                </Text>
              </>
            )}
          </chakra.p>
        </chakra.p>

        <chakra.p>
          <Text {...subTitle}>Status:</Text>
          <Text {...body}>{statusAtual}</Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Local:</Text>
          <Text {...body}>{local}</Text>
        </chakra.p>
        {atendimento.TELEMEDICINA === true || atendimento.agenda_especialidade === true ? (
          ''
        ) : (
          <>
            <chakra.p>
              <Text
                color="rgba(14, 170, 139, 1)"
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(urlMapa, '_blank');
                }}
                fontSize="0.625em"
              >
                Clique para ver no mapa <SiGooglemaps class="googlemap" />
                {user.coords && user.coords.latitudeAtual && user.coords.longitudeAtual && atendimento.latitude && atendimento.longitude
                  ? ' ' +
                    (
                      haversine(
                        { lat: user.coords.latitudeAtual, lng: user.coords.longitudeAtual },
                        { lat: atendimento.latitude, lng: atendimento.longitude }
                      ) / 1000
                    ).toFixed(2) +
                    'km'
                  : ''}
              </Text>
            </chakra.p>
          </>
        )}
        <chakra.p>
          <Text {...subTitle}>Telefone:</Text>
          <Text {...body}>{atendimento.ACTIVITY_LID == 80168 || atendimento.ACTIVITY_LID == '80168' ? '88 99870-4981' : unidadePhone} </Text>
        </chakra.p>
        <VStack align="start" spacing={5}>
          {atendimento.RESOURCE_NAME === 'Sem Solicitante' || atendimento.RESOURCE_NAME === null || atendimento.RESOURCE_NAME === undefined ? (
            <>
              <chakra.h2 color="grey">Profissional</chakra.h2>
              <HStack>
                <VStack align="start">
                  <Avatar id={atendimento.ACTIVITY_LID} size="lg" bg="teal.400" name={atendimento.RESOURCE_NAME} src={img} />
                </VStack>
                <VStack align="start" spacing={-1}>
                  <chakra.h2 fontSize="md" fontWeight="bold" color="">
                    {titulo} {atendimento.RESOURCE_NAME}
                  </chakra.h2>
                  <Text casing="capitalize" fontSize="md" color="">
                    {atendimento.ESPECIALIDADE}
                  </Text>
                  <HStack>
                    <chakra.p fontSize="xs">{atendimento.CONSELHO}</chakra.p>
                    <chakra.p fontSize="xs"> {atendimento.RQE}</chakra.p>
                  </HStack>
                  <HStack spacing={1} fontSize="sm"></HStack>
                </VStack>
              </HStack>
            </>
          ) : (
            <></>
          )}
          <VStack variant="soft-rounded" w="full" alignItems="justify">
            <chakra.p>
              <Text {...subTitle}>Paciente:</Text>
              <Text {...body}>
                {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
              </Text>
            </chakra.p>
            <chakra.p>
              {mostrarValor ? (
                <>
                  <Text {...subTitle}>Valor: </Text>
                  <Text {...body}>R$ {atendimento.procedimentos_multiplos !== null ? atendimento.VALOR_TOTAL : atendimento.PRICE}</Text>
                </>
              ) : (
                <>
                  <Text {...subTitle}>Valor: </Text>
                  <Text {...body}>R$ {creditoPago > 1 ? parseFloat(creditoPago).toFixed(2) : creditoPago}</Text>
                </>
              )}
            </chakra.p>
            {atendimento.ACTIVITY_LID == 80168 || atendimento.ACTIVITY_LID == '80168' ? (
              <></>
            ) : (
              <chakra.p>
                <Text {...subTitle}>E-Ticket: </Text>
                <Text {...body}>PMA{atendimento.APP_LID}</Text>
              </chakra.p>
            )}
            {atendimento.PROCEDURE_NAMES != null && atendimento.PROCEDURE_NAMES != undefined ? (
              <>
                <Text {...subTitle}>Procedimentos:</Text>
                <chakra.ul {...body}>
                  <Text mt="1%" mb="1%" fontWeight="bold">
                    {useListProcedures}
                  </Text>
                  <Text mt={'1em'}></Text>
                  <Text fontSize={'1.1em'} color={'red'}>
                    Orientações:
                  </Text>
                  <>{preparos && preparos?.length > 0 ? useListProceduresHierar : useListProcedures}</>
                </chakra.ul>
              </>
            ) : (
              <></>
            )}
          </VStack>
          <VStack w="90%" minWidth="280px" alignItems="justify" spacing={4}>
            {atendimento.PREPARO ? (
              <>
                <Heading size="md">Orientações</Heading>
                <Box padding="5" borderRadius="10" borderWidth="0.063rem" borderColor="yellow.500" color="red.500" fontSize="sm" fontWeight="bold">
                  <chakra.p>{atendimento.PREPARO}</chakra.p>
                </Box>
              </>
            ) : (
              <></>
            )}
            {statusAtual !== 'Concluído' && (atendimento.TELEMEDICINA === true || atendimento.agenda_especialidade === true) ? (
              <Button
                colorScheme={data_atual > data_agendamento ? 'teal' : 'red'}
                width="100%"
                disabled={situacaoBotao === 'Ir para o atendimento' ? false : true}
                height="12"
                size="xs"
                borderRadius={'10'}
                variant="outline"
                onClick={() => {
                  window?.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      service: true,
                      openInBrowser: false,
                      //url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
                      url: `https://atendimento.deovita.com.br:5000/atendimento?p=${atendimento?.USER_LID}&m=${atendimento?.RESOURCE_LID}&a=${atendimento?.APP_LID}&u=paciente`,
                    })
                  );
                }}
              >
                {situacaoBotao}
              </Button>
            ) : (
              <>
                {/* <Button colorScheme="red" isFullWidth height="12" size="xs" borderRadius={'10'} variant="outline" onClick={() => {}}>
                  {atendimento.realizada === false
                    ? data_atual > data_agendamento
                      ? 'O dia do agendamento já passou'
                      : 'O atendimento ainda não está disponível'
                    : 'Ir para o atendimento'}
                </Button> */}

                {documentos ? (
                  <>
                    <Button
                      colorScheme="green"
                      isFullWidth
                      height="12"
                      size="xs"
                      borderRadius={'10'}
                      variant="outline"
                      onClick={() => {
                        documentosDisclosure.onOpen();
                      }}
                    >
                      Baixar Atestados/Receituários
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {statusAtual === 'Concluído' && ( //verificar se já foi feita a avaliação e desativa o botão
              <PopupAvaliacao data={atendimento} />
            )}
            {statusAtual !== 'Concluído' && statusAtual !== 'Pagamento em Analise' && (
              <>
                <HStack isFullWidth align="start">
                  <Button
                    colorScheme="red"
                    size="xs"
                    height="12"
                    isFullWidth
                    variant="outline"
                    borderRadius={'10'}
                    // onClick={() => navigate('/consultas/cancelamento', { state: { atendimento: atendimento, statusAtual } })}
                    onClick={() => {
                      onOpen();
                    }}
                    isDisabled={!isNotLonger24Hours()}
                  >
                    Cancelar
                  </Button>
                  <RemarcarConsulta />
                </HStack>
                {!isNotLonger24Hours() && (
                  <Text fontSize="xs" color="red">
                    Não é permitido cancelar ou remarcar consultas com menos de 24 horas para o atendimento
                  </Text>
                )}
              </>
            )}
            <Button colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={'10'} onClick={() => navigate('/consultas')}>
              ↑ Detalhes
            </Button>
          </VStack>
        </VStack>
      </VStack>
      <WrapItem mt="20">
        <BottomNavBar />
      </WrapItem>
    </>
  );
}

export default Detalhes;
