import {
  VStack,
  useDisclosure,
  chakra,
  Center,
  HStack,
  Flex,
  Heading,
  Spacer,
  Image,
  useToast,
  Box,
  useMediaQuery,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import Loading from 'components/Loading';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrocarPlano from '../../../images/TrocarPlano.svg';
import OutrasOpcoes from '../../../images/OutrasOpcoes.svg';
import Editar from '../../../images/editar.svg';
import Excluir from '../../../images/excluir.svg';
import { FaCircleInfo } from 'react-icons/fa6';
import Pet from './Modal/Pet';
import Dependentes from './Modal/Dependentes';
import DrawerTrocarPlano from './Modal/DrawerTrocarPlano';
import AvisoDependentes from '../Dependentes/AvisoDependentes';
import MainChildHeader from 'components/Dashboard/Main/MainChildHeader';
import BottomNavBar from 'components/BottomNavBar';

function Main() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [valor, setValor] = useState([]);
  const { setPage, setUser, user, toastInadimplente } = useContext(AccountContext);
  const { id_contrato, descricao_plano, titular } = user;
  const [dependentes, setDependentes] = useState([]);
  const [dependentes_cancelados, setDependentesCancelados] = useState([]);
  const [restricao_dependente, setRestricaoDependente] = useState(true);
  const [pets, setPets] = useState([]);
  const [titular_dados, setTitular] = useState([]);
  const [refValorPlano, setRefValorPlano] = useState(false);
  const toast = useToast();
  const petDisclosure = useDisclosure();
  const trocarplanoDisclousure = useDisclosure();
  const disclosureDependentes = useDisclosure();
  const dependenteDisclosure = useDisclosure();
  function callToast(title, descricao, status) {
    toast({
      title: title,
      description: descricao,
      status: status,
      duration: 6000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  setPage('Assinaturas');
  let valorString = 0;
  const fetchValorPlano = async () => {
    const id_contrato = titular ? user?.id_contrato : user?.id_contrato_dependent?.paciente_contrato_id;
    const url = `${ENDPOINT}/clients/searchPlanoValue`;

    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ id_contrato }),
    });
    const postsData = await response.json();
    if (postsData.success !== true) {
      setError('Não foi possível encontrar os detalhes do seu contrato. Favor, entre em contato com o suporte');
      setLoading(false);
      return;
    }
    setValor(postsData?.valor);
  };

  const fetchDependentes = async () => {
    const url = `${ENDPOINT}/client/dependents`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ id_contrato: id_contrato, titularInQuery: false }),
    });
    const postsData = await response.json();
    setDependentes(postsData.data);
    setLoading(false);
  };

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const fetchDependentesCancelados = async () => {
    setLoading(true);
    const url = `${ENDPOINT}/client/dependentsCancelados`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ id_contrato: id_contrato }),
    });
    const postsData = await response.json();
    if (postsData?.data?.length > 0) {
      const data_contrato_padrao = new Date(postsData?.data[0].data_cadastro);
      const hoje = new Date();
      const dataHoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());

      data_contrato_padrao.setUTCHours(0, 0, 0, 0);
      dataHoje.setUTCHours(0, 0, 0, 0);

      const diferencaDias = Math.floor((hoje - data_contrato_padrao) / (1000 * 60 * 60 * 24));
      setRestricaoDependente(diferencaDias >= 90 ? false : true);
    } else {
      setRestricaoDependente(false);
    }
    setLoading(false);
  };

  const fetchPets = async () => {
    const url = `${ENDPOINT}/client/pets`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ id_contrato: user.id_contrato }),
    });
    const postsData = await response.json();
    setLoading(false);
    setPets(postsData.data);
  };

  const fetchTitular = async () => {
    setLoading(true);
    const url = `${ENDPOINT}/clients/getTitularDados`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ id_contrato_dependent: user?.id_contrato_dependent?.paciente_contrato_id }),
    });
    const postsData = await response.json();
    setLoading(false);
    setTitular(postsData.data);
  };
  useEffect(() => {
    fetchValorPlano();
    fetchDependentes();
    fetchPets();
    fetchValorPlano();
    if (user.titular) {
      fetchDependentesCancelados();
    }
    if (user?.id_contrato_dependent?.paciente_contrato_id > 0) {
      fetchTitular();
    }
  }, []);

  function formatCPF(cpf) {
    const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    return cpf?.replace(cpfRegex, '$1.$2.$3-$4');
  }

  const refechValorPlano = (data) => {
    setRefValorPlano(data.success);
    setValor(data.valor);
  };

  useEffect(() => {
    fetchValorPlano();
  }, [refValorPlano]);

  function formatAniversario(aniversario) {
    const dataNascimento = new Date(aniversario);

    const dia = dataNascimento.getDate();
    const mes = dataNascimento.getMonth() + 1;
    const ano = dataNascimento.getFullYear();

    const dataAniversario = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${ano}`;

    return dataAniversario;
  }

  const useDependentes = dependentes.map((dependente, index) => {
    const arrName = dependente.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';
    return (
      <>
        <HStack justify="space-between" w="100%" spacing={4} mt={index === 0 ? '1em' : ''}>
          <chakra.span fontSize="12" color={'#585858'}>
            {nome}
          </chakra.span>
          {/* Envolver os ícones em um HStack */}
          <HStack>
            <Image
              boxSize="15px"
              objectFit="cover"
              src={Editar}
              alt=""
              mr={'0.5em'}
              onClick={() => {
                navigate('/perfil/dependentes/editar', {
                  state: {
                    id: dependente.paciente_id,
                    dependente_id: dependente.paciente_contrato_dependente_id,
                    nome: dependente.nome,
                    cpf: dependente.cpf,
                    nascimento: dependente.nascimento,
                    cep: dependente.cep,
                    code_ibge: dependente.codigo_ibge,
                    logradouro: dependente.logradouro,
                    numero: dependente.numero,
                    bairro: dependente.bairro,
                    complemento: dependente.complemento,
                    municipio: dependente.municipio,
                    estado: dependente.estado,
                    fone: dependente.celular,
                    sexo: dependente.sexo,
                  },
                });
              }}
            />
            <Image
              boxSize="15px"
              objectFit="cover"
              src={Excluir}
              alt=""
              onClick={() => {
                navigate('/perfil/dependentes/aviso-exclusao', {
                  state: { id: dependente.paciente_contrato_dependente_id, navigate_to: '/perfil/dependentes/processar-dados-assinatura' },
                });
              }}
            />
          </HStack>
        </HStack>
        <Spacer border={'0.1px solid #f0f0f0'} />
      </>
    );
  });

  const usePets = pets.map((pet, index) => {
    const arrName = pet.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';
    return (
      <>
        <HStack justify="space-between" w="100%" spacing={4} mt={index === 0 ? '1em' : ''}>
          <chakra.span fontSize="12" color={'#585858'}>
            {nome}
          </chakra.span>
          <Image
            boxSize="15px"
            objectFit="cover"
            src={Excluir}
            alt=""
            onClick={() => {
              navigate('/perfil/dependentes/aviso-exclusao', {
                state: { id: pet.paciente_contrato_dependente_id, navigate_to: '/perfil/dependentes/processar-dados-assinatura' },
              });
            }}
          />
        </HStack>
        <Spacer border={'0.1px solid #f0f0f0'} />
      </>
    );
  });
  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  var screenHeight = window.innerHeight;
  var screenWidth = window.innerWidth;
  const marginLeftValue = useBreakpointValue({ base: '2em', md: '-4em' });
  const headingMarginLeft = useBreakpointValue({ base: '0', md: isLargeHeight ? '-35%' : '-40' });
  const valorMarginLeft = useBreakpointValue({ base: '-7em', md: '-8.2em' });
  return !loading ? (
    <>
      <ChildHeader />
      <Pet disclosure={petDisclosure} />
      <Dependentes disclosure={dependenteDisclosure} />
      <DrawerTrocarPlano disclosure={trocarplanoDisclousure} atualizarUsuario={refechValorPlano} />
      <AvisoDependentes disclosure={disclosureDependentes} />
      <VStack w={'100%'} overflowY="auto" maxHeight="calc(100vh - 200px)">
        <VStack alignItems="center" spacing={2.5} w={'100%'}>
          <Heading fontSize="16px" fontWeight={'bold'} color={'#585858'} mt={'1.5em'} ml={headingMarginLeft}>
            Dados Assinatura
          </Heading>
          <VStack bg={'#EB8B2A'} borderRadius={'0.8em'} w={'75%'} p={1}>
            <VStack alignItems="flex-start" spacing={1} w={'100%'}>
              <chakra.p fontSize="16" display="flex" alignItems="center">
                <chakra.span color={'#ffffff'} fontSize="16" marginRight="0.5em" textAlign="left">
                  Assinatura:
                </chakra.span>
                <chakra.span fontWeight="bold" color={'#ffffff'} fontSize="16" textAlign="left">
                  {user.titular
                    ? String(descricao_plano).charAt(0).toUpperCase() + String(descricao_plano).slice(1).toLowerCase()
                    : titular_dados?.plano}
                </chakra.span>
              </chakra.p>
              <chakra.p fontSize="16" display="flex" alignItems="center">
                <chakra.span color={'#ffffff'} fontSize="16" textAlign="left">
                  Valor:
                </chakra.span>
                <chakra.span fontWeight="bold" color={'#ffffff'} fontSize="16" ml="0.5em">
                  R$ {valor?.length > 0 ? valor : '0.00'}
                </chakra.span>
              </chakra.p>
            </VStack>
          </VStack>
        </VStack>
        <Box w={'74%'}>
          <HStack justify="space-between" w="100%" mt={'1em'}>
            <Heading fontSize="16" color={'#585858'} fontWeight={'bold'}>
              Dados do Titular
            </Heading>

            <chakra.span
              fontSize="14"
              color={'white'}
              bg={'#EB8B2A'}
              borderRadius={'1em'}
              padding={'0.3em'}
              h={'1.9em'}
              onClick={() => navigate('/perfil/dados-pessoais')}
            >
              Editar dados
            </chakra.span>
          </HStack>
          <VStack align="left" mb={'1em'}>
            <chakra.span fontSize="14" color={'#585858'}>
              Nome Completo:{' '}
            </chakra.span>
            <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}>
              {' '}
              {user.titular ? user.name : titular_dados.nome}{' '}
            </chakra.span>
          </VStack>

          <HStack justify="space-between" w="100%">
            <VStack align="left">
              <chakra.span fontSize="14" color={'#585858'}>
                CPF:{' '}
              </chakra.span>
              <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}>
                {' '}
                {formatCPF(user.titular ? user.cpf : titular_dados?.cpf)}{' '}
              </chakra.span>
            </VStack>
            <VStack align="left">
              <chakra.span fontSize="14" color={'#585858'}>
                Nascimento:{' '}
              </chakra.span>
              <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}>
                {' '}
                {formatAniversario(user.titular ? user.data_aniv : titular_dados?.nascimento)}{' '}
              </chakra.span>
            </VStack>
          </HStack>
          <Spacer border={'1px solid #f0f0f0'} />
        </Box>
        {user?.id_contrato_dependent?.paciente_contrato_id > 0 ? (
          <></>
        ) : (
          <>
            <Box w={'74%'}>
              <HStack justify="space-between" w="100%" mt={'1em'}>
                <Heading fontSize="16" color={'#585858'} fontWeight={'bold'}>
                  Dependentes
                </Heading>
                <Box display="inline-flex">
                  <Image
                    boxSize="15px"
                    objectFit="cover"
                    src={Editar}
                    alt=""
                    mr={'1.1em'}
                    onClick={() => {
                      if (restricao_dependente) {
                        callToast(
                          'Importante',
                          'Há menos de 90 dias, um dependente vinculado a você excluiu a conta. Você só poderá adicionar outro em 90 dias.',
                          'error'
                        );
                        return;
                      }
                      let limit = user.qtde_max_dependentes_total;
                      let limitIncluso = user.qtde_max_dependentes;
                      if (dependentes.length >= limitIncluso && dependentes.length < limit) {
                        disclosureDependentes.onOpen();
                      }
                      if (dependentes.length >= limit) {
                        toast({
                          title: 'Importante',
                          description: `Você já atingiu o limite de ${limit} dependentes`,
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'red',
                          containerStyle: {
                            paddingTop: '50px',
                          },
                        });
                        return;
                      }
                      if (dependentes.length < limitIncluso) {
                        navigate('/perfil/dependentes/cadastro/', {
                          state: { navigate_to: '/perfil/assinaturas', id_contrato: user.id_contrato, extra: false },
                        });
                      }
                    }}
                  />
                  <FaCircleInfo
                    color="grey"
                    onClick={() => {
                      petDisclosure.onOpen();
                    }}
                  />
                </Box>
              </HStack>

              <VStack w={'100%'}>{useDependentes}</VStack>
            </Box>

            <Box w={'74%'}>
              {user?.titular && (
                <>
                  <HStack justify="space-between" w="100%" mt={'1em'}>
                    <Heading fontSize="16" color={'#585858'} fontWeight={'bold'}>
                      Pets
                    </Heading>
                    <Box display="inline-flex">
                      <Image
                        boxSize="15px"
                        objectFit="cover"
                        src={Editar}
                        alt=""
                        mr={'1.1em'}
                        onClick={() => {
                          let limit = user.qtde_max_dependentes;
                          if (pets.length >= 1) {
                            callToast('Importante', `Você já atingiu o limite de 1 pet gratuito incluso no seu plano`, 'warning');
                          } else {
                            navigate('/perfil/dependentes/cadastro-pet/', {
                              state: {
                                navigate_to: '/perfil/assinaturas',
                                id_cliente_deovita: user.id_cliente_deovita,
                                id_contrato: user.id_contrato,
                              },
                            });
                          }
                        }}
                      />
                      <FaCircleInfo
                        color="grey"
                        onClick={() => {
                          dependenteDisclosure.onOpen();
                        }}
                      />
                    </Box>
                  </HStack>

                  <VStack w={'100%'}>{usePets}</VStack>
                </>
              )}
            </Box>
          </>
        )}
        <Box w={'74%'}>
          <Heading fontSize="16" fontWeight="bold" mb="1em" color={'#585858'} mt={'1em'}>
            Opções
          </Heading>
          {user?.titular && user?.qtde_max_dependentes_total <= 2 && user?.id_forma_pagamento !== 13 && (
            <>
              <HStack
                align="left"
                spacing={4}
                onClick={() => {
                  if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                    return;
                  }
                  trocarplanoDisclousure.onOpen();
                }}
              >
                <Image boxSize="20px" objectFit="cover" src={TrocarPlano} alt="" />
                <chakra.span fontSize="12" color={'#585858'}>
                  {' '}
                  Trocar plano{' '}
                </chakra.span>
              </HStack>
              <Spacer border={'0.1px solid #f0f0f0'} />
            </>
          )}
          <HStack
            align="left"
            mt={'1em'}
            spacing={4}
            onClick={() => navigate('/perfil/assinaturas/mais-opcoes', { state: { dependentes: dependentes, pet: pets } })}
          >
            <Image boxSize="20px" objectFit="cover" src={OutrasOpcoes} alt="" />
            <chakra.span fontSize="12" color={'#585858'}>
              {' '}
              Outras opções{' '}
            </chakra.span>
          </HStack>
          <Spacer border={'0.1px solid #f0f0f0'} />
        </Box>
      </VStack>

      <BottomNavBar />
    </>
  ) : (
    <>
      <ChildHeader /> <Loading />
    </>
  );
}

export default Main;
