import { Box, Heading, Image, chakra, Img, Text, VStack, Button, Switch, ButtonGroup, InputGroup, HStack, InputLeftElement } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Contact from '../../../../../../images/ClinicoIcon.svg';
import BackButton from '../../../../../../images/assets/telemedicina/backbutton.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import IconMedico from '../../../../../../images/iconeMedico.svg';
import { AccountContext } from 'components/AccountContext';
import SaudeDeslogado from '../../../../../../images/Deslogado/SaudeDeslogado.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

export default function Step2() {
  const { state } = useLocation();
  const { user } = useContext(AccountContext);
  const [medidor, setMedidor] = useState(false);
  const [medtemp, setMedtemp] = useState(false);
  const navigate = useNavigate();
  let initialValues = {
    pulso: '',
    pd: '',
    ps: '',
    temperatura: '',
  };
  let validationSchema = {
    pulso: !medidor
      ? Yup.string()
          .required('É necessário informar o pulso')
          .matches(/^[0-9\s]*$/, 'Somente números')
      : '',
    pd: !medidor
      ? Yup.string()
          .required('É obrigatorio informar o pd')
          .matches(/^[0-9\s]*$/, 'Somente números')
      : '',
    ps: !medidor
      ? Yup.string()
          .required('É obrigatorio informar o ps')
          .matches(/^[0-9\s]*$/, 'Somente números')
      : '',
    temperatura: !medtemp
      ? Yup.string()
          .required('É necessário informar a temperatura')
          .matches(/^[0-9]*\.?[0-9]+$/, 'Temperatura deve conter apenas números e ponto decimal')
      : '',
  };

  async function searchValorCoparticipacao() {
    const url = ENDPOINT + '/clients/fetchValorCoparticipacao/';
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({pediatra: false}),
        });
        const postsData = await response.json();
        return postsData
      } catch (error) {
        console.log('error', error);
      }
  }

  const Formulario = ({ formikProps }) => {
    const { setFieldValue, values } = formikProps;

    const formatarTemperatura = (peso) => {
      const apenasNumeros = peso.replace(/[^\d]/g, '');
      if (apenasNumeros.length > 2) {
        const parteInteira = apenasNumeros.slice(0, -1);
        const parteDecimal = apenasNumeros.slice(-1);
        return `${parteInteira}.${parteDecimal}`;
      }
      return apenasNumeros;
    };

    const handleTemperaturaChange = (e) => {
      const peso = e.target.value;
      const pesoFormatado = formatarTemperatura(peso);
      setFieldValue('temperatura', pesoFormatado);
    };
    return (
      <>
        {' '}
        <VStack as={Form} align="start" p="2em" w="100%">
          <Text mt="10%" size="xs" fontWeight="bold" color="#585858">
            Vamos aferir sua pressão
          </Text>
          <Text w="100%" textAlign="left">
            <Switch
              id="medidor"
              name="medidor"
              isChecked={medidor}
              colorScheme="orange"
              onChange={(e) => {
                setMedidor(e.target.checked);
                if (e.target.checked === true) {
                  setFieldValue('pulso', '');
                  setFieldValue('pd', '');
                  setFieldValue('ps', '');
                } else {
                  setFieldValue('pulso', '');
                  setFieldValue('pd', '');
                  setFieldValue('ps', '');
                }
              }}
            />{' '}
            Não possuo Medidor de Pressão (Esfigmomanômetro)
          </Text>
          {!medidor && (
            <>
              <Text textAlign="left">
                Pulso<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                <TextField name="pulso" placeholder="Bpm" type="tel" maxLength={3} />
                <Text color="gray.500" fontSize={'14px'} ml={'-13.6em'} mt={'1.2em'}>
                  {values.pulso ? 'bpm' : ''}
                </Text>
              </InputGroup>
              <HStack spacing={6} align="start">
                <VStack spacing={1} align="start">
                  <Text textAlign="left">
                    P.D(max)<chakra.span color="red">*</chakra.span>
                    <TextField name="pd" placeholder="00" type="tel" maxLength={3} />
                  </Text>
                </VStack>
                <VStack spacing={1} align="start">
                  <Text textAlign="left">
                    P.S(min)<chakra.span color="red">*</chakra.span>
                  </Text>
                  <TextField name="ps" placeholder="00" type="tel" maxLength={3} />
                </VStack>
              </HStack>
            </>
          )}
          <Text mt="10%" size="xs" fontWeight="bold" color="#585858">
            Vamos medir sua temperatura
          </Text>
          <Text w="100%" textAlign="left">
            <Switch
              id="medtemp"
              name="medtemp"
              isChecked={medtemp}
              colorScheme="orange"
              onChange={(e) => {
                setMedtemp(e.target.checked);
                if (e.target.checked === true) {
                  setFieldValue('temperatura', '');
                } else {
                  setFieldValue('temperatura', '');
                }
              }}
            />
            Não possuo Medidor de Temperatura (Termômetro)
          </Text>
          {!medtemp && (
            <>
              <Text textAlign="left">
                Temperatura<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                {values.temperatura ? (
                  <>
                    <InputLeftElement pointerEvents="none">
                      <Text color="gray.500" fontSize="16px" ml={'8em'} mt={'1.2em'}>
                        °C
                      </Text>
                    </InputLeftElement>
                  </>
                ) : (
                  ''
                )}

                <TextField name="temperatura" placeholder="°C" type="tel" maxLength={6} onChange={handleTemperaturaChange} />
              </InputGroup>
            </>
          )}
          <Button w="100%" background={usuarioAssinante ? '#EB8B2A' : '#529C94'} textColor="white" type="submit">
            Continuar
          </Button>
        </VStack>
      </>
    );
  };
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  return (
    <VStack align="center" wrap="no-wrap" minH="100vh" spacing={0} pt="6em" pb="6em" background={usuarioAssinante ? '#EB8B2A' : '#529C94'} color="#585858" overflow={'scroll'}>
      <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
      <Box
        id=""
        as="card"
        width="95%"
        minHeight="75vh"
        borderRadius="18px"
        fontSize="xs"
        size="10"
        backgroundColor="white"
        maxInlineSize="80"
        textAlign="center"
      >
        <Img ml="42.5%" mt="15%" src={!usuarioAssinante ? SaudeDeslogado :IconMedico} w="15%" />
        <Heading m="2%" size="xs">
          Falta pouco!
        </Heading>
        <Heading m="2%" size="xs">
          Preencha os campos abaixo para que a gente possa te atender melhor!
        </Heading>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values) => {
            if (!usuarioAssinante && !state.pago) {
              navigate('/pagamentos', {
                state: {
                  telemedPaga: user?.allowsTelemedicina?.paga_telemedicina,
                  parcelsActive: true,
                  valor: state?.metadataCoparticipacao.valortotal ? state?.metadataCoparticipacao.valortotal : 30,
                  expiration_pix_in_min: 5,
                  service: 'telemedicine_deovita',
                  navig_to: '/medico-agora-atualiza-telefone',
                  ...state,
                  ...values,
                  faturado: false,
                  item: {
                    ...state,
                    ...values,
                  },
                  metadataCoparticipacao: state.metadataCoparticipacao,
                },
              });
              return;
            } else if(usuarioAssinante && !user?.allowsTelemedicina?.paga_telemedicina) {
              navigate('/medico-agora-atualiza-telefone', {
                state: {
                  ...state,
                  ...values,
                  faturado: true,
                },
              });
            }
            if (user.allowsTelemedicina.paga_telemedicina === true && !state.pago) {
              const valorCoparticipacao = await searchValorCoparticipacao()
              console.log('valorCoparticipacao', valorCoparticipacao);
              const metadataCoparticipacao = {
                convenio_id: valorCoparticipacao?.convenio_id,
                procedimento_convenio_id: valorCoparticipacao?.procedimento_convenio_id,
                procedimento_tuss_id: valorCoparticipacao?.procedimento_tuss_id,
                valortotal: valorCoparticipacao.valortotal,
              }
              navigate('/pagamentos', {
                state: {
                  telemedPaga: user.allowsTelemedicina.paga_telemedicina,
                  parcelsActive: true,
                  valor: valorCoparticipacao.valortotal ? valorCoparticipacao.valortotal : 30,
                  expiration_pix_in_min: 5,
                  service: 'telemedicine_deovita',
                  navig_to: '/medico-agora-atualiza-telefone',
                  ...state,
                  ...values,
                  faturado: false,
                  item: {
                    ...state,
                    ...values,
                  },
                  metadataCoparticipacao: metadataCoparticipacao,
                },
              });
            } else {
              navigate('/medico-agora-atualiza-telefone', {
                state: {
                  ...state,
                  ...values,
                  faturado: true,
                },
              });
            }
          }}
        >
          {(props) => <Formulario formikProps={props} />}
        </Formik>
      </Box>
    </VStack>
  );
}
