import React, {  } from 'react';
import usePushNotificationToken from '../hooks/usePushNotificationToken';

// Higher Order Function component
const withPushNotifications = (WrappedComponent) => {
  console.log('aquui dentro do withPushNotifications 1');
  // You can use hooks and state here
  const EnhancedComponent = (props) => {
    console.log('aquui dentro do withPushNotifications 2');
    usePushNotificationToken();

    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};


export default withPushNotifications;
