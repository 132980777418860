import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  Radio,
  useDisclosure,
  HStack,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  useMediaQuery,
  Flex,
  Button,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PetsImg from '../../../../../images/assets/telemedicina/pets.png';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import { Form, Formik } from 'formik';
import SelectField from 'components/SelectField';
import contrato from '../../../../../termos/telemedicina_vet.pdf';
import Pdf from '../../../../PdfViewer';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const disclosure = useDisclosure();
  const [accept, setAccepted] = useState();
  const [dependentes, setDependentes] = useState(false);
  const [client, setClient] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const { state } = useLocation();

  const fetchDependentes = async () => {
    const url2 = ENDPOINT + '/client/pets';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    const dependentes = resp.data;
    setDependentes(dependentes);
    setClient(dependentes[0].paciente_id);
  };

  useEffect(() => {
    fetchDependentes();
  }, []);

  const useDependentes = dependentes
    ? dependentes.map((dependente, index) => {
        console.log(dependente);
        console.log(index);
        if (index > 0) {
          return (
            <>
              <Radio value={`${index}`} isDisabled={isDisabled}>
                {dependente.nome ? dependente.nome.split(' ')[0] + ' ' + (dependente.nome.split(' ')[1] ? dependente.nome.split(' ')[1] : '') : ''}
              </Radio>
            </>
          );
        }
      })
    : [];
  const [isLargeWidth] = useMediaQuery('(min-width: 668px)');

  async function searchValorCoparticipacao() {
    const url = ENDPOINT + '/clients/fetchValorCoparticipacao/';
    try {
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ pediatra: true }),
      });
      const postsData = await response.json();
      return postsData;
    } catch (error) {
      console.log('error', error);
    }
  }
  return (
    <>
      <Drawer isCentered isFullHeight size="md" isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
          <Pdf file={contrato} />
          <DrawerCloseButton color="red" borderColor="red" pt="15%" />
        </DrawerContent>
      </Drawer>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="#EB8B2A"
      >
        <Box
          as="card"
          width="328px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
          height={'55%'}
        >
          <VStack m={{ sm: '10%' }} textColor="#585858">
            <VStack m="5%" textColor="#585858">
              <VStack m="10%">
                {/*<Img src={PetsImg} w="30%" mt="2em" /> */}
                <Image top={{ lg: '5.5em', sm: '5em' }} src={Contact} alt="teste" height={'4.5em'} />

                <Text fontWeight="bold" fontSize="1.5em" color={'#585858'}>
                  Qual paciente <br /> será atendido?
                </Text>
                <Text fontSize="16px" color="#585858">
                  Selecione o paciente abaixo
                </Text>
                <Formik initialValues={{ dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
                  <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" justify="top" overflow="hidden" align="start">
                    <SelectField
                      name="dependente"
                      placeholder="Selecione"
                      w={isLargeWidth ? '28vh' : '30vh'}
                      variant="outline"
                      borderRadius={'0.8em'}
                      textAlign="left"
                      fontWeight="normal"
                      backgroundColor={'#EEEEEE'}
                      onChange={(e) => {
                        console.log(e);
                      }}
                      options={arrayNomeDependentes}
                    />
                  </VStack>
                </Formik>
                {/* 
               <HStack w="100%" pl="1.5em">
                  <Checkbox
                    name="termos"
                    onChange={(e) => {
                      if (!accept) {
                        document.getElementById('callBtn').style.color = '#529C94';
                        setAccepted(true);
                      } else {
                        document.getElementById('callBtn').style.color = '#D3D3D3';
                        setAccepted(false);
                      }
                    }}
                  ></Checkbox>
                  <Text
                    fontSize="0.8em"
                    color="#529C94"
                    onClick={() => {
                      disclosure.onOpen();
                    }}
                  >
                    Eu li e aceito os {` `}
                    <chakra.a colorScheme="teal">
                      <u>Termos e Condições</u>
                    </chakra.a>
                  </Text>
                </HStack>
               */}
                {dependentes.length === 0 ? (
                  <>
                    <Flex bg="#D60000" p="10px" borderRadius="10px" w={isLargeWidth ? '50%' : '100%'}>
                      <VStack w="100%" spacing="5px">
                        <HStack borderRadius="0.8em" w="100%" alignItems="left">
                          <Image src={PetsImg} w={'20%'} />
                        </HStack>
                        <Text fontSize="16px" color="white" textAlign={'left'}>
                          É necessário que você possua pelo menos um pet cadastrado para ser atendido.
                        </Text>
                      </VStack>
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {dependentes.length > 0 ? (
                  <>
                    <Button
                      textAlign="center"
                      id="callBtn"
                      color={'white'}
                      fontWeight="bold"
                      w={isLargeWidth ? '60%' : '100%'}
                      fontSize="1.5em"
                      backgroundColor={'#EB8B2A'}
                      onClick={async () => {
                        if (!client) {
                          setError('você precisa cadastrar um pet para começar a utilizar o serviço');
                        }
                        if (client) {
                          if (user.allowsTelemedicina.paga_telemedicina === true && !state.pago) {
                            const valorCoparticipacao = await searchValorCoparticipacao();
                            const metadataCoparticipacao = {
                              convenio_id: valorCoparticipacao?.convenio_id,
                              procedimento_convenio_id: valorCoparticipacao?.procedimento_convenio_id,
                              procedimento_tuss_id: valorCoparticipacao?.procedimento_tuss_id,
                              valortotal: valorCoparticipacao.valortotal,
                            };
                            navigate('/pagamentos', {
                              state: {
                                parcelsActive: true,
                                metadataCoparticipacao: metadataCoparticipacao,
                                valor: valorCoparticipacao.valortotal ? valorCoparticipacao.valortotal : '30',
                                expiration_pix_in_min: 5,
                                service: 'telemedicine_deovita_pet',
                                navig_to: '/telepet/fila',
                                faturado: false,
                                item: {},
                                credito: state.credito,
                              },
                            });
                          } else {
                            navigate('/telepet/fila', {state: { credito: state.credito, pago: state.pago}});
                          }
                        }
                      }}
                    >
                      Continuar
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </VStack>
            </VStack>
          </VStack>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
