import { Box, Heading, Image, chakra, Img, Text, VStack, Button, Switch, ButtonGroup, HStack, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import Contact from '../../../../../../images/ClinicoIcon.svg';
import BackButton from '../../../../../../images/assets/telemedicina/backbutton.svg';
import IconMedico from '../../../../../../images/iconeMedico.svg';
import SaudeDeslogado from '../../../../../../images/Deslogado/SaudeDeslogado.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import SelectFieldMultiplo from 'components/SelectFieldMultiplo';
import { Form, Formik } from 'formik';
import TextField from 'components/TextField';
import * as Yup from 'yup';
import { set } from 'date-fns';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

export default function Step1() {
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [medicado, setMedicado] = useState(false);
  const [comorbidades, setComorbidades] = useState([]);
  const [sintomas, setSintomas] = useState([]);
  const [comorbidadesSelecionadas, setComorbidadesSelecionadas] = useState([]);
  const [sintomasSelecionados, setSintomasSelecionados] = useState([]);
  const initialValues = {
    atestado: '',
    peso: '',
    altura: '',
    imc: '',
    medicado: false,
    medicacoes: '',
    alergias: '',
    comorbidade: '',
    sintomas_agudos: '',
  };

  const validationSchema = Yup.object({
    atestado: Yup.string().required('É necessário escolher uma opção'),
    peso: Yup.string()
      .required('É obrigatório informar o peso')
      .matches(/^[0-9]+$/, 'Peso deve conter apenas números inteiros'),
    altura: Yup.string()
      .required('É obrigatório informar a altura')
      .matches(/^[0-9]*\,?[0-9]+$/, 'Altura deve conter apenas números e vírgula decimal'),
    imc: Yup.string().required('É necessário informar o imc'),
    medicacoes: medicado ? Yup.string().required('É necessário informar a medicação') : '',
    comorbidade: Yup.number().required('É necessário escolher uma opção'),
    sintomas_agudos: Yup.number().required('É necessário escolher uma opção'),
  });

  useEffect(() => {
    async function fetchComobirdades() {
      const url = ENDPOINT + '/clients/searchComorbidades/';
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({}),
        });
        let postsData = await response.json();
        if (postsData) {
          postsData.sort((a, b) => {
            if (a.comorbidades_id === 0) return -1;
            if (b.comorbidades_id === 0) return 1;
            return b.comorbidades_id - a.comorbidades_id;
          });
        }
        setComorbidades(postsData);
      } catch (error) {}
    }

    async function fetchSintomas() {
      const url = ENDPOINT + '/clients/fetchSintomas/';
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({}),
        });
        const postsData = await response.json();
        if (postsData) {
          postsData.sort((a, b) => {
            if (a.sintomas_id === 0) return -1;
            if (b.sintomas_id === 0) return 1;
            return b.sintomas_id - a.sintomas_id;
          });
        }
        setSintomas(postsData);
      } catch (error) {}
    }

    fetchComobirdades();
    fetchSintomas();
  }, []);

  const handleChangeComorbidades = (selectedIndices) => {
    const novosIds = selectedIndices.map((index) => comorbidades[index]);
    setComorbidadesSelecionadas(novosIds);
  };

  const handleChangeSintomas = (selectedIndices) => {
    const novosIds = selectedIndices.map((index) => sintomas[index]);
    setSintomasSelecionados(novosIds);
  };

  const arrayNomeComorbidades = comorbidades
    ? comorbidades.map((comorbidade) => {
        return comorbidade.nome;
      })
    : [];

  const arraySintomasAgudos = sintomas
    ? sintomas.map((sintoma) => {
        return sintoma.nome;
      })
    : [];
  const FormularioCadastro = ({ formikProps }) => {
    const { setFieldValue, values } = formikProps;

    const formatarAltura = (altura) => {
      const apenasNumeros = altura.replace(/\D/g, '');

      const alturaFormatada = apenasNumeros.replace(/^(\d{1})(\d{2})$/, '$1,$2');

      return alturaFormatada;
    };

    const formatarPeso = (peso) => {
      const apenasNumeros = peso.replace(/[^\d]/g, '');

      if (apenasNumeros.length > 2) {
        const parteInteira = apenasNumeros.slice(0, -1);
        const parteDecimal = apenasNumeros.slice(-1);
        return `${parteInteira},${parteDecimal}`;
      }

      return apenasNumeros;
    };

    const handleAlturaChange = (e) => {
      const altura = e.target.value;
      const alturaFormatada = formatarAltura(altura);
      setFieldValue('altura', alturaFormatada);
    };

    const handlePesoChange = (e) => {
      const peso = e.target.value;
      //const pesoFormatado = formatarPeso(peso);
      setFieldValue('peso', e.target.value);
    };

    const calculateIMC = () => {
      const peso = parseFloat(values.peso.replace(',', '.'));
      const altura = parseFloat(values.altura.replace(',', '.'));

      if (peso > 0 && altura > 0) {
        const imc = peso / (altura * altura);
        let categoria;

        if (imc < 18.5) {
          categoria = 'Abaixo do peso';
        } else if (imc >= 18.5 && imc < 25) {
          categoria = 'Normal';
        } else if (imc >= 25 && imc < 30) {
          categoria = 'Sobrepeso';
        } else {
          categoria = 'Obesidade';
        }

        setFieldValue('imc', imc.toFixed(2) + ' - ' + categoria);
      }
    };

    return (
      <>
        <VStack as={Form} w="100%" p="2em" align="start">
          <Text>
            Precisa de Atestado Médico?<chakra.span color="red">*</chakra.span>
          </Text>
          <SelectField
            name="atestado"
            placeholder="Selecione"
            w="100%"
            textAlign="left"
            variant="outline"
            fontWeight="normal"
            onChange={(e) => setFieldValue('atestado', e.target.value)}
            options={['Sim', 'Não']}
          />

          <HStack spacing={6} align="start">
            <VStack spacing={1} align="start">
              <Text>
                Peso<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                <TextField name="peso" placeholder="000 KG" type="tel" maxLength={3} onChange={handlePesoChange} />
                <Text color="gray.500" fontSize={'14px'} ml={'-3.5em'} mt={'1.2em'}>
                  {values.peso ? 'KG' : ''}
                </Text>
              </InputGroup>
            </VStack>

            <VStack spacing={1} align="start">
              <Text>
                Altura<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                <TextField
                  name="altura"
                  placeholder="0,00 m"
                  maskChar={null}
                  type="tel"
                  maxLength={4}
                  value={values.altura}
                  onChange={handleAlturaChange}
                  onBlur={calculateIMC}
                />
                <Text color="gray.500" fontSize={'14px'} ml={'-3.5em'} mt={'1.2em'}>
                  {values.altura ? 'm' : ''}
                </Text>
              </InputGroup>
            </VStack>
          </HStack>

          <Text textAlign="left">IMC</Text>
          <TextField name="imc" placeholder="Classificação" type="tel" disabled />
          <Text>
            {' '}
            <Switch
              id="medicado"
              name="medicado"
              isChecked={medicado}
              colorScheme="orange"
              onChange={(e) => {
                setMedicado(e.target.checked);
              }}
            />{' '}
            Estou medicado(a)
          </Text>
          {medicado ? (
            <>
              <Text textAlign="left">Medicações {medicado ? <chakra.span color="red">*</chakra.span> : ''} </Text>
              <TextField name="medicacoes" placeholder="Digite aqui as medicações" type="text" maxLength={150} />
            </>
          ) : (
            <></>
          )}

          <Text textAlign="left">
            Comorbidades<chakra.span color="red">*</chakra.span>
          </Text>
          <SelectFieldMultiplo
            name="comorbidade"
            placeholder="Selecione"
            w="100%"
            h={'3em'}
            textAlign="left"
            variant="outline"
            fontWeight="normal"
            options={arrayNomeComorbidades}
            value={comorbidadesSelecionadas.map((comorb) => arrayNomeComorbidades.indexOf(comorb))}
          />
          <Text textAlign="left">
            Sintomas agudos<chakra.span color="red">*</chakra.span>
          </Text>
          <SelectFieldMultiplo
            name="sintomas_agudos"
            placeholder="Selecione"
            w="100%"
            h={'3em'}
            textAlign="left"
            variant="outline"
            fontWeight="normal"
            options={arraySintomasAgudos}
            value={sintomasSelecionados.map((sintomas) => arraySintomasAgudos.indexOf(sintomas))}
          />
          <Text textAlign="left">Alergias</Text>
          <TextField name="alergias" placeholder="Digite aqui as alergias" type="text" maxLength={150} />
          <ButtonGroup pt="1rem" pb="25%" width="100%">
            <Button w="100%" background={usuarioAssinante ? '#EB8B2A' : '#529C94'} textColor="white" type="submit">
              Continuar
            </Button>
          </ButtonGroup>
        </VStack>
      </>
    );
  };

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <VStack
      align="center"
      wrap="no-wrap"
      minH="100vh"
      spacing={0}
      pt="6em"
      pb="6em"
      background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
      color="#585858"
      overflow={'scroll'}
    >
      <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
      <Box
        id=""
        as="card"
        width="95%"
        minHeight="75vh"
        borderRadius="18px"
        fontSize="xs"
        size="10"
        backgroundColor="white"
        maxInlineSize="80"
        textAlign="center"
      >
        {' '}
        <Img ml="40%" mt="15%" src={!usuarioAssinante ? SaudeDeslogado : IconMedico} w="15%" />
        <Heading m="2%" size="xs">
          Preencha o formulário abaixo antes de prosseguir para seu atendimento
        </Heading>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const comorbidade_ids = values.comorbidade ? values.comorbidade.map((index) => comorbidades[index]) : [];
            const sintomas_ids = values.sintomas_agudos ? values.sintomas_agudos.map((index) => sintomas[index]) : [];
            const imcValue = parseFloat(values.imc.replace(/[^0-9.,-]/g, ''));

            const valores = {
              atestado: values.atestado,
              peso: values.peso.replace(',', '.'),
              altura: values.altura.replace(',', '.'),
              imc: imcValue,
              medicado: values.medicado,
              alergias: values.alergias,
              comorbidade: values.comorbidade,
              medicacoes: values.medicacoes,
              sintomas_agudos: values.sintomas_agudos,
            };

            navigate('/medico-agora/triagem2', {
              state: {
                ...state,
                valores,
                comorbidadesSelecionadas: comorbidade_ids,
                sintomasSelecionados: sintomas_ids,
                pago: state?.pago,
                credito: state.credito,
                pediatra: state?.pediatra,
                metadataCoparticipacao: state?.metadataCoparticipacao,
              },
            });
          }}
        >
          {(props) => (
            <>
              <FormularioCadastro formikProps={props} />
            </>
          )}
        </Formik>
      </Box>
    </VStack>
  );
}
