import React, { useCallback, useContext, useEffect, useState } from 'react';
import ChildHeader from './ChildHeader';
import { AccountContext } from 'components/AccountContext';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useDisclosure,
  useMediaQuery,
  useTab,
  useToast,
} from '@chakra-ui/react';

//imagens
import BannerMDV from '../../../images/BanneMDVr.png';
import BolsaMdv from '../../../images/assets/icons/MDVNaoAssinante/bolsa.svg';
import CelularMdv from '../../../images/assets/icons/MDVNaoAssinante/celular.svg';
import MoneyMdv from '../../../images/assets/icons/MDVNaoAssinante/money.svg';
import { useNavigate } from 'react-router-dom';
import { loadPlans } from 'api/plans';

import { ReactComponent as Heart } from '../../../images/heart.svg';
import { ReactComponent as FamiliaPlus } from '../../../images/familia-plus.svg';
import { ReactComponent as Familia } from '../../../images/plano-familia.svg';
import { ReactComponent as Individual } from '../../../images/individual.svg';
import { ReactComponent as PlanoRecomendado } from '../../../images/plano-recomendado.svg';

function MdvNaoAssinante() {
  const { setPage, setUser, user } = useContext(AccountContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [medico, setMedico] = useState(false);
  const [tipo, setTipo] = useState('');
  const disclosure = useDisclosure();
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  setPage('Clube de Vantagens');
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await loadPlans();
        setPlans(data);
      } catch (error) {
        //setError(true);
      } finally {
        //setLoading(false);
      }
    })();
  }, []);

  function getPlanColor(planID) {
    switch (planID) {
      case 29: // FAMILIA
        return '#EB8B2A';
      case 55: //INDIVIDUAL
        return '#529C94';

      default:
        return '#FF8000';
    }
  }

  const [frequenciaPagamento, setFrequenciaPagamento] = useState(0);

  function getPlanIcon(planID) {
    switch (planID) {
      case 29: // FAMILIA
        return <Familia height={20}></Familia>;
      case 55: //INDIVIDUAL
        return <Individual height={20}></Individual>;
      case 137:
        return <FamiliaPlus height={20}></FamiliaPlus>;

      default:
        return <Individual height={20}></Individual>;
    }
  }

  function CardDadosPlano() {
    const [lg] = useMediaQuery('(min-width: 425px)');

    const TabFrequenciaPlano = React.forwardRef((props, ref) => {
      const tabProps = useTab({ ...props, ref });
      const isSelected = !!tabProps['aria-selected'];
      return (
        <Text
          {...tabProps}
          py={1}
          px={2}
          textAlign={'center'}
          bg={isSelected ? 'white' : 'transparent'}
          color={isSelected ? props.color : 'white'}
          borderRadius={'full'}
          border={`2px solid white`}
        >
          {tabProps.children}
        </Text>
      );
    });

    return plans?.map((item, index) => {
      const idPlano = item.id;
      const menorValor = item?.parcelas && item.parcelas.length > 0 ? Math.min(...item.parcelas.map((parcela) => parcela.min_valor)) : null;
      const beneficiosFixos = [
        {
          beneficios_plano_id: 1,
          descricao: 'Adicione até 4 dependentes + 1 Pet.',
        },
        {
          beneficios_plano_id: 2,
          descricao: 'Desconto de 50% em consultas de telemedicina.',
        },
        {
          beneficios_plano_id: 3,
          descricao: 'Até 80% de desconto em consultas e exames presenciais.',
        },
        {
          beneficios_plano_id: 4,
          descricao: 'Obs: Este plano não inclui telemedicina sem custo adicional. Para esse benefício, escolha o plano Família ou Individual.',
        },
      ];
      const beneficios = item.id === 70 ? beneficiosFixos : item.beneficios;
      return (
        <Box
          key={item.id}
          plan={item}
          p={8}
          borderRadius={20}
          width={'100%'}
          flexShrink={0}
          scrollSnapAlign={'center'}
          overflow={'visible'}
          position={'relative'}
          fontSize={lg ? 'md' : 'xs'}
          border={`1px solid ${getPlanColor(item.id)}`}
          bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
        >
          <Center bg={getPlanColor(item.id)} color={'white'} gap={2} p={2} borderRadius={10} textAlign={'center'}>
            {getPlanIcon(item.id)} Plano {item.nome}
          </Center>

          <Box mb={2}>
            <Tabs
              variant="solid-rounded"
              colorScheme={getPlanColor(item.id)}
              index={frequenciaPagamento}
              bg={getPlanColor(item.id)}
              onChange={(index) => {
                setFrequenciaPagamento(index);
              }}
              mt={4}
              p={0.5}
              borderRadius={20}
            >
              <TabPanels color={'white'}>
                {item?.parcelas?.map(({ min_valor, quantidade }) => (
                  <TabPanel px={4}>
                    <Center flexDirection={'column'}>
                      <Text fontSize={lg ? 'xl' : 'md'} alignSelf={'flex-start'}>
                        A partir de
                      </Text>
                      <Text fontSize={lg ? '5xl' : '3xl'} fontWeight={'bold'} lineHeight={1} mb={4} alignSelf={'flex-start'}>
                        {Number(menorValor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Text>
                      <Text fontSize={'xs'}>* O valor do plano será ajustado de acordo com a forma de pagamento escolhida.</Text>
                    </Center>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>

          <Center>
            <Button
              bottom={0}
              color="#fff"
              backgroundColor={`${getPlanColor(item.id)}`}
              borderRadius={20}
              mt={'1em'}
              onClick={() => {
                if (user.id_contrato > 0) {
                  if (user.contrato_pendente_pagamento === true) {
                    toast({
                      title: 'Assinatura pendente',
                      description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  } else {
                    if (user.loggedIn === true && user.id_contrato > 0) {
                      if (medico === true) {
                        navigate('/telepet/termos');
                      } else {
                        toast({
                          title: 'Indisponível',
                          description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'green',
                          containerStyle: {
                            paddingBottom: '5em',
                          },
                        });
                      }
                    } else {
                      setTipo('Veterinário');
                      disclosure.onOpen();
                    }
                  }
                } else if (user.loggedIn === false) {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro');
                } else {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service: 'telemedicine',
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro/plano', {
                    state: {
                      client_id: user.id_cliente_deovita,
                      name: user.name,
                      cpf: user.cpf,
                    },
                  });
                }
              }}
            >
              {user.loggedIn === false ? 'Quero me cadastrar agora!' : 'Quero assinar agora!'}
            </Button>
          </Center>
          <Box pt={4} borderTop={'2px solid #D0D0D0'} mt={6} color={'#585858'}>
            <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'} mb={4}>
              Com o <span style={{ color: getPlanColor(item.id) }}>Plano {item.nome}</span>, você pode:
            </Text>
            {item.beneficios &&
              item.beneficios.map((beneficio) => (
                <HStack mt={1}>
                  <chakra.span fontSize="16px">
                    <Heart style={{ flexShrink: 0, marginTop: 4 }} color={getPlanColor(idPlano)}></Heart>
                  </chakra.span>
                  {
                    <chakra.span color={'#585858'} fontSize="16px">
                      {beneficio.descricao}
                    </chakra.span>
                  }
                </HStack>
              ))}
          </Box>
        </Box>
      );
    });
  }
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack width="100%" align="center" spacing={4}>
        <Box width="90%" mt="1em" borderRadius="2em" overflow="hidden">
          <Image src={BannerMDV} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: '2em' }} alt="Banner MDV" />
        </Box>

        <VStack width="80%" align="left" spacing={1}>
          <Text fontSize="20px" color="#529C94" fontWeight="bold">
            O que é MDV?
          </Text>
          <Text fontSize="20px" color="#529C94" fontWeight="bold">
            (Método DeoVita de Vendas)
          </Text>
          <Text fontSize="14px" color="#818181">
            É a maneira de você fazer renda extra sem sair de casa utilizando o seu app DeoVita.
          </Text>
        </VStack>

        <Text fontSize="19.5px" color="#529C94" fontWeight="bold">
          Por que devo revender?
        </Text>

        <Flex minW={miniLargeHeight ? '290px' : '330px'} maxW="133px" bg="#F3F3F3" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px" mb="2em">
            <Image src={BolsaMdv} />
            <VStack w="100%" alignItems="center" spacing="5px">
              <Text fontSize="16px" color="#7C7C7C" fontWeight="bold">
                Produto altamente vendável
              </Text>
              <Text fontSize="12px" color="#7C7C7C" textAlign={'center'}>
                O App DeoVita oferece um serviço de altíssimo valor agregado na área que mais gerou lucro nos últimos anos.
              </Text>
            </VStack>
          </VStack>
        </Flex>

        <Flex minW={miniLargeHeight ? '290px' : '330px'} maxW="133px" bg="#F3F3F3" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px" mb="2em">
            <Image src={CelularMdv} />
            <VStack w="100%" alignItems="center" spacing="5px">
              <Text fontSize="16px" color="#7C7C7C" fontWeight="bold">
                Utilize apenas o seu celular
              </Text>
              <Text fontSize="12px" color="#7C7C7C" textAlign={'center'}>
                Basta compartilhar o seu link de vendedor MDV para os seus amigos, familiares ou carteira de clientes.
              </Text>
            </VStack>
          </VStack>
        </Flex>

        <Flex minW={miniLargeHeight ? '290px' : '330px'} maxW="133px" bg="#F3F3F3" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px" mb="2em">
            <Image src={MoneyMdv} />
            <VStack w="100%" alignItems="center" spacing="5px">
              <Text fontSize="16px" color="#7C7C7C" fontWeight="bold">
                Complemente a sua renda
              </Text>
              <Text fontSize="12px" color="#7C7C7C" textAlign={'center'}>
                Venda DeoVita enquanto realiza outras atividades, ampliando os seus ganhos mensais.
              </Text>
            </VStack>
          </VStack>
        </Flex>
      </VStack>
      {usuarioAssinante === false ? (
        <>
          <Box mx={10} mt={8}>
            <Text fontWeight={'bold'} color={'#FF8000'} mb={-4} fontSize={20}>
              Deovita é a melhor opção para sua saúde!
            </Text>
          </Box>
          <Box
            display={'flex'}
            gap={4}
            px={10}
            py={8}
            overflow={'visible'}
            overflowY={'auto'}
            overscrollBehaviorX={'contain'}
            scrollSnapType={'x mandatory'}
          >
            <CardDadosPlano />
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default MdvNaoAssinante;
