import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Illustration from '../../../../../images/illustration.svg';
import { AccountContext } from 'components/AccountContext';
import _ from 'lodash';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalAvisoEspecialista(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={'11%'} borderRadius="0.8em">
          <ModalBody>
            <Center mb={'1em'} mt={'1em'}>
              <Image src={Illustration} />
            </Center>
            <Center>
              <Text fontSize={'16px'} fontWeight={'bold'} color={'#333333'}>
                Você atingiu o limite de <br /> consultas esta semana
              </Text>
            </Center>
            <Text fontSize={'14px'} mt={'1em'}>
              Entendemos que você precisa de mais uma consulta médica. Para seguir com o agendamento, você terá que agendar para uma data futura.
            </Text>
            <Center mt={'1em'}>
              <Button bg={'#EB8B2A'} ml={'1em'} color={'white'} borderRadius={'0.8em'} onClick={() => disclosure.onClose()}>
                Entendi
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoEspecialista;
