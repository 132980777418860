import React, { useEffect, useState, useContext } from 'react';
import {
  VStack,
  Box,
  Text,
  Flex,
  TabList,
  useToast,
  Tabs,
  TabPanels,
  Link,
  Tab,
  useClipboard,
  TabPanel,
  Badge,
  Heading,
  Center,
  chakra,
  Button,
  HStack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import {
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { TwitterIcon, WhatsappIcon, FacebookMessengerIcon, TelegramIcon } from 'react-share';
import BottomNavBar from '../../../../BottomNavBar';
import ChildHeader from '../../ChildHeader';
import { AccountContext } from '../../../../AccountContext';
import QrCodeImg from './QrCodeImg';
import { useNavigate } from 'react-router-dom';
import { MdOutlineIosShare } from 'react-icons/md';
import Loading from 'components/Loading';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function GerarQrCode() {
  const [loading, setLoading] = useState(true);
  const { setPage, user } = useContext(AccountContext);
  const [qrcode, setQrcode] = useState();
  const [url, setUrl] = useState();
  const [codigo, setCodigo] = useState(user.id_vendedor);
  const [hasBank, setHasBank] = useState(false);
  const shareDrawer = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [onDrawer, setOndrawer] = useState(false);
  const [copy, setCopy] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  setPage('Qr Code');
  async function fetchData() {
    const qrimageUrl = `${ENDPOINT}/qrcode/${user.id_vendedor}/`;
    try {
      let response = await fetch(qrimageUrl, { method: 'GET' });
      let res = await response.json();
      setUrl(res.values[0].url);
      setQrcode(res.values[0].qrcode);
    } catch (error) {
      console.log(error);
    }
  }
  const qrurl = (user) => {
    setQrcode(user.qrcode);
    setUrl(user.url);
  };

  async function fetchBankData() {
    const urlBankData = ENDPOINT + '/entrepreneur/bank-data/' + user.id_empreendedor;
    try {
      let response = await fetch(urlBankData, { method: 'GET' })
        .catch((err) => {
          return;
        })
        .then((res) => {
          if (!res || !res.ok || res.status >= 400) {
            return;
          }
          return res.json();
        })
        .then((data) => {
          setHasBank(data.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  const objeto = {
    title: 'DeoVita Saúde',
    text: `Olá, me chamo ${user.name}, sou revendedor Deovita!

    Não tem plano? DeoVita é o plano!
    Seja um assinante e tenha: 🥳
    
    👨‍⚕️ Pronto atendimento 24h e 12 especialidades (telemedicina);
       🧑‍⚕️Cardiologia
        🧑‍⚕️Dermatologia
        🧑‍⚕️Endocrinologia
        🧑‍⚕️Geriatria
        🧑‍⚕️Ginecologia
        🧑‍⚕️Neurologia
        🧑‍⚕️Pediatria
        🧑‍⚕️Psiquiatria
        🧑‍⚕️Traumatologia
        🧑‍⚕️Otorrinolaringologia
        🧑‍⚕️Urologia
        🧑‍⚕️Nutrição
    
    🏥 Consultas a partir de 25 reais;
    🔬 Exames com até 80% de desconto;
    ➕ e muito mais!
    
    Clique no link abaixo e assine já:
    Voucher: ${codigo}
    ${url}`,
  };
  const { hasCopied, onCopy } = useClipboard(objeto.text); 
  
  function shareContent(objeto) {
    if (navigator.share) {
      navigator
        .share(objeto)
        .then(() => {
          console.log('Compartilhado com sucesso!');
        })
        .catch(console.error);
    } else {
      window.alert('Não Suportado');
      shareDrawer.isOpen();
    }
  }

  useEffect(() => {
    user.qrcode === null ? fetchData() : qrurl(user);
    fetchBankData();
  }, []);

  function Copy() {
    const [value, setValue] = useState(url);
    const { hasCopied, onCopy } = useClipboard(value);
    const toast = useToast();

    return (
      <>
        <Flex mb={5} mt={-2}>
          <Text ml="4" mt="7" size="xs" color="gray">
            Link:
          </Text>
          <Link isExternal size="xs" mt="7" ml="1" fontSize={'0.8em'}>
            {url}
          </Link>
        </Flex>
      </>
    );
  }

  function DrawerAndroidShare() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const shareDrawer = useDisclosure();

    return (
      <>
        <Button w="100%" borderRadius="2em" bgColor="#57AB7A" color="white" colorScheme="teal" onClick={onOpen}>
          <MdOutlineIosShare />
          Compartilhar
        </Button>
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent borderTopRadius={20}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center">Compartilhar</DrawerHeader>

            <DrawerBody w="100%" alignContent="center">
              <Center>
                <HStack align="center">
                  <WhatsappShareButton url={objeto.text}>
                    <WhatsappIcon size={42} round />
                  </WhatsappShareButton>
                  <FacebookMessengerShareButton url={objeto.text}>
                    <FacebookMessengerIcon size={42} round />
                  </FacebookMessengerShareButton>
                  <TwitterShareButton url={objeto.text}>
                    <TwitterIcon size={42} round />
                  </TwitterShareButton>

                  <TelegramShareButton url={objeto.text}>
                    <TelegramIcon size={42} round />
                  </TelegramShareButton>
                </HStack>
              </Center>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  if ( loading ) {
    return (
      <>
        <ChildHeader />
        <Loading />
        <BottomNavBar />
      </>
    );
  }

  return hasBank ? (
    <>
      <ChildHeader />
      <VStack
        align="row"
        mt="3"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'row' }}
        wrap="no-wrap"
        minH="10vh"
        px={5}
        spacing={2}
        pb="5%"
      >
        <Tabs isFitted>
          <TabList>
            <Tab>Revendedor</Tab>
            <Tab isDisabled></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="md" paddingInlineStart={5} mt="4">
                Código de indicação:
                <chakra.span fontSize="1em" color="#5AB2ADB3">
                  {' '}
                  {codigo}
                </chakra.span>
              </Heading>

              <Heading size="md" paddingInlineStart={5} mt="4">
                QR Code
              </Heading>
              <QrCodeImg props={qrcode} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Heading size="md">Outras Opções</Heading>

        <Badge size={90} borderRadius={20}>
          <Copy />
        </Badge>
      </VStack>
      <VStack pb="25%">
        <HStack w="90%">
          {user.device && user.device === 'ios' ? (
            <Button
              w="100%"
              borderRadius="2em"
              bgColor="#57AB7A"
              color="white"
              onClick={() => {
                shareContent(objeto);
              }}
            >
              <MdOutlineIosShare />
              Compartilhar
            </Button>
          ) : (
            <DrawerAndroidShare />
          )}

          <Button
            w="50%"
            borderRadius="2em"
            bgColor="#57AB7A"
            color="white"
            onClick={() => {
              onCopy();
              return toast({
                position: 'bottom-left',
                render: () => (
                  <Box color="white" borderRadius={10} mb="5" p={4} bg="green.500">
                    Copiado! <CheckIcon />
                  </Box>
                ),
              });
            }}
          >
            <CopyIcon />
            Copiar
          </Button>
        </HStack>
      </VStack>
      <BottomNavBar name="QR Code de indicação" />
    </>
  ) : (
    <>
      <ChildHeader />
      <VStack
        align="row"
        mt="3"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'row' }}
        wrap="no-wrap"
        minH="10vh"
        px={5}
        spacing={2}
      >
        <Tabs isFitted>
          <TabList>
            <Tab>Revendedor</Tab>
            <Tab isDisabled></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="md" paddingInlineStart={6} mt="4">
                Você ainda não cadastrou seus dados bancários. Para gerar o código de indicação, cadastre seus dados bancários.
              </Heading>
              <Center>
                <Button
                  mt="4em"
                  w="90%"
                  colorScheme="teal"
                  onClick={() => {
                    navigate('/empreendedor/dados-bancarios');
                  }}
                >
                  Cadastrar Dados Bancários
                </Button>
              </Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <BottomNavBar />
      </VStack>
    </>
  );
}

export default GerarQrCode;
