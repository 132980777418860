import {
  Text,
  Box,
  Image,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useTab,
  Button,
  Center,
  useDisclosure,
  useMediaQuery,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import { AccountContext } from '../AccountContext';
import Loading from '../LoadingWithHeader';
import MoneyMouth from '../../images/emoji-money-mouth.svg';
import { ReactComponent as Heart } from '../../images/heart.svg';
import { ReactComponent as FamiliaPlus } from '../../images/familia-plus.svg';
import { ReactComponent as Familia } from '../../images/plano-familia.svg';
import { ReactComponent as Individual } from '../../images/individual.svg';
import { ReactComponent as PlanoRecomendado } from '../../images/plano-recomendado.svg';

import AvisoPendencias from '../../images/AvisoPendencias.svg';
import DrawerIndicacao from './DrawerIndicacao';
import { loadPlans } from 'api/plans';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

function useIsFullyInViewport(ref) {
  const [isFullyVisible, setIsFullyVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIsFullyVisible(entry.isIntersecting && entry.intersectionRatio === 1);
        },
        { threshold: 1.0 }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isFullyVisible;
}

const BeneficiosItem = (props) => {
  return (
    <Box display={'flex'} alignItems={'flex-start'} gap={2} color={props.color} mb={1}>
      <Heart style={{ flexShrink: 0, marginTop: 4 }}></Heart>
      {props.children}
    </Box>
  );
};

const TabFrequenciaPlano = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];
  return (
    <Text
      {...tabProps}
      py={1}
      px={2}
      textAlign={'center'}
      bg={isSelected ? 'white' : 'transparent'}
      color={isSelected ? props.color : 'white'}
      borderRadius={'full'}
      border={`2px solid white`}
    >
      {tabProps.children}
    </Text>
  );
});

function TabFrequenciaPlanoSelect({ plano, state, navigate, user }) {
  const [md, lg] = useMediaQuery(['(min-width: 375px)', '(min-width: 425px)']);

  const [frequenciaPagamento, setFrequenciaPagamento] = useState(0);

  const getTextFrequencia = useCallback((quantidade) => {
    switch (quantidade) {
      case 12:
        return 'Mensal';
      case 2:
        return 'Semestral';
      case 4:
        return 'Trimestral';
      case 1:
        return 'Anual';
      default:
        break;
    }
  }, []);

  function handleSubmit() {
    let params = {
      ...state,
      id_plano: plano.id,
      nome_plano: plano.nome,
      dependentes_plano: plano.qtd_dependentes,
      parcelsActive: true,
      valor_plano: plano.parcelas[frequenciaPagamento].min_valor,
      valor_plano_formatado: Number(plano.parcelas[frequenciaPagamento].min_valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      frequenciaText: getTextFrequencia(plano.parcelas[frequenciaPagamento].quantidade),
      frequencia: plano.parcelas[frequenciaPagamento].quantidade,
      navig_to: '/cadastro/analise',
      expiration_pix_in_min: 5,
      item: 'Contratação DeoVita',
      service: 'contract',
      voucher: user.voucher ? user.voucher : null,
    };

    let carrinho = [{ id: 0, nome: params.nome_plano, valor: params.valor_plano_formatado, valor_numerico: params.valor_plano }];
    navigate('/pagamentos', { state: { ...params, carrinho: carrinho } });
  }
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Tabs
        variant="solid-rounded"
        colorScheme={getPlanColor(plano.id)}
        index={frequenciaPagamento}
        bg={getPlanColor(plano.id)}
        onChange={(index) => {
          setFrequenciaPagamento(index);
        }}
        mt={4}
        borderRadius={20}
      >
        {plano.parcelas && (
          <>
            <TabList gap={2} m={4} mb={0} display={'flex'} flexWrap={'wrap'}>
              {plano.parcelas.map(({ quantidade }) => (
                <TabFrequenciaPlano color={getPlanColor(plano.id)}>{getTextFrequencia(quantidade)}</TabFrequenciaPlano>
              ))}
            </TabList>
            <TabPanels color={'white'}>
              {plano.parcelas.map(({ min_valor }) => (
                <TabPanel px={4}>
                  <Center flexDirection={'column'}>
                    <Text fontSize={lg ? 'xl' : 'md'} alignSelf={'flex-start'}>
                      A partir de
                    </Text>
                    <Text
                      fontSize={md ? (min_valor > 99 ? '2.5rem' : '5xl') : '3xl'}
                      fontWeight={'bold'}
                      lineHeight={1}
                      mb={4}
                      alignSelf={'flex-start'}
                    >
                      {Number(min_valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Text>
                    <Text fontSize={'xs'}>* O valor do plano será ajustado de acordo com a forma de pagamento escolhida.</Text>
                  </Center>
                </TabPanel>
              ))}
            </TabPanels>
          </>
        )}
      </Tabs>

      <Button bg={getPlanColor(plano.id)} color={'white'} onClick={() => handleSubmit()} mt={4} borderRadius={'full'} alignSelf={'center'}>
        Quero assinar agora!
      </Button>
    </Box>
  );
}

function getPlanColor(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return '#EB8B2A';
    case 55: //INDIVIDUAL
      return '#529C94';

    default:
      return '#FF8000';
  }
}

function getPlanIcon(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return <Familia height={20}></Familia>;
    case 55: //INDIVIDUAL
      return <Individual height={20}></Individual>;
    case 137:
      return <FamiliaPlus height={20}></FamiliaPlus>;

    default:
      return <Individual height={20}></Individual>;
  }
}

const PlanoItem = (props) => {
  const { item, index, setTabIndex } = props;
  const [lg] = useMediaQuery(['(min-width: 375px)', '(min-width: 425px)']);
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const ref = useRef(null);
  const isInViewport = useIsFullyInViewport(ref);

  useEffect(() => {
    if (isInViewport) {
      setTabIndex(index);
    }
  }, [index, isInViewport, setTabIndex]);

  const beneficiosFixos = [
    {
      beneficios_plano_id: 1,
      descricao: 'Adicione até 4 dependentes + 1 Pet.',
    },
    {
      beneficios_plano_id: 2,
      descricao: 'Desconto de 50% em consultas de telemedicina.',
    },
    {
      beneficios_plano_id: 3,
      descricao: 'Até 80% de desconto em consultas e exames presenciais.',
    },
    {
      beneficios_plano_id: 4,
      descricao: 'Obs: Este plano não inclui telemedicina sem custo adicional. Para esse benefício, escolha o plano Família ou Individual.',
    },
  ];
  const beneficios = item.id === 70 ? beneficiosFixos : item.beneficios;
  return (
    <Box
      key={item.id}
      plan={item}
      p={6}
      borderRadius={20}
      width={'95%'}
      flexShrink={0}
      scrollSnapAlign={'center'}
      bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
      border={isInViewport ? `2px solid ${getPlanColor(item.id)}` : '2px solid transparent'}
      transition={'border-color .6s ease-in-out'}
      overflow={'visible'}
      position={'relative'}
      fontSize={lg ? 'md' : 'xs'}
    >
      {item.recomendado && (
        <PlanoRecomendado
          style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%) translateY(-100%)', zIndex: 1000 }}
        ></PlanoRecomendado>
      )}
      <Center ref={ref} bg={getPlanColor(item.id)} color={'white'} gap={2} p={2} borderRadius={10} textAlign={'center'}>
        {getPlanIcon(item.id)} Plano {item.nome}
      </Center>

      <TabFrequenciaPlanoSelect plano={item} user={user} navigate={navigate} state={state}></TabFrequenciaPlanoSelect>

      <Box pt={4} borderTop={'2px solid #D0D0D0'} mt={6} color={'#585858'} fontSize={'md'}>
        <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'} mb={4}>
          Com o <span style={{ color: getPlanColor(item.id) }}>Plano {item.nome}</span>, você pode:
        </Text>
        {item.beneficios &&
          item.beneficios.map((beneficio) => (
            <BeneficiosItem key={beneficio.beneficios_plano_id} color={getPlanColor(item.id)}>
              {beneficio.descricao}
            </BeneficiosItem>
          ))}
      </Box>
    </Box>
  );
};

function Plano() {
  const [plans, setPlans] = useState([]);
  const { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const [error, setError] = useState(false);
  const [assinaturaLiberada, setAssinaturaLiberada] = useState(false);
  const disclosure = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [loadingAssinatura, setLoadingAssinatura] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  let clientId = state?.client_id ? state?.client_id : user.id_cliente_deovita;

  const navigate = useNavigate();

  useEffect(() => {
    setPage('Assinatura');
  }, [setPage]);

  useEffect(() => {
    (async () => {
      try {
        const data = await loadPlans();
        setPlans(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!user.voucher && assinaturaLiberada && !loading) {
      disclosure.onOpen();
    } else {
      disclosure.onClose();
    }
  }, [user, assinaturaLiberada, loading]);

  const getCancelamento = useCallback(() => {
    setLoadingAssinatura(true);
    fetch(`${ENDPOINT}/clients/getContratoCancelado`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cpf: user?.cpf }),
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          console.log(res);
          return;
        }
        return await res.json();
      })
      .then(async (resposta) => {
        setLoadingAssinatura(false);
        if (resposta?.data !== undefined && resposta?.data.length) {
          const dataAtual = new Date();
          const dataCadastro = new Date(resposta.data[0].data_cadastro);

          const dataCadastroSimples = new Date(dataCadastro.getFullYear(), dataCadastro.getMonth(), dataCadastro.getDate());

          const dataCadastroMais90Dias = new Date(dataCadastroSimples);
          dataCadastroMais90Dias.setDate(dataCadastroMais90Dias.getDate() + 90);

          const dataAtualSimples = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate());

          const resultado = dataAtualSimples >= dataCadastroMais90Dias;

          if (!resultado) {
            //O cancelamento do cliente não completou noventa dias
            if (state?.tipo !== 'recontratar_plano') {
              setAssinaturaLiberada(false);
            }
          }
        } else {
          setAssinaturaLiberada(true)
        }
      });
  }, [state?.tipo, user?.cpf]);

  useEffect(() => {
    getCancelamento();
  }, [getCancelamento]);
  
  if (loadingAssinatura || loading) {
    return <Loading />;
  }

  return plans.length && !loadingAssinatura ? (
    !assinaturaLiberada ? (
      <>
        {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
        <Box p={8}>
          <Flex direction={'column'} bg={'#F3F3F3'} p={6} borderRadius={10}>
            <Image src={AvisoPendencias} w={30} alignSelf={'center'} />
            <Text fontSize={16} fontWeight={'bold'} color={'#585858'} my={4}>
              Não é <span style={{ color: '#CB0000' }}> possível reativar </span> seu contrato
            </Text>
            <Spacer border={'0.1px solid #E6E6E6'} />
            <Text fontSize={'16px'} color={'#585858'} my={4}>
              Você só poderá reativar seu contrato 90 dias após a data do cancelamento
            </Text>

            <Button
              isFullWidth
              colorScheme="teal"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Entendi
            </Button>
          </Flex>
        </Box>
      </>
    ) : (
      <>
        {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}

        <DrawerIndicacao disclosure={disclosure} clientId={clientId} />
        <Box mx={10} mt={8}>
          <Image src={MoneyMouth} alignSelf={'flex-start'} mb={4} height={12} width={12}></Image>
          <Text fontWeight={'bold'} color={'#FF8000'} mb={4} fontSize={20}>
            Comece economizando!
          </Text>
          <Text color={'#585858'}>Assinando o plano Deovita você economiza em consultas e procedimentos.</Text>
        </Box>

        {user.voucher && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
            mt={4}
            mx={10}
            p={2}
            px={4}
            borderRadius={10}
          >
            <Box>
              <Text color="teal" fontSize={'xs'} textTransform={'uppercase'}>
                voucher aplicado
              </Text>
              <Text>{user.voucher.vendedor_id}</Text>
            </Box>

            <Button colorScheme="teal" size="xs" textTransform={'uppercase'} onClick={() => disclosure.onOpen()}>
              alterar
            </Button>
          </Box>
        )}

        <Box display={'flex'} gap={4} width={'30%'} margin={'0 auto'} mt={6} mb={2}>
          {plans.map((_, index) => (
            <Box key={index} height={'5px'} bgColor={index === tabIndex ? '#529C94' : '#E0E0E0'} borderRadius={'20px'} flex={1}></Box>
          ))}
        </Box>

        <Box
          display={'flex'}
          gap={4}
          px={10}
          py={8}
          overflow={'visible'}
          overflowY={'auto'}
          overscrollBehaviorX={'contain'}
          scrollSnapType={'x mandatory'}
        >
          {plans.map((item, index) => (
            <PlanoItem item={item} index={index} setTabIndex={setTabIndex}></PlanoItem>
          ))}
        </Box>
      </>
    )
  ) : error ? (
    navigate('/alerts/erro/cadastro_plano')
  ) : null;
}

export default Plano;
