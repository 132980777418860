import { Button, chakra, Box, Flex, Image, Text } from '@chakra-ui/react';

import React, { useContext } from 'react';
import LogoDashboard from '../../images/logo_deovita_blank.svg';
import './PerfilEmpresa/css/main.css';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import { MdCircleNotifications } from 'react-icons/md';

function NoUserHeader() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const { id_contrato, descricao_plano, titular } = user;

  let firstName = user.name ? user.name.split(' ')[0] : 'Cliente';

  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? user?.configsHeader?.hex_color_whitelabel
        ? user?.configsHeader?.hex_color_whitelabel
        : '#EB8B2A'
      : titular === true && id_contrato > 0
      ? user?.configsHeader?.hex_color_whitelabel
        ? user?.configsHeader?.hex_color_whitelabel
        : '#EB8B2A'
      : '#529C94';

  let message =
    id_contrato && descricao_plano
      ? `Assinante plano ${String(descricao_plano).charAt(0).toUpperCase() + String(descricao_plano).slice(1).toLowerCase()}`
      : 'Seja um assinante!';
  if (titular === false) message = 'Assinante dependente';
  if (user.situacao_contrato === 'P') message = 'Contrato em análise';

  return (
    <>
      <chakra.header w="full" py={0} shadow="md" backgroundColor={backgroundColor} minHeight="136px" borderBottomRadius="30px">
        <Flex alignItems="center" justifyContent="space-between" mx="auto" ml={{ sm: '40px', base: '10px' }}>
          <Flex direction="column">
            <chakra.h2 fontSize="xl" fontWeight="bold" mt="43px" color="white">
              Olá, {firstName}
            </chakra.h2>
            <Button
              fontSize="12px"
              minW="136px"
              h="20px"
              color={backgroundColor}
              borderRadius={20}
              onClick={() => {
                if (message === 'Seja um assinante!') {
                  navigate('/cadastro/plano');
                }
              }}
            >
              {message}
            </Button>
          </Flex>
          <Box display={{ base: 'inline-flex' }}>
            <Image
              w="70px"
              h="55px"
              me={{ sm: '30px', base: '10px' }}
              position="absolute"
              right="0.02rem"
              top="43px"
              src={user?.configsHeader?.logo_whitelabel ? user?.configsHeader?.logo_whitelabel : LogoDashboard}
              alt="teste"
            />
          </Box>
        </Flex>

        <Flex direction="row" justifyContent="space-between" alignItems="center">
          <Text fontSize="xl" fontWeight="bold" pt="1.1em" textColor="white" textAlign="center" width="100%" zIndex="1"></Text>
          <Box
            mr={7}
            mt={3}
            onClick={() => {
              alert('show notification');
            }}
            visibility="hidden"
          >
            <MdCircleNotifications color="#FFF" size={26} />
          </Box>
        </Flex>
      </chakra.header>
    </>
  );
}

export default NoUserHeader;
