import {
  Flex,
  HStack,
  Box,
  chakra,
  Avatar,
  WrapItem,
  Table,
  Text,
  Tr,
  Td,
  TableContainer,
  VStack,
  useDisclosure,
  Heading,
  Image,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchContext } from './SearchContext';
import Loading from '../../Loading';
import '../css/lista-medicos.css';
import DotsLoading from '../../Utils/Components/DotsLoading';
import { AccountContext } from '../../AccountContext';
import haversine from 'haversine-distance';
import { SiGooglemaps } from 'react-icons/si';
import BottomNavBar from 'components/BottomNavBar';
import AvisoSemMedicos from './Telemedicina/MedicoAgora/Modal/AvisoSemMedicos';
import Searchbox from './Searchbox';
import VishMedicos from '../../../images/VishMedicos.svg';
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
function ListaMedicos(props) {
  const pic = getRandomInt(0, 999);
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const { id_contrato, titular } = user;
  const state = useLocation();
  const [isLoading, setLoading] = useState(true);
  const typeList = props.typeList;
  const especialidade = props.especialidade;
  console.log(especialidade);

  const isAndroid = user.device === 'android' ? true : false;
  console.log('TESTE ', process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_URL_PROFILEPICS_OPERADOR : process.env.REACT_APP_URL_PROFILEPICS_OPERADOR_TEST)
  var mapUriPrefix = 'https://maps.apple.com/?q=';
  if (isAndroid) mapUriPrefix = 'https://maps.google.com/?q=';
  const { medicos, loadingMedicos, fetchData, searchContext, lastSearch, profession, professionList, setProfession } = useContext(SearchContext);
  const { webviewParams, toastInadimplente } = useContext(AccountContext);
  const [medicos2, setMedicos2] = useState(medicos);
  const [fetchMore, setFetchMore] = useState(false);

  const disclousureSemMedicos = useDisclosure();

  useEffect(() => {
    setMedicos2(medicos);
  }, [medicos]);

  const dataAtual = new Date();

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }

  useEffect(() => {
    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    var busyStack = false;
    var page = 1;

    const handleScroll = async () => {
      let urlPart;
      if (user.coords != null && user.coords.latitudeAtual && user.coords.longitudeAtual) {
        urlPart = `&lat=${user.coords.latitudeAtual}&lng=${user.coords.longitudeAtual}`;
      } else {
        if (
          user.coordenadas_cidade &&
          user.coordenadas_cidade.latitude !== null &&
          user.coordenadas_cidade.latitude !== 'null' &&
          user.coordenadas_cidade.latitude !== undefined &&
          user.coordenadas_cidade.latitude !== 'undefined'
        ) {
          urlPart = `&lat=${user.coordenadas_cidade.latitude}&lng=${user.coordenadas_cidade.longitude}`;
        } else {
          urlPart = `&city=${user.cidade ? user.cidade : 'FORTALEZA'}`;
        }
      }

      if (window.innerHeight + window.scrollY + 10 > document.body.offsetHeight && busyStack === false) {
        setFetchMore(true);
        busyStack = true;
        console.log('typeList ', typeList);
        let url = ENDPOINT + `/schedule/searchMedicos?page=${page + 1}&limit=${10}${urlPart}&profession=${profession}&typeList=${typeList}`;
        if (searchContext === 'search')
          url = ENDPOINT + `/schedule/searchMedicos?text=${lastSearch}&page=${page + 1}&limit=${10}${urlPart}&profession=${profession}&typeList=${typeList}`;
        console.log(url);
        let data = await fetchData(url);
        let lastPage = data.length === 0;
        if (!lastPage) busyStack = false;
        setMedicos2((prev) => [...prev, ...data]);
        page++;
        setFetchMore(false);
      }
    };

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [profession, searchContext, lastSearch, user]);

  const [elementoClicado, setElementoClicado] = useState(false);
  let filteredProfessions = professionList;
  console.log(typeList);
  if (typeList === 'Dentistas') {
    filteredProfessions = professionList.filter((prof) => prof.profissoes_id === 2);
  }

  if (typeList === 'Medicos') {
    filteredProfessions = professionList.filter((prof) => prof.profissoes_id === 11 && prof.profissoes_id === 0);
  }

  if (typeList === 'Outros') {
    filteredProfessions = professionList.filter((prof) => prof.profissoes_id !== 11 && prof.profissoes_id !== 2);
  }
  const useProfessions = filteredProfessions.map((prof, index) => {
    const isClicado = elementoClicado === index;

    return (
      <Td pr="0.1%">
        <Text
          className={isClicado ? 'elemento-clicado' : ''}
          id={prof.nome}
          color={profession === prof.nome ? (id_contrato ? '#EB8B2A' : '#585858') : '#585858'}
          onClick={() => {
            if (!loadingMedicos) {
              document.getElementById(prof.nome).scrollIntoView({ inline: 'center', behavior: 'smooth' });
              setProfession(prof.nome);
              setElementoClicado(index);
            }
          }}
        >
          {prof.nome}
        </Text>
      </Td>
    );
  });

  let filteredMedicos = medicos2;

  if (typeList === 'Medicos') {
    filteredMedicos = medicos2.filter((prop) => prop?.dados?.flag_medico === true && prop?.dados?.agenda_especialidade === false);
  }
  if (typeList === 'Outros') {
    filteredMedicos = medicos2.filter(
      (prop) =>
        prop?.dados?.flag_medico === false &&
        ![85, 66, 76].includes(prop?.dados?.ambulatorio_tipo_consulta_id) &&
        prop?.dados?.agenda_especialidade === false
    );
  }
  if (typeList === 'Especialista') {
    filteredMedicos = medicos2.filter((prop) => [props?.especialidade].includes(prop?.dados?.ambulatorio_tipo_consulta_id));
  }

  const useMedicos = filteredMedicos.map((medico, index) => {
    const arrName = medico.dados.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nomeMedico = Array.isArray(arrName)
      ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name.toLowerCase()) === false).join(' ')
      : '';

    if (!medico.horarios) {
      medico.horarios = [
        {
          AVA_DATE: '0',
          procedimentos: [
            {
              id: 0,
              nome: '',
              valor: '0',
              convenio_id: 0,
              procedimento_particular: [
                {
                  particular_id: 0,
                  particular_valor: '0',
                },
              ],
            },
          ],
        },
      ];
    }

    const isButtonDisabled = !medico.horarios || !medico.horarios[0].procedimentos || medico.horarios[0].AVA_DATE === '0';

    const event = new Date(medico.horarios[0].AVA_DATE.split('/').reverse().join('-') + 'T08:00:00');

    const avaliacao = Math.round(medico.dados.avaliacao_media);
    const urlMapa = `${mapUriPrefix}${medico.dados.logradouro}, ${medico.dados.numero}, ${medico.dados.bairro}, ${medico.dados.municipio}, Ceará`;
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    let titulo = '';
    let promocao = '';
    if (medico.dados.sexo === 'M') {
      titulo = 'Dr. ';
    } else if (medico.dados.sexo === 'F') {
      titulo = 'Dra. ';
    }
    console.log(
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_URL_PROFILEPICS_OPERADOR
        : process.env.REACT_APP_URL_PROFILEPICS_OPERADOR_TEST
    );
    return (
      <>
        <Flex id={index} minW="320px" maxW="320px" maxH="20em" mx="auto" overflow="hidden" borderRadius="20px" bg={'#F0F0F0'}>
          <Box
            w={1 / 3}
            alignItems="center"
            justifyContent="center"
            bgSize="cover"
            align="center"
            display="flex"
            mt={-1.5}
            onClick={() => {
              if (!medico.horarios[0].procedimentos) {
                navigate('/atendimento/agendamento', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: null,
                    procedimentoId: medico.horarios[0].ACTIVITY_LID,
                    procedimento: medico.horarios[0].ACTIVITY_NAME,
                    price: medico.horarios[0].PRICE,
                  },
                });
              } else {
                if (medico.horarios[0].procedimentos.length === 1) {
                  navigate('/atendimento/agendamento', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                      procedimentoId: medico.horarios[0].procedimentos[0].id,
                      procedimento: medico.horarios[0].procedimentos[0].nome,
                      price: medico.horarios[0].procedimentos[0].valor,
                      procedimentoParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_id
                          : null,
                      valorParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_valor
                          : null,
                    },
                  });
                } else {
                  navigate('/atendimento/escolha-procedimento', {
                    state: { medico, startNum: 0, st: 0, defaultDateTime: null },
                  });
                }
              }
            }}
          >
            <WrapItem ml="5px" mt="0.4em">
              <Avatar
                borderRadius="15px"
                id={medico.dados.medico_id}
                size="xl"
                name={medico.dados.nome}
                src={
                  process.env.REACT_APP_ENVIRONMENT === 'production'
                    ? process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic
                    : process.env.REACT_APP_URL_PROFILEPICS_OPERADOR_TEST + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic
                }
              />{' '}
            </WrapItem>
          </Box>
          <Box
            w={2 / 3}
            p={{ base: 2, md: 4 }}
            onClick={() => {
              if (!medico.horarios[0].procedimentos && !isButtonDisabled) {
                navigate('/atendimento/agendamento', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: null,
                    procedimentoId: medico.horarios[0].ACTIVITY_LID,
                    procedimento: medico.horarios[0].ACTIVITY_NAME,
                    price: medico.horarios[0].PRICE,
                  },
                });
              } else {
                if (medico.horarios[0].procedimentos.length === 1 && !isButtonDisabled) {
                  navigate('/atendimento/agendamento', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                      procedimentoId: medico.horarios[0].procedimentos[0].id,
                      procedimento: medico.horarios[0].procedimentos[0].nome,
                      price: medico.horarios[0].procedimentos[0].valor,
                      procedimentoParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_id
                          : null,
                      valorParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_valor
                          : null,
                    },
                  });
                } else {
                  if (!isButtonDisabled) {
                    navigate('/atendimento/escolha-procedimento', {
                      state: { medico, startNum: 0, st: 0, defaultDateTime: null },
                    });
                  }
                }
              }
            }}
          >
            <Text mt={-1} casing="capitalize" fontSize="0.9em" fontWeight="bold" color="#585858">
              {titulo}
              {''}
              {nomeMedico.toLocaleLowerCase()}
            </Text>
            <chakra.p mt={-1} fontSize={medico?.dados?.especialidade?.length < 20 ? '0.6em' : '0.6em'} fontWeight={'0.8em'} color="#818181">
              {medico.dados.especialidade}
            </chakra.p>
            <HStack>
              <chakra.p fontSize="0.625em" color="#818181">
                {medico.dados.conselho && medico.dados.conselho} {medico.dados.conselho && medico.dados.rqe && ' '}{' '}
                {medico.dados.rqe && medico.dados.rqe}
              </chakra.p>
            </HStack>
            {typeList == 'Especialista' ? (
              <>
                <HStack>
                  <chakra.p fontSize="0.625em" align="justify-self">
                    Atendimento Online
                  </chakra.p>
                </HStack>
                <HStack></HStack>
              </>
            ) : (
              <>
                <HStack>
                  <chakra.p fontSize="0.625em" align="justify-self">
                    {medico.dados.empresa}
                  </chakra.p>
                </HStack>
                <HStack>
                  <chakra.p fontSize="0.625em" align="justify-self">
                    {medico.dados.logradouro}, {medico.dados.numero} - {medico.dados.bairro} - {medico.dados.municipio}
                  </chakra.p>
                </HStack>
              </>
            )}
            <HStack spacing={4} mt={0} display="flex" alignItems="center" fontSize="sm" pt={0}>
              {avaliacao > 0 ? (
                <>
                  <chakra.span mt={-0.5}>
                    {avaliacao >= 1 ? <StarIcon color="#EB8B2A" fontSize="1em" mt={-0.3} /> : <StarIcon color="gray.500" fontSize="1em" mt={-0.3} />}

                    <chakra.span fontSize="0.625em" color="#EB8B2A" fontWeight={600}>
                      {' '}
                      {avaliacao >= 5 ? '5' : avaliacao}
                    </chakra.span>
                  </chakra.span>
                </>
              ) : (
                <></>
              )}
              {typeList == 'Especialista' ? (
                <></>
              ) : (
                <>
                  <chakra.span
                    color="rgba(14, 170, 139, 1)"
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(urlMapa, '_blank');
                    }}
                    fontSize="0.625em"
                  >
                    <SiGooglemaps class="googlemap" />
                    {user.coords && user.coords.latitudeAtual && user.coords.longitudeAtual && medico.dados.latitude && medico.dados.longitude
                      ? ' ' +
                        (
                          haversine(
                            { lat: user.coords.latitudeAtual, lng: user.coords.longitudeAtual },
                            { lat: medico.dados.latitude, lng: medico.dados.longitude }
                          ) / 1000
                        ).toFixed(2) +
                        'km'
                      : ''}
                  </chakra.span>
                </>
              )}
              <chakra.a
                color="rgba(14, 170, 139, 1)"
                fontWeight="bold"
                fontSize="0.625em"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate('/empresa/medico/curriculo', {
                    state: {
                      medico,
                      pic,
                    },
                  });
                }}
              >
                Ver currículo
              </chakra.a>
            </HStack>
            <chakra.p color="red.500">{promocao}</chakra.p>
          </Box>
        </Flex>

        <Flex
          minW="320px"
          maxW="320px"
          mx="auto"
          mb={2}
          bg="#ffffff"
          borderRadius="0 0 20px 20px"
          overflow="hidden"
          alignItems="center"
          justifyContent="space-between"
        ></Flex>
      </>
    );
  });
  return (
    <>
      <VStack w="90%">
        <>
          <Box mt={5} justifyContent="center" w={'100%'}>
            <Searchbox />
          </Box>
        </>
      </VStack>

      {typeList !== 'Dentistas' && typeList !== 'Especialista' ? (
        <TableContainer w="100%" overflowY="auto">
          <Table variant="simple">
            <Tr>{professionList && useProfessions}</Tr>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}

      {!loadingMedicos && medicos2.length > 0 ? (
        <>
          {medicos2 && useMedicos} {fetchMore && <DotsLoading />}
        </>
      ) : !loadingMedicos && medicos2.length <= 0 ? (
        <>
          <VStack w={'100%'} m="auto" justify="center" align="center" spacing={4}>
            <Image src={VishMedicos} boxSize="100px" />
            <Heading fontSize={'18px'} textAlign="center">
              Viish...
            </Heading>
            <Text fontSize={'16px'} textAlign="center">
              Nenhum {typeList === 'Medicos' ? 'médico' : typeList === 'Dentistas' ? 'dentista' : 'profissional de saúde'} está disponível no momento
            </Text>
            <Text fontSize={'12px'} textAlign="center">
              Tente novamente mais tarde
            </Text>
          </VStack>
        </>
      ) : (
        <>
          <VStack w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
            <Loading />
          </VStack>
        </>
      )}
    </>
  );
}

export default React.memo(ListaMedicos);
