import { Heading, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import MainHeader from './MainHeader';
import BottomNavBar from '../../BottomNavBar';
import { AccountContext } from 'components/AccountContext';
import AvisoDependente from './DashboardAlerts/AvisoDependente';
import UserHeaderLivre from 'components/AreaLivre/UserHeaderLivre';
import DrawerPedidoPendente from './DrawerPedidoPendente';
import ModalLogin from './ModalLogin';
import AvisoTelefone from './DashboardAlerts/AvisoTelefone';
import BannerCarrosel from 'components/Dashboard/Main/BannerCarrosel';
import BannerEspecialidades from 'components/Dashboard/Main/BannerEspecialidades';
import BannerClubeVantagens from 'components/Dashboard/Main/BannerClubeVantagens';
import AtendimentoPresencial from 'components/Dashboard/Main/AtendimentoPresencial';
import AvisoAtendimento from './DashboardAlerts/AvisoAtendimento';
import AvisoAnaliseContrato from './DashboardAlerts/AvisoAnaliseContrato';
import '../css/main.css';
import ModalAtualizacaoV5 from '../Modal/ModalAtualizacaoV5';
import ModalCartaoV5Pagarme from '../Modal/ModalCartaoV5Pagarme';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main({ ...rest }) {
  const [notificacao, setNoficacao] = useState(false);
  const { setUser, user, toastInadimplente, toastSolicitacao, webviewParams } = useContext(AccountContext);
  const { id_contrato } = user;
  const disclosureTelefone = useDisclosure();
  const disclosureAtendimento = useDisclosure();
  const modalAtualizacaoDisclousure = useDisclosure();
  const modalCartaoV5 = useDisclosure();

  useEffect(() => {
    if (user.loggedIn === true && (!user.phone1 || user.phone1 === undefined || user.phone1 === null || user.phone1 === '')) {
      disclosureTelefone.onOpen();
    }
  }, [user]);

  const dataAtual = new Date();

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }

  useEffect(() => {
    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
    }
  }, []);

  useEffect(() => {
    const simulateLoginEvent = () => {
      const contractId = id_contrato;
      const id_cliente_deovita = user.id_cliente_deovita;
      const cpf = user?.cpf ?? user?.username;
      const paciente_id_clinicas = user && user.client_deovita ? user?.client_deovita[0]?.paciente_id : 0;
      if (user.loggedIn) {
        const cookies = document.cookie;
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            sid: cookies?.sid,
            cpf,
            contractId: contractId,
            id_cliente_deovita: id_cliente_deovita,
            paciente_id_clinicas: paciente_id_clinicas,
          })
        );
      }
    };
    simulateLoginEvent();
  }, [user, id_contrato]);

  useEffect(() => {
    if (webviewParams?.agenda_exames_id) {
      disclosureAtendimento.onOpen();
    }
    if (
      webviewParams?.link_confirmacao &&
      webviewParams?.link_confirmacao !== null &&
      webviewParams?.link_confirmacao !== undefined &&
      webviewParams?.link_confirmacao !== ''
    ) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          service: true,
          openInBrowser: true,
          url: `${webviewParams?.link_confirmacao}`,
        })
      );
    }
  }, [webviewParams]);

  const updateCart = async () => {
    await fetch(`${ENDPOINT}/cart/updateCart/${user.username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUser((prevState) => ({
          ...prevState,
          carrinho: { ...data.cart },
          solicitacoes: { ...data.solicitacoes },
          solicitacoes_consulta: { ...data.solicitacoes_consulta },
        }));
      });
  };

  useEffect(() => {
    if (user.loggedIn) {
      if (!notificacao && (Object.keys(user?.solicitacoes_consulta)?.length > 0 || Object.keys(user?.solicitacoes).length > 0)) {
        setNoficacao(true);
        toastSolicitacao();
      }
    }
  }, []);

  useEffect(() => {
    setNoficacao(false);
  }, []);

  let usuarioAssinante = id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  useEffect(() => {
    if (user.loggedIn && !user?.aceite_versao) {
      modalAtualizacaoDisclousure.onOpen();
    }

    if(user?.cards?.length === 0 && user?.id_forma_pagamento === 12 && usuarioAssinante){
      modalCartaoV5.onOpen()
    }
  }, []);

  return (
    <>
      <AvisoAnaliseContrato />
      <AvisoTelefone disclosure={disclosureTelefone} />
      <AvisoAtendimento disclosure={disclosureAtendimento} />
      <AvisoDependente />
      {/*<ModalAtualizacaoV5 disclosure={modalAtualizacaoDisclousure} />*/}
      <ModalCartaoV5Pagarme disclosure={modalCartaoV5} />
      {/*<PopupAvisoAdicionais />*/}

      {user.loggedIn === true ? <MainHeader /> : <UserHeaderLivre />}
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={8}
        {...rest}
      >
        {user.loggedIn === true && user.pedido_pendente ? <DrawerPedidoPendente /> : <></>}
        {user.loggedIn === false ? <ModalLogin /> : <></>}

        <BannerCarrosel />
        {usuarioAssinante ? <> </> : <BannerEspecialidades />}

        <BannerClubeVantagens />
        <AtendimentoPresencial />
      </VStack>

      {/*<Tabs w="100%" pb={user.loggedIn === true ? '15%' : ''} pt="5%" colorScheme={id_contrato ? 'yellow' : 'teal'}>
          <TabList w="100%">
            <Tab w="50%">PROFISSIONAIS</Tab>
            <Tab w="50%">EXAMES/OUTROS</Tab>
          </TabList>
          <TabPanels>
            <TabPanel bgColor="#F0F0F0">
              {loading && <Loading />}
              <Box ml={1} mr={1} justifyContent="center">
                <Searchbox />
              </Box>

              <ListaMedicos isLoading={(value) => setLoading(value)} />
            </TabPanel>
            <TabPanel bgColor="#F0F0F0">
              <ListaCategorias />
            </TabPanel>
          </TabPanels>
        </Tabs>*/}
      <BottomNavBar name="dashboard" />
    </>
  );
}

export default Main;
