import { chakra, Button, ButtonGroup, VStack, Heading, HStack, InputGroup, InputRightElement, Switch } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import BottomNavBar from '../../../BottomNavBar';
import { parse } from 'date-fns';
import ChildHeader from '../../../Dashboard/Main/ChildHeader';
import TextField from '../../../TextField';
import SelectField from 'components/SelectField';
import validaCPF from '../../../Utils/Functions/validaCPF';
import { EditIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Editar() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { setPage, user } = useContext(AccountContext);
  setPage('Editar dependente');
  const { state } = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [municipiosFiltradoPorEstado, setMunicipiosFiltradoPorEstado] = useState([]);
  const [desabilitaCampo, setDesabilitaCampo] = useState(true);
  const [desabilitaCadastro, setDesabilitaCadastro] = useState(false);

  const initialValues = {
    nome: state.nome,
    cpf: state.cpf,
    numero: state.numero,
    data_aniv: state.nascimento ? new Date(state.nascimento).toLocaleDateString('pt-br', { dateStyle: 'short' }) : '',
    sexo: state.sexo === 'M' || state.sexo === 'm' ? 0 : state.sexo === 'F' || state.sexo === 'f' ? 1 : null,
    fone: state.fone,
    localidadeIbge: state.code_ibge,
    cep: state.cep,
    endereco: state.logradouro,
    complemento: state.complemento ? state.complemento : '',
    cidade: state.municipio,
    estado: state.estado,
    bairro: state.bairro,
    switchCep: false,
    editarTelefone: true,
    editarEndereco: true,
    hadCpf: state.cpf ? true : false,
  };
  const validationSchema = Yup.object({
    nome: Yup.string().required('Nome é obrigatório').typeError('erro'),
    cpf: Yup.string().required('cpf é obrigatório').test('valida_cpf', 'Cpf inválido', validaCPF).typeError('Inválido'),
    data_aniv: Yup.date()
      .max(new Date(), 'Futuro não é permitido')
      .transform((value, originalValue) => {
        return parse(originalValue, 'dd/MM/yyyy', new Date());
      })
      .required('Data de nascimento é obrigatória!')
      .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
    sexo: Yup.number().required('Sexo é obrigatório').label('sexo'),
    fone: Yup.string()
      .required('Telefone é obrigatório')
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido')
      .typeError('Inválido'),
    cep: Yup.string()
      .required('Cep é obrigatório')
      .test('min_cep', 'Cep inválido', (cep) => onlyNumbers(cep).length >= 8)
      .typeError('Inválido'),
    endereco: Yup.string().required('Endereço é obrigatório').typeError('Inválido'),
    numero: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Somente números e letras são permitidos')
      .required('Campo obrigatório'),
    localidadeIbge: Yup.string().required('Cidade é brigatória').typeError('Inválido'),
    estado: Yup.string().required('Estado é obrigatório').typeError('Inválido'),
    bairro: Yup.string().required('Bairro é obrigatório').typeError('Inválido'),
  });

  useEffect(() => {
    fetch(`${ENDPOINT}/cities`, {
      method: 'GET',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        let estados = [];
        data.map((cidade) => (!estados.includes(cidade.estado) ? estados.push(cidade.estado) : ''));
        setLocalidades({ municipios: data, estados: estados });
      });
  }, []);

  const handleSubmit = (values) => {
    const vals = {
      id: state.id,
      celular: values.fone,
      cep: onlyNumbers(values.cep),
      logradouro: values.endereco,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      cod_ibge: values.localidadeIbge,
      nome: values.nome,
      nascimento: initialValues.data_aniv ? state.nascimento : values.data_aniv.replace(/([0-9]{2})[/]([0-9]{2})[/]([0-9]{4})/g, '$3-$2-$1'),
      sexo: values.sexo === 0 ? 'm' : 'f',
      titular: user.titular,
      hadCpf: values.hadCpf,
    };

    if (!initialValues.cpf) vals['cpf'] = onlyNumbers(values.cpf); //atualizacao de dependente sem cpf
    console.log(vals);
    fetch(`${ENDPOINT}/clients`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([vals]),
    })
      .catch((err) => {
        setError('Falha ao atualizar cadastro');
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then((data) => {
        let datas = data[0];
        if (!datas) {
          setError('Falha ao atualizar cadastro');
        } else if (datas.success) {
          setError(null);
          navigate('/alerts/sucesso/atualizar_dependente');
        } else {
          setError('Falha ao atualizar cadastro ' + datas.data.error);
        }
      });
  };

  const FormularioEditar = ({ formikProps }) => {
    const { setFieldValue, values, isSubmitting, getFieldMeta } = formikProps;

    function BuscaCep(cep) {
      cep = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cep}/json/`);
          resposta.json().then((res) => {
            console.log('RESPOSTA VIA CEP ', res);
            if (res.erro === 'true' || res.erro === true) {
              setError(`O CEP que você digitou não foi encontrado. Favor, preencha um CEP válido ou clique em 'Não sei o cep / Digitar dados'`);
              setDesabilitaCadastro(true);
              setDesabilitaCampo(true);
              return;
            }

            if (res.logradouro === '' || res.logradouro === null) {
              setError(null);
              setFieldValue('endereco', res.logradouro);
              setFieldValue('bairro', res.bairro);
              setFieldValue('localidadeIbge', res.ibge);
              setFieldValue('cidade', res.localidade);
              setFieldValue('estado', res.uf);
              setDesabilitaCampo(false); //aqui eu libero o campo pra editar caso seja um cep 'universal'
              setDesabilitaCadastro(false);
              return;
            }

            setDesabilitaCadastro(false);
            setDesabilitaCampo(true);
            setError(null);
            setFieldValue('localidadeIbge', res.ibge);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('bairro', res.bairro);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);
          });
        } catch (error) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      buscaCep();
    }

    const DadosPessoais = () => {
      return (
        <>
          <HStack>
            <Heading size="sm">Dados pessoais:</Heading>
          </HStack>
          <TextField name="nome" placeholder="Nome Completo" isDisabled={initialValues.nome || !values.editarTelefone} />
          <TextField
            name="cpf"
            type="tel"
            placeholder="Digite seu CPF"
            mask="999.999.999-99"
            maskChar={null}
            isDisabled={initialValues.cpf || !values.editarTelefone}
          />
          <SelectField
            name="sexo"
            placeholder="Sexo"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => setFieldValue(e)}
            isDisabled={initialValues.sexo || !values.editarTelefone}
            options={['Masculino', 'Feminino']}
          />
          <HStack spacing="0.3rem" justify="top" align="start">
            <TextField
              name="data_aniv"
              type="tel"
              placeholder="Data de Nascimento"
              mask="99/99/9999"
              maskChar={null}
              isDisabled={initialValues.data_aniv || !values.editarTelefone}
            />
            <InputGroup>
              <TextField
                name="fone"
                type="tel"
                placeholder="Celular com DDD"
                mask="(99) 99999-9999"
                maskChar={null}
                isDisabled={!values.editarTelefone}
              />
              <InputRightElement name="editarTelefone" children={<EditIcon />} />
            </InputGroup>
          </HStack>
        </>
      );
    };

    const Endereco = () => {
      const campoCep = () => {
        return values.switchCep ? (
          ''
        ) : (
          <TextField
            name="cep"
            placeholder="CEP"
            mask="99.999-999"
            type="tel"
            maskChar={'_'}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              if (e.currentTarget.value.replace('_', '').length >= 10) BuscaCep(e.currentTarget.value);
            }}
            isDisabled={!values.editarEndereco}
          />
        );
      };

      const switchCep = () => {
        return (
          <Switch
            name="switchCep"
            color="gray"
            size="sm"
            fontSize="sm"
            colorScheme="teal"
            isChecked={values.switchCep}
            isDisabled={!values.editarEndereco}
            onChange={(e) => {
              function setEnd() {
                setFieldValue('cep', '00.000-000');
                setFieldValue('localidadeIbge', '');
                setFieldValue('endereco', '');
                setFieldValue('bairro', '');
                setFieldValue('cidade', '');
                setFieldValue('estado', '');
              }
              setDesabilitaCadastro(false);
              setError(null);
              setDesabilitaCampo(false); //aqui eu libero o campo pra editar caso seja um cep 'universal'
              setFieldValue('switchCep', e.target.checked);
              e.target.checked ? setEnd() : setFieldValue('cep', state.cep);
            }}
          >
            Não sei o cep / Digitar dados
          </Switch>
        );
      };

      const campoEstado = () => {
        return values.switchCep ? (
          <SelectField
            name="campoEstado"
            placeholder={state.estado ? `${state.estado}` : 'Estado'}
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              setFieldValue('estado', localidades.estados[e]);
              const municipiosFiltrados = localidades.municipios
                .filter((cidade) => cidade.estado === localidades.estados[e])
                .sort((a, b) => a.nome.localeCompare(b.nome)); 
              setMunicipiosFiltradoPorEstado(municipiosFiltrados);
            }}
            options={localidades.estados}
          />
        ) : (
          <TextField name="estado" placeholder={state.estado ? `${state.estado}` : 'Estado'} isDisabled={!values.switchCep} />
        );
      };

      const campoCidade = () => {
        let arrayNomeMunicipios = municipiosFiltradoPorEstado.map((municipio) => municipio.nome);
        return values.switchCep ? (
          <SelectField
            name="campoCidade"
            placeholder={state.municipio ? `${state.municipio}` : 'Cidade'}
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              let codIbge = municipiosFiltradoPorEstado[parseInt(e)].codigo_ibge;
              setFieldValue('localidadeIbge', codIbge);
            }}
            isDisabled={values.estado === ''}
            options={arrayNomeMunicipios}
          />
        ) : (
          <TextField name="cidade" placeholder={state.municipio ? `${state.municipio}` : 'Estado'} isDisabled={!values.switchCep} />
        );
      };

      return (
        <>
          <HStack>
            <Heading size="sm">Endereço:</Heading>
            <EditIcon name="editarEndereco" onClick={(e) => setFieldValue('editarEndereco', true)} />
          </HStack>
          {campoCep()}
          {switchCep()}
          <TextField name="endereco" placeholder="Endereço" isDisabled={desabilitaCampo} />
          <HStack spacing="0.3rem" justify="top" align="start">
            <TextField name="numero" placeholder="Número" isDisabled={!values.editarEndereco} />
            <TextField name="complemento" placeholder="Complemento (opcional)" isDisabled={!values.editarEndereco} />
          </HStack>
          <HStack justify="top" align="start" w="100%">
            {campoEstado()}
            {campoCidade()}
          </HStack>
          <TextField name="bairro" placeholder="Bairro" isDisabled={desabilitaCampo} />
        </>
      );
    };

    return (
      <VStack
        as={Form}
        w={{ base: '90%', md: '500px' }}
        m="auto"
        justify="top"
        marginTop="5%"
        h="100vh"
        spacing="0.3rem"
        align="start"
        paddingBottom="25%"
      >
        <chakra.p color="red">{error}</chakra.p>
        {DadosPessoais()}
        <div></div>
        {Endereco()}
        <ButtonGroup pt="0.1rem" mb="25%" sx={{ width: '100%' }}>
          <Button
            name="btn_salvar"
            colorScheme="teal"
            type="submit"
            w={{ base: '100%' }}
            isDisabled={!(values.editarEndereco || values.editarTelefone) || desabilitaCadastro}
            isLoading={isSubmitting && !error}
          >
            Salvar
          </Button>
          <Button
            name="btn_excluir"
            w={{ base: '100%' }}
            colorScheme="red"
            onClick={() => navigate('/perfil/dependentes/aviso-exclusao', { state: { id: state.dependente_id } })}
          >
            Excluir
          </Button>
        </ButtonGroup>
      </VStack>
    );
  };
  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(props) => (
          <>
            <FormularioEditar formikProps={props} />
          </>
        )}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default Editar;
