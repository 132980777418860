import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Illustration from '../../../../../images/illustration.svg';
import { AccountContext } from 'components/AccountContext';
import _ from 'lodash';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalAvisoSessao(props) {
  const disclosure = props.disclosure;
  const tipo = props.tipo;
  const valor = props.valor;
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);

  const confirmEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={'11%'} borderRadius="0.8em">
          <ModalBody>
            <Center mb={'1em'} mt={'1em'}>
              <Image src={Illustration} />
            </Center>
            <Center>
              <Text fontSize={'16px'} fontWeight={'bold'} color={'#333333'}>
                Você atingiu o limite de <br /> consultas esta semana
              </Text>
            </Center>
            <Text fontSize={'14px'} mt={'1em'}>
              Entendemos que você precisa de mais uma consulta médica. Para seguir com o agendamento, será aplicado um valor adicional de{' '}
              {parseFloat(valor, 2)?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              . Gostaria de continuar?
            </Text>
            <Center mt={'1em'}>
              <Button
                bg={'white'}
                border={'1px solid #666666'}
                color={'#666666'}
                borderRadius={'0.8em'}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                bg={'#EB8B2A'}
                ml={'1em'}
                color={'white'}
                borderRadius={'0.8em'}
                onClick={() => {
                  if (props.dados.orcamento_id) {
                    confirmEstimate(props.dados.orcamento_id);
                  }
                  if (props?.navigt_ext) {
                    navigate('/atendimento/pedido', {
                      state: { ...props.dados, paciente: { nome: user.name, ...user }, importante: true },
                    });
                  } else {
                    navigate('/medico-agora/especialista/conferencia', {
                      state: props.dados,
                      teste: true,
                    });
                  }
                }}
              >
                Continuar
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoSessao;
