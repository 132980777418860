import { React, useState, useContext } from 'react';
import {
  Button,
  Text,
  HStack,
  ButtonGroup,
  useToast,
  Box,
  Center,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Grid,
  useMediaQuery,
  Heading,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { AccountContext } from '../AccountContext';
import TextField from '../TextField';
import SelectField from '../SelectField';
import sendMessageSolicitante from './sendMessageSolicitante';
import { ReactComponent as Chip } from '../../images/card-chip.svg';
import validaCPF from 'components/Utils/Functions/validaCPF';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
const REACT_APP_DEOVITA_RECIPIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_DEOVITA_RECIPIENT_ID_PROD
    : process.env.REACT_APP_DEOVITA_RECIPIENT_ID_TEST;

const CartaoGenerico = (selecionado) => {
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent ?? 0;
  const mdvGerente = selecionado.mdvGerente ?? 0;
  const mdvDiretor = selecionado.mdvDiretor ?? 0;
  const permiteParcelamento = selecionado.permiteParcelamento;
  const parcelamentoMaximo = selecionado.parcelamentoMaximo;

  const [lg] = useMediaQuery('(min-width: 425px)');
  const navigate = useNavigate();
  const { state } = useLocation();

  const { navig_to, valor, parcelsActive, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const parcelas = Parcelas(fromCadastro === true ? valor_selecionado : valor);
  const arrayTextoParcelas = parcelas.map((parcela) => parcela.string);
  const toast = useToast();
  const { user, setUser } = useContext(AccountContext);
  const { id_contrato, titular, cpf } = user;
  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';
  const { cards } = user;
  const { onClose } = useDisclosure();

  let defaultCard;
  let defaulData = { cvv: '', name: '', cardNumber: '' };
  function Parcelas(valor_total) {
    let qtde_max_parcelas, valor_min_parcela;

    if (fromCadastro === true) {
      // valor_min_parcela = valorMinimoParcela ? parseFloat(valorMinimoParcela) : 80;
      qtde_max_parcelas = parcelamentoMaximo ? parcelamentoMaximo : 12;
    } else {
      valor_min_parcela = 50;
      qtde_max_parcelas = 12;

      let qtde_parcelas = parseInt(valor_total / valor_min_parcela);
      if (qtde_parcelas <= 0) qtde_parcelas = 1;
      if (qtde_parcelas > qtde_max_parcelas) qtde_parcelas = qtde_max_parcelas;

      let parcelas = [];
      for (var i = 1; i <= qtde_parcelas; i++) {
        let valor_parcela_string = (valor_total / i).toFixed(2);
        let valor_parcela_float = parseFloat(valor_parcela_string);
        let string_parcela = `${i}x R$${valor_parcela_string} (sem juros)`;
        let parcela = { id: i, val: valor_parcela_float, string: string_parcela.replace('.', ',') };
        parcelas.push(parcela);
      }
  
      return parcelas;
    }

    let qtde_parcelas = qtde_max_parcelas;
    let parcelas = [];

    for (var i = 1; i <= qtde_parcelas; i++) {
      let valor_parcela_string = (valor_total / i).toFixed(2);
      let valor_parcela_float = parseFloat(valor_parcela_string);
      let string_parcela = `${i}x R$${valor_parcela_string} (sem juros)`;
      let parcela = { id: i, val: valor_parcela_float, string: string_parcela.replace('.', ',') };
      parcelas.push(parcela);
    }

    return parcelas;
  }
  const arrayCards = cards
    ? cards.map((card, index) => {
        if (card.default) {
          defaultCard = index;
          defaulData = {
            cardId: card.card_id_pagarme,
            name: card.holder_name,
            cardNumber: `${card.brand} final ${card.last_digits}`,
            expirationDate: card.expiration_date,
            cpf: card?.holder_document,
          };
        }

        return `${card.brand} final ${card.last_digits}`;
      })
    : [];
  arrayCards.push('Adicionar novo cartão');

  const [data, setData] = useState(defaulData);
  const [loading, setLoading] = useState(false);
  const [enterCard, setEnterCard] = useState(false);
  const [aviso, setAviso] = useState(null);
  const [parcel, setParcel] = useState('1');
  const [tipoCartao, setTipoCartao] = useState('credito');

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  function callToast() {
    toast({
      title: 'Importante',
      description: 'Selecione ou cadastre um cartão para realizar o o pagamento',
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  async function pagar(vals) {
    if (vals.cardNumber === '') {
      callToast();
      setLoading(false);
      return;
    }
    const erroMsg = 'Não foi possível completar seu pagamento nesse momento.';

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    };

    fetch(`${ENDPOINT}/payment/${state.service}/card`, requestOptions)
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
        setAviso(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setLoading(false);
          console.log('res', res);
          setAviso(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setAviso(null);
          setLoading(false);
          vals['transaction_id'] = data.id;
          vals['paid'] = true;

          if (state.solicitacao === true) {
            sendMessageSolicitante(ENDPOINT, state.orcamento_id);
          }

          if (fromCadastro) {
            const exp_mes = vals?.expirationDate?.substring(0, 2);
            const exp_ano = vals?.expirationDate?.substring(2, 4);

            try {
              fetch(`${ENDPOINT}/pagamento/criar-cartao`, {
                method: 'POST',
                body: JSON.stringify({
                  cpf: user.cpf,
                  number: vals.cardNumber,
                  holder_name: vals.holder_name,
                  holder_document: vals.holder_document,
                  exp_month: parseInt(exp_mes),
                  exp_year: parseInt(exp_ano),
                  cvv: vals.cvv,
                }),
                headers: {
                  Accept: '/',
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => response.json())
                .then((dadosCartao) => {
                  fetch(`${ENDPOINT}/client/add_credit_card`, {
                    method: 'POST',
                    body: JSON.stringify({
                      paciente_id: user.id_cliente_deovita,
                      first_digits: dadosCartao.first_six_digits,
                      last_digits: dadosCartao.last_four_digits,
                      brand: dadosCartao.brand,
                      holder_name: dadosCartao.holder_name,
                      holder_document: dadosCartao.holder_document,
                      expiration_date: `${dadosCartao.exp_month.toString().padStart(2, '0')}/${(dadosCartao.exp_year % 100)
                        .toString()
                        .padStart(2, '0')}`,
                      card_id_pagarme: dadosCartao.id,
                      fingerprint_card_pagarme: null,
                      default: true,
                    }),
                    headers: {
                      Accept: '/',
                      'Content-Type': 'application/json',
                    },
                  });
                  setUser((prevState) => ({
                    ...prevState,
                    cards: [
                      ...user.cards,
                      {
                        paciente_cartao_credito_pagarme_id: 6402,
                        paciente_id: user.id_cliente_deovita,
                        first_digits: dadosCartao.first_six_digits,
                        last_digits: dadosCartao.last_four_digits,
                        brand: dadosCartao.brand,
                        holder_name: dadosCartao.holder_name,
                        expiration_date: `${dadosCartao.exp_month.toString().padStart(2, '0')}/${(dadosCartao.exp_year % 100)
                          .toString()
                          .padStart(2, '0')}`,
                        data_atualizacao: dadosCartao.updated_at,
                        data_cadastro: dadosCartao.created_at,
                        validade: null,
                        ativo: true,
                        card_id_pagarme: dadosCartao.id,
                        fingerprint_card_pagarme: '',
                        default: true,
                        card_hash: null,
                        recuperado: false,
                        pagarme_versao_5: true,
                        ativo_versao_5: true,
                        holder_document: dadosCartao.holder_document,
                      },
                    ],
                  }));
                });
            } catch (error) {
              console.error(error);
            }
          }

          navigate(navig_to, {
            state: {
              ...vals,
              ...state,
              credito: data?.credito_id,
              metadataCoparticipacao: state?.metadataCoparticipacao,
            },
          });
        } else {
          console.log('data erroMsg', data);
          setLoading(false);
          setAviso(erroMsg);
        }
      });
  }

  function handleClickCard(card) {
    setData({
      cardId: card.card_id_pagarme,
      name: card.holder_name,
      cardNumber: `${card.brand} final ${card.last_digits}`,
      expirationDate: card.expiration_date,
    });
  }

  function SelectCard() {
    return (
      <SelectField
        name="cartoes"
        colorScheme="teal"
        variant="outline"
        onChange={(e) => {
          let lastIndex = arrayCards?.length === 1 ? 1 : arrayCards?.length - 1;
          if (lastIndex === parseInt(e) || arrayCards?.length === 1) {
            navigate('/perfil/editar-cartao', { state: { op: 'new', card: {} } });
          } else {
            handleClickCard(cards[e]);
          }
        }}
        //label="Cartões"
        placeholder="Selecione o cartão"
        options={arrayCards}
      />
    );
  }

  function SavedCards() {
    return (
      <>
        <Button
          color={backgroundColor}
          variant="ghost"
          isFullWidth
          justifyContent="center"
          onClick={() => {
            setData(defaulData);
            setEnterCard(true);
          }}
        >
          {arrayCards?.length < 2 ? 'Cadastrar cartão' : 'Usar outro cartão'}
        </Button>
      </>
    );
  }

  const formCreditCard = () => {
    return (
      <Box mt={4} display={'flex'} flexDirection={'column'} gap={4}>
        <TextField
          label={'Titular do cartão'}
          name="name"
          type="text"
          placeholder="Digite o nome impresso no cartão"
          onBlur={HandleChange}
          required
        />
        <TextField
          label={'Número do Cartão'}
          name="cardNumber"
          type="tel"
          placeholder="xxxx xxxx xxxx xxxx"
          mask="9999 9999 9999 9999"
          maskChar={null}
          onBlur={HandleChange}
          required
        />
        <Grid gridTemplateColumns={lg ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} gap={4}>
          <TextField
            label={'Data de validade'}
            name="expirationDate"
            placeholder="xx/xx"
            type="tel"
            mask="99/99"
            maskChar={null}
            onBlur={HandleChange}
            required
          />
          <TextField label={'CVV'} name="cvv" type="tel" placeholder="xxx" onBlur={HandleChange} required />
        </Grid>
        <TextField
          label={'CPF'}
          name="cpf"
          type="tel"
          placeholder="xxx.xxx.xxx-xx"
          mask="999.999.999-99"
          maskChar={null}
          onBlur={HandleChange}
          required
        />

        {/* <Box w={'full'}>
          <FormLabel>Seu cartão é de débito ou crédito?</FormLabel>
          <HStack spacing={8}>
            <Checkbox isChecked={tipoCartao === 'debito'} colorScheme="teal" onChange={() => setTipoCartao('debito')}>
              Débito
            </Checkbox>
            <Checkbox isChecked={tipoCartao === 'credito'} colorScheme="teal" onChange={() => setTipoCartao('credito')}>
              Crédito
            </Checkbox>
          </HStack>
        </Box> */}
      </Box>
    );
  };

  const validate = (values) => {
    if (!fromCadastro) {
      return {};
    }
    if (fromCadastro && user.loggedIn && cards?.length > 0) {
      return {};
    }

    const errors = {};

    if (!values.cardNumber) {
      errors.cardNumber = 'Campo obrigatório';
    }
    if (!values.cvv) {
      errors.cvv = 'Campo obrigatório';
    }
    if (!values.expirationDate) {
      errors.expirationDate = 'Campo obrigatório';
    }
    if (!values.name) {
      errors.name = 'Campo obrigatório';
    }

    if (!values.cpf?.trim()) {
      errors.cpf = 'Campo obrigatório';
    } else if (!validaCPF(onlyNumbers(values.cpf.trim()))) {
      errors.cpf = 'Número de CPF inválido';
    }

    console.log(values, errors);

    return errors;
  };
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <Formik
        initialValues={{ cartoes: defaultCard, parcel: 0 }}
        validate={validate}
        onSubmit={(values) => {
          var val = {};

          if (user.loggedIn && cards?.length > 0) {
            val = {
              particular: !usuarioAssinante ? true : false,
              metadataCoparticipacao: state?.metadataCoparticipacao,
              id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
              cpf: user.cpf,
              tipo: state?.tipo ? state?.tipo : null,
              cardId: data.cardId,
              coparticipacao: user?.allowsTelemedicina?.paga_telemedicina ? user?.allowsTelemedicina?.paga_telemedicina : false,
              amount: (fromCadastro === true ? valor_selecionado * 100 : valor * 100).toFixed(2),
              // tipoCartao: tipoCartao,
              installments: parcel,
              forma_pgto: 'card',
              triagem: state?.triagem ? state?.triagem : false,
              paid: false,
              frequencia: state.frequencia,
              split_rules: voucher
                ? voucher.recipient_id_gerente
                  ? [
                      {
                        amount: mdvGerente,
                        recipient_id: voucher.recipient_id_gerente,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: mdvDiretor,
                        recipient_id: voucher.recipient_id_diretor,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent - mdvDiretor - mdvGerente,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                    ]
                  : voucher.recipient_id_diretor
                  ? [
                      {
                        amount: mdvDiretor,
                        recipient_id: voucher.recipient_id_diretor,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent - mdvDiretor,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                    ]
                  : [
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                    ]
                : split,
              item: fromCadastro
                ? {
                    client_id: state.client_id,
                    nome: state.name,
                    fone: user.phone1 ? user.phone1 : state.phone,
                    adicionais: state.adicionais,
                    plano: state.id_plano,
                  }
                : state.item,
              voucher: voucher,
            };
          } else {
            val = {
              particular: !usuarioAssinante ? true : false,
              coparticipacao: user?.allowsTelemedicina?.paga_telemedicina ? user?.allowsTelemedicina?.paga_telemedicina : false,
              metadataCoparticipacao: state?.metadataCoparticipacao,
              id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
              cpf: user.cpf,
              tipo: state?.tipo ? state?.tipo : null,
              cardNumber: typeof values.cardNumber === 'string' ? onlyNumbers(values.cardNumber) : '',
              cvv: values.cvv,
              expirationDate: typeof values.expirationDate === 'string' ? onlyNumbers(values.expirationDate) : '',
              amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
              // tipoCartao: tipoCartao,
              holder_name: values.name,
              holder_document: typeof values.cpf === 'string' ? onlyNumbers(values.cpf) : '',
              frequencia: state.frequencia,
              split_rules: voucher
                ? voucher.recipient_id_gerente
                  ? [
                      {
                        amount: mdvGerente,
                        recipient_id: voucher.recipient_id_gerente,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: mdvDiretor,
                        recipient_id: voucher.recipient_id_diretor,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent - mdvDiretor - mdvGerente,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                    ]
                  : voucher.recipient_id_diretor
                  ? [
                      {
                        amount: mdvDiretor,
                        recipient_id: voucher.recipient_id_diretor,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent - mdvDiretor,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                    ]
                  : [
                      {
                        amount: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: false,
                          charge_remainder_fee: false,
                          liable: false,
                        },
                      },
                      {
                        amount: 100 - mdvPercent,
                        recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                        type: 'percentage',
                        options: {
                          charge_processing_fee: true,
                          charge_remainder_fee: true,
                          liable: true,
                        },
                      },
                    ]
                : split,

              installments: parcel,
              forma_pgto: 'card',
              triagem: state?.triagem ? state?.triagem : false,
              paid: false,
              item: fromCadastro
                ? {
                    client_id: state.client_id,
                    nome: state.name,
                    fone: user.phone1 ? user.phone1 : state.phone,
                    adicionais: state.adicionais,
                    plano: state.id_plano,
                  }
                : state.item,
              voucher: voucher,
            };
          }
          const vals = { ...val };
          setLoading(true);
          setAviso(null);
          pagar(vals);
        }}
      >
        {({ errors, touched, isValid, dirty }) => {
          return (
            <Box as={Form} pb={10}>
              <Box
                bg={isValid && dirty ? 'linear-gradient(180deg, #5AB2AD 0%, #529C94 100%)' : 'linear-gradient(180deg, #F0F0F0 0%, #D7D7D7 100%)'}
                width={'100%'}
                borderRadius={20}
                style={{ aspectRatio: '86/53' }} // dimensões padrão de um cartão de crédito no Brasil são 86,6 mm de largura e 53,98 mm de altura
                position={'relative'}
                transition={'color 0.2s'}
                color={isValid && dirty ? 'white' : '#585858'}
              >
                <Box position={'absolute'} top={6} left={6}>
                  <Chip>Chip</Chip>
                </Box>
                <Box position={'absolute'} bottom={6} left={6}>
                  <Text textTransform={'uppercase'} fontWeight={'bold'} mb={2}>
                    {data.name ? data.name.toLocaleUpperCase() : 'Nome'}
                  </Text>
                  <Text>{data.cardNumber ? data.cardNumber : 'xxxx xxxx xxxx xxxx'}</Text>
                </Box>
              </Box>

              {user.loggedIn && cards?.length > 0
                ? SelectCard()
                : user.loggedIn
                ? fromCadastro || state.service === 'telemedicine'
                  ? formCreditCard()
                  : enterCard
                  ? SelectCard()
                  : SavedCards()
                : formCreditCard()}
              <Text as="p" color="red.500">
                {aviso}
              </Text>

              {permiteParcelamento && (
                <SelectField
                  name="parcel"
                  borderColor={backgroundColor}
                  color={backgroundColor}
                  variant="outline"
                  onChange={(e) => {
                    setParcel(parseInt(e) + 1);
                  }}
                  w="100%"
                  isDisabled={!parcelsActive}
                  options={arrayTextoParcelas}
                />
              )}

              {fromCadastro && (
                <Box w={'100%'} border={'2px solid #E4E4E4'} borderRadius={20} p={4} color={'#585858'} my={4}>
                  <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
                    Resumo da compra:
                  </Text>
                  <HStack justifyContent={'space-between'}>
                    <Box>
                      <Text color={'#FF8000'} fontWeight={'bold'}>
                        Plano {state.nome_plano}
                      </Text>
                      <Text>Período: {state.frequenciaText} </Text>
                    </Box>
                    <Text fontWeight={'bold'}>{Number(valor_selecionado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  </HStack>
                </Box>
              )}
              {(state.service === 'telemedicine_deovita' || state.service === 'telemedicine_deovita_pet') && (
                <Box w={'100%'} border={`2px solid ${usuarioAssinante ? '#EB8B2A' : '#529C94'}`} borderRadius={20} p={4} color={'#585858'} mt={8}>
                  <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
                    Resumo da compra:
                  </Text>
                  <HStack justifyContent={'space-between'}>
                    <Box>
                      <Text color={usuarioAssinante ? '#EB8B2A' : '#529C94'} fontWeight={'bold'}>
                        {state.service === 'telemedicine_deovita'
                          ? 'Consulta Telemedicina'
                          : state.service === 'telemedicine_deovita_pet'
                          ? 'Consulta Telepet'
                          : ''}
                      </Text>
                    </Box>
                    <Text fontWeight={'bold'}>{Number(valor_selecionado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  </HStack>
                </Box>
              )}
              <ButtonGroup width="100%" mt={4}>
                {!usuarioAssinante ? (
                  <>
                    <Button
                      backgroundColor={backgroundColor}
                      color="#FFF"
                      type="submit"
                      w="100%"
                      isLoading={loading}
                      loadingText="Carregando"
                      mt={4}
                      disabled={fromCadastro && !(user.loggedIn && cards?.length > 0) ? !(isValid && dirty) : !data.cardNumber}
                    >
                      Realizar pagamento
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      backgroundColor={backgroundColor}
                      color="#FFF"
                      type="submit"
                      w="100%"
                      isLoading={loading}
                      loadingText="Carregando"
                      mt={4}
                      disabled={!data.cardNumber}
                    >
                      Realizar pagamento
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Box>
          );
        }}
      </Formik>
      <Modal onClose={onClose} isOpen={loading} isCentered>
        <ModalOverlay />
        <ModalContent bg={'transparent'}>
          <ModalBody>
            <Center p={8} w={'100%'} bg="#F3F3F3" flexDirection={'column'} gap={10} rounded={10}>
              <Spinner thickness="4px" emptyColor="gray.200" color="#5AB2AD" size="xl" speed="1s" />
              <Text>
                Estamos carregando as <br />
                informações, aguarde...
              </Text>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CartaoGenerico;
