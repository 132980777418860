import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Button,
  Center,
  useDisclosure,
  ModalCloseButton,
  List,
  ListItem,
  Image,
  ModalFooter,
} from '@chakra-ui/react';
import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import especialistas from '../../../../images/doctor-badge.svg';
import ampulheta from '../../../../images/hourglass.svg';
import info from '../../../../images/info.svg';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

export default function AvisoAtendimento(props) {
  const disclosure = props.disclosure;
  const { webviewParams, user } = useContext(AccountContext);
  const navigate = useNavigate();

  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [checkingFaturado, setCheckingFaturado] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tipoDesistencia, setTipoDesistencia] = useState('credito');
  const { isOpen: isOpenPosDesistencia, onOpen: openPosDesistencia, onClose: closePosDesistencia } = useDisclosure();

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  const agenda = webviewParams?.agenda_exames_id;
  const state = {patient: webviewParams?.paciente}

  const desistirAtendimento = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);

      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setIsLoading(false);
    }
  }, [ENDPOINT, agenda, navigate, state.patient]);

  const desistirAtendimentoCredito = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();

      setTipoDesistencia('credito');
      onClose();
      openPosDesistencia();
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setIsLoading(false);
    }
  }, [ENDPOINT, agenda, onClose, openPosDesistencia, state.patient]);

  const desistirAtendimentoReembolso = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}?reembolso=true`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();

      setTipoDesistencia('reembolso');
      onClose();
      openPosDesistencia();
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setIsLoading(false);
    }
  }, [ENDPOINT, agenda, onClose, openPosDesistencia, state.patient]);

  async function handleCheckResult(result) {
    setLoading(false);
    if (result.canceled) {
      setCanceled(true);
    } else {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          service: true,
          openInBrowser: false,
          //url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
          url: `https://atendimento.deovita.com.br:5000/atendimento?p=${webviewParams?.paciente}&m=${webviewParams?.medico}&a=${webviewParams?.agenda_exames_id}&u=paciente`,
        })
      );
      disclosure.onClose();
    }
  }

  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent textAlign={'center'} borderRadius={20} m={8}>
          <ModalHeader fontSize={'md'}>Você tem certeza disso?</ModalHeader>
          <ModalBody fontSize={'xs'}>
            <Text mb={4}>Se desistir do atendimento, você perderá sua posição na fila.</Text>
            <Text>Caso já tenha realizado o pagamento, acesse nossa central de atendimento para solicitar o reembolso.</Text>
          </ModalBody>

          <ModalFooter>
            <VStack w={'100%'}>
              {error && (
                <Text fontSize={'sm'} fontWeight={'bold'} bgColor={'#CB0000'} color={'white'} p={2} rounded={10}>
                  {error}
                </Text>
              )}

              {user?.allowsTelemedicina?.paga_telemedicina || !usuarioAssinante ? (
                <>
                  <Button
                    size="sm"
                    bg={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                    color={'white'}
                    onClick={() => {
                      desistirAtendimentoCredito();
                    }}
                    isFullWidth
                    isLoading={isLoading}
                  >
                    Desistir e manter crédito
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="gray"
                    color={'#585858'}
                    onClick={() => {
                      desistirAtendimentoReembolso();
                    }}
                    fontWeight={'normal'}
                    isFullWidth
                    isLoading={isLoading}
                  >
                    Desistir e solicitar reembolso
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  colorScheme="teal"
                  onClick={() => {
                    desistirAtendimento();
                  }}
                  isFullWidth
                  isLoading={isLoading}
                >
                  Confirmar desistência
                </Button>
              )}

              <Button size="sm" disabled={isLoading} colorScheme="gray" onClick={onClose} variant="ghost" isFullWidth>
                Cancelar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenPosDesistencia} onClose={closePosDesistencia} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent textAlign={'center'} borderRadius={20} m={8}>
          <ModalHeader fontSize={'md'}>Recebemos sua solicitação</ModalHeader>
          <ModalBody fontSize={'sm'}>
            {tipoDesistencia === 'credito' && 'O crédito ficará disponível para a sua próxima consulta'}
            {tipoDesistencia === 'reembolso' &&
              'Para solicitar o reembolso, por favor, entre em contato com nossa central de atendimento pelo número 0800 280-2133 (Whatsapp)'}
          </ModalBody>

          <ModalFooter>
            <VStack w={'100%'}>
              {error && (
                <Text fontSize={'sm'} fontWeight={'bold'} bgColor={'#CB0000'} color={'white'} p={2} rounded={10}>
                  {error}
                </Text>
              )}

              <Button
                size="sm"
                colorScheme="teal"
                onClick={() => {
                  navigate('/dashboard', { replace: true });
                }}
                isFullWidth
                isLoading={isLoading}
              >
                Entendi
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={disclosure.isOpen} isCentered size="full">
        <ModalOverlay />
        <ModalContent backgroundColor={'#EB8B2A'} p={8}>
          <ModalBody
            backgroundColor={'#EEEEEE'}
            borderRadius={20}
            position={'relative'}
            display={'flex'}
            flexDir={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <VStack gap={4} mt={20}>
              <Image src={especialistas} h="5em" w="5em" />

              <Text fontWeight={'bold'} fontSize={24} textAlign={'center'} color={'#EB8B2A'}>
                {canceled ? 'Seu tempo acabou!' : 'Prontinho!'}
              </Text>

              <Text fontWeight={'bold'} fontSize={18} textAlign={'center'} m={4} color={'#EB8B2A'}>
                {canceled ? 'Solicite um novo atendimento' : 'O Profissional de saúde está esperando por você'}
              </Text>
            </VStack>

            {!canceled && (
              <VStack gap={4}>
                <Image src={ampulheta} h="3em" w="3em" />

                <Button
                  bg={'#EB8B2A'}
                  textColor={'#FFF'}
                  paddingX={10}
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    if (canceled) {
                      disclosure.onClose();
                    } else {
                      fetch(`${ENDPOINT}/schedule/checkCanceledSchedule`, {
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify({ agendaExamesId: webviewParams?.agenda_exames_id }),
                      }).then(async (data) => {
                        const result = await data.json();
                        handleCheckResult(result);
                      });
                    }
                  }}
                >
                  Entrar agora!
                </Button>
              </VStack>
            )}

            <VStack mb={4} width={'100%'}>
              {!canceled && (
                <VStack alignItems={'flex-start'} width={'100%'} backgroundColor={'#D60000'} color={'white'} p={4} borderRadius={8}>
                  <Image src={info} />

                  <Text>Você tem 1 minuto para entrar no atendimento</Text>
                  <Text fontSize={12}>Caso não entre neste tempo, seu lugar na fila será passado para outro paciente em espera.</Text>
                </VStack>
              )}
              {canceled ? (
                <Button
                  isFullWidth
                  disabled={loading}
                  border={'1px solid #7F7F7F'}
                  color={'#7F7F7F'}
                  fontWeight={'hairline'}
                  onClick={() => {
                    disclosure.onClose();
                  }}
                >
                  Continuar
                </Button>
              ) : (
                <Button
                  isFullWidth
                  size="sm"
                  colorScheme="gray"
                  variant="outline"
                  color="#585858"
                  mt={4}
                  isLoading={checkingFaturado}
                  onClick={async () => {
                    onOpen();
                  }}
                >
                  Desistir do atendimento
                </Button>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
