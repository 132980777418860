import { AccountContext } from 'components/AccountContext';

const { createContext, useState, useEffect, useContext } = require('react');
export const SearchContext = createContext();

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;


const UserContext = ({ children }) => {
  const [allMedicos, setAllMedicos] = useState([]);
  const [medicos, setMedicos] = useState([]);
  const [loadingMedicos, setLoadingMedicos] = useState(true);
  const [searchContext, setSearchContext] = useState('all'); // all or search
  const [lastSearch, setLastSearch] = useState('');
  const [professionList, setProfessionList] = useState([]);
  const [profession, setProfession] = useState('Todos');
  const { user, setUser } = useContext(AccountContext);
  const pageDefault = 1;
  const limitDefault = 10;
  const typeList = children?.props?.typeList

  const fetchProfessions = async () => {
    const response = await fetch(`${ENDPOINT}/schedule/getProfessions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    const uniqueProfessions = [...new Set(fetchData)];
    uniqueProfessions.unshift({ nome: 'Todos', profissoes_id: 0 });
    setProfessionList(uniqueProfessions);
  };

  useEffect(() => {
    fetchProfessions();
  }, [user]);

  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  async function getCoords() {
    try {
      if (user.coords.latitudeAtual && user.coords.longitudeAtual) {
        return `&lat=${user.coords.latitudeAtual}&lng=${user.coords.longitudeAtual}`;
      } else {
        throw new Error("Dados de posição indisponíveis");
      }
    } catch (error) {
      if (user.coordenadas_cidade && user.coordenadas_cidade.latitude !== null && user.coordenadas_cidade.latitude !== 'null' && user.coordenadas_cidade.latitude !== undefined && user.coordenadas_cidade.latitude !== 'undefined') {
        return `&lat=${user.coordenadas_cidade.latitude}&lng=${user.coordenadas_cidade.longitude}`;
      } else {
        return `&city=${user.cidade ? user.cidade : 'FORTALEZA'}`;
      }
    }
  }
  async function fetchSchedules(page, limit) {
    let urlPart = await getCoords();


    setLoadingMedicos(true);
    let url = `${ENDPOINT}/schedule/searchMedicos?page=${page}&limit=${limit}${urlPart}&profession=${profession}&typeList=${typeList}`;
    console.log(url)
    let data = await fetchData(url);
    setAllMedicos(data);
    setMedicos(data);
    setSearchContext('all');
    setLoadingMedicos(false);
  }

  async function fetchSearch(text, page, limit) {
    let urlPart = await getCoords();
    setLoadingMedicos(true);
    if (!text || text === '') {
      setMedicos(allMedicos);
      setSearchContext('all');
    } else {
      let url = `${ENDPOINT}/schedule/searchMedicos?text=${text}&page=${page}&limit=${limit}${urlPart}&profession=${profession}&typeList=${typeList}`;
      let data = await fetchData(url);
      setMedicos(data);
      setLastSearch(text);
      setSearchContext('search');
    }
    setLoadingMedicos(false);
  }

  useEffect(() => {
    fetchSchedules(pageDefault, limitDefault);
  }, [user, profession]);

  return (
    <SearchContext.Provider
      value={{
        allMedicos,
        medicos,
        setMedicos,
        fetchSchedules,
        fetchSearch,
        fetchData,
        loadingMedicos,
        setLoadingMedicos,
        searchContext,
        setSearchContext,
        lastSearch,
        profession,
        professionList,
        setProfession,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default UserContext;
